import { Link } from "react-router-dom";
import moment from "moment";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
const columnsResumenClient = [
  {
    propiedad: "Usuario",
    etiquetaTraduccion: "Usuario",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "Usuario",
    width: 150,
    render: (_, Usuario) => tolinck(_, Usuario),
  },
  {
    propiedad: "Email",
    etiquetaTraduccion: "Email",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "Email",
    width: 100,
    // render: (_,Client) => tolinck(_,Client)
  },
  {
    propiedad: "Puesto",
    etiquetaTraduccion: "Puesto",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "Email",
    width: 150,
    // render: (_,Client) => tolinck(_,Client)
  },
  {
    propiedad: "PctMesActual",
    etiquetaTraduccion: "% Mes",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "PctMesActual",
    width: 150,
    // render: (_,Client) => tolinck(_,Client)
  },
  {
    propiedad: "TotalDiasMesActual",
    etiquetaTraduccion: "Días Mes",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "TotalDiasMesActual",
    width: 150,
    // render: (_,Client) => tolinck(_,Client)
  },
  {
    propiedad: "PctMesAnterior",
    etiquetaTraduccion: "% Anterior",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "PctMesAnterior",
    width: 150,
    // render: (_,Client) => tolinck(_,Client)
  },

  {
    propiedad: "TotalDiasMesAnterior",
    etiquetaTraduccion: "Dias Anterior",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "TotalDiasMesAnterior",
    width: 150,
    // render: (_,Client) => tolinck(_,Client)
  },

  {
    propiedad: "ranking",
    etiquetaTraduccion: "Ranking",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "ranking",
    width: 130,
    render: (_, Client) => rankingNumber(_, Client),
  },
  {
    propiedad: "icon",
    etiquetaTraduccion: "",
    ordenar: false,
    buscar: false,
    filtrar: false,
    key: "icon",
    width: 30,
    render: (_, Client) => rankingIcon(_, Client),
  },
];

const tolinck = (_, Usuario) => {
  const app = localStorage.getItem("aplicacionSelected");
  const fechaSelected = localStorage.getItem("fechaSelected");
  const fecha = moment(new Date(fechaSelected)).format("YYYY-MM-01");
  const NombreCliente = localStorage.getItem("NombreCliente");
  return (
    <Link
      key={Usuario.Client.toString()}
      to={`/usage/user/${app}/${fecha}/${Usuario.Client}/${Usuario.Email}/${NombreCliente}`}
    >
      {Usuario.Usuario}
    </Link>
  );
};
const rankingNumber = (_, Client) => {
  return <p>{Client.rak}</p>;
};

const rankingIcon = (Client) => {
  if (Client.icon === "sub") {
    return (
      <p>
        <CaretUpOutlined style={{ fontSize: "30px", color: "#46C844" }} />
      </p>
    );
  } else {
    return (
      <p>
        <CaretDownOutlined style={{ fontSize: "30px", color: "#CE3131" }} />
      </p>
    );
  }
};

export default columnsResumenClient;
