import SecondaryNavBar from "../components/SecondaryNavBar";
import AppFooter from "../components/AppFooter";
import { Disclosure, Tab } from "@headlessui/react";
import classNames from "../util/ClassNames";
import AppsTab from "../components/AppsTab";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { useState, useContext } from "react";
import { categoriesContext } from "../context/CategoriesContext";
import { useParams } from "react-router-dom";

function AllApps() {
  const { selectedTab } = useParams();
  const { categories } = useContext(categoriesContext);
  const { apps } = categories;
  const categoriesApp = apps.map((app) => {
    const item = {
      name: app.categoria.nombre,
      key: app.categoria.alias,
    };

    return item;
  });

  const [category, setCategory] = useState("Categorias...");
  return (
    <>
      <SecondaryNavBar />
      <main className="focus:outline-none relative flex-1 overflow-y-auto">
        <div className="py-4">
          <div className="mx-auto hidden max-w-7xl grid-cols-1 gap-4 px-4 sm:px-6 md:px-8 lg:grid">
            <Tab.Group
              defaultIndex={selectedTab || 0}
              onChange={(index) => {
                console.log("Changed selected tab to:", index);
              }}
            >
              <div className="w-full border-b border-gray-200">
                <Tab.List className="flex justify-around">
                  {categoriesApp.map((cat) => (
                    <Tab
                      key={cat.name}
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? "border-indigo-600 text-indigo-600"
                            : "border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800",
                          "whitespace-nowrap border-b-2 py-6 text-sm font-medium"
                        )
                      }
                    >
                      {cat.name}
                    </Tab>
                  ))}
                </Tab.List>
              </div>
              <AppsTab />
            </Tab.Group>
          </div>

          {/* Simulacion de menu emergente */}
          <Disclosure defaultOpen={true}>
            {({ open, close }) => (
              <>
                <div className="mx-auto grid max-w-7xl grid-cols-1 gap-4 px-4 sm:px-6 md:px-8 lg:hidden">
                  <Tab.Group
                    vertical
                    defaultIndex={selectedTab || 0}
                    onChange={(index) => {
                      console.log("Changed selected tab to:", index);
                      setCategory(categoriesApp[index].name);
                      close();
                    }}
                  >
                    <div className="flex flex-col">
                      <div className="flex items-center lg:hidden">
                        {/* Mobile menu button */}
                        <Disclosure.Button className="focus:outline-none flex w-full cursor-default justify-between rounded-md border border-gray-300 bg-white py-2 pl-3 pr-5 text-left shadow-sm focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                          <span>{category}</span>
                          <ChevronDownIcon
                            className={`h-5 w-5 text-purple-500`}
                          />
                        </Disclosure.Button>
                      </div>
                      {/* Titulos de los tabs - debe ser el disclosure panel */}
                      <Disclosure.Panel className="mt-1 bg-white shadow lg:hidden">
                        <div className="w-full border-b border-gray-200">
                          <Tab.List className="flex flex-col justify-between">
                            {categoriesApp.map((cat) => (
                              <Tab
                                key={cat.name}
                                className={({ selected }) =>
                                  classNames(
                                    selected
                                      ? "border-indigo-600 text-indigo-600"
                                      : "border-transparent",
                                    "whitespace-nowrap rounded py-2 px-4 text-left text-sm font-medium hover:bg-indigo-600 hover:text-white"
                                  )
                                }
                              >
                                {cat.name}
                              </Tab>
                            ))}
                          </Tab.List>
                        </div>
                      </Disclosure.Panel>
                    </div>

                    {/* Listado de apps */}
                    <AppsTab />
                  </Tab.Group>
                </div>
              </>
            )}
          </Disclosure>
        </div>
        <AppFooter />
      </main>
    </>
  );
}

export default AllApps;
