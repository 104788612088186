import { useContext } from "react";
import AppFooter from "../components/AppFooter";
import { categoriesContext } from "../context/CategoriesContext";
import { CategoryIconEnum, AppsIconEnum } from "../util/AppsEnum";
import { Link } from "react-router-dom";

function Home() {
  const { categories } = useContext(categoriesContext);
  const { apps } = categories;

  const mapApps = apps.map((app) => {
    const apps = app.categoria.elementos.map((app) => {
      const item = {
        key: app.aplicacion.clave,
        title: app.aplicacion.nombre,
        subtitle: "",
        icon: AppsIconEnum[app.aplicacion.clave],
      };
      return item;
    });
    const item = {
      key: app.categoria.alias,
      title: app.categoria.nombre,
      subtitle: app.categoria.subtitle,
      color: app.categoria.color,
      icon: CategoryIconEnum[app.categoria.alias],
      description: app.categoria.descripcionLarga.replace(
        /<\/?[^>]+(>|$)/g,
        ""
      ),
      index: app.categoria.orden - 1,
      href: "#",
      apps,
    };
    return item;
  });

  return (
    <main className="focus:outline-none relative flex-1 overflow-y-auto">
      <div className="py-4">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          {/* Replace with your content */}
          <div className="py-4">
            {mapApps.map((item) => (
              <div
                className="my-4 border bg-white p-4 shadow-sm"
                key={item.title}
              >
                <h1 className={`text-2xl font-bold text-primary-${item.color}`}>
                  {item.title}
                </h1>
                <h2 className={`text-xl uppercase text-primary-${item.color}`}>
                  {item.subtitle}
                </h2>

                <div className="mt-2 flex flex-wrap ">
                  <div className="w-full md:w-1/2 md:pr-6">
                    <div
                      className={`flex items-center justify-center justify-items-center text-center bg-primary-${item.color} mb-4 p-12`}
                    >
                      <item.icon className="" aria-hidden="true" />
                    </div>
                    <p className="text-justify">{item.description}</p>

                    <div className="hidden py-3 text-right sm:px-6 md:block">
                      <Link
                        to={`/all-apps/${item.index}`}
                        className="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-simblue-900 py-4 px-6 text-sm font-medium text-white shadow-sm hover:bg-simblue-800 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Ver más
                      </Link>
                    </div>
                  </div>

                  <div
                    className={`scrollbar-thumb-rounded-full scrollbar-track-rounded-full grid w-full grid-cols-1 gap-2 overflow-auto p-4 scrollbar-thin scrollbar-track-neutral-400 scrollbar-thumb-neutral-600 md:max-h-72 md:w-1/2 xl:grid-cols-2 ${
                      item.apps.length > 6 ? "bg-neutral-300" : ""
                    }`}
                  >
                    {item.apps.map((app) => (
                      <div key={app.title} className="flex">
                        <div className="">
                          <app.icon
                            className="w-16 h-auto bg-neutral-300  fill-current text-primary-darkblue p-2"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="p-2">
                          <h1 className="text-base font-medium xl:text-base">
                            {app.title}
                          </h1>
                          <h2 className="text-sm xl:text-base">
                            {app.subtitle}
                          </h2>
                        </div>
                      </div>
                    ))}

                    <div className="block py-3 text-right sm:px-6 md:hidden">
                      <Link
                        to={`/all-apps/${item.index}`}
                        className="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-simblue-900 py-4 px-6 text-sm font-medium text-white shadow-sm hover:bg-simblue-800 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Ver más
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* /End replace */}
        </div>
      </div>
      <AppFooter />
    </main>
  );
}

export default Home;
