import { useContext, useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import Whatsapp from "../../components/icons/Dashboard/Whatsapp";
import { ModalContext } from "../../context/ModalContext";
import toast from "react-hot-toast";
import { FetchContext } from "../../context/FetchContext";

const WhatsappModal = () => {
  const { modalWhatsappState, onCloseModalWhatsapp } = useContext(ModalContext);
  const { authAxios } = useContext(FetchContext);
  const [qr, setQr] = useState({});

  useEffect(() => {
    const getQR = async () => {
      try {
        const { data } = await authAxios.get("info/qr");
        setQr(data[0]);
      } catch (err) {
        const { data } = err.response;
        if (data.statusCode === 401 && data.message === "Unauthorized") {
          toast.error("La sesión ha expirado");
          setTimeout(() => {
            history.push("/");
          }, 1500);
        } else {
          toast.error(data.message);
        }
      }
    };
    getQR();
  }, []);

  return (
    <Modal
      open={modalWhatsappState}
      onClose={onCloseModalWhatsapp}
      center
      classNames={{ overlay: "customOverlay", modal: "customModal" }}
    >
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-simblue-300 sm:mx-0 sm:h-10 sm:w-10">
          <Whatsapp className="h-6 w-6 text-simblue-800" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Saber mas
          </h3>
          <div className="mt-2">
            <p className="text-sm text-simblue-800">
              <a
                href={`https://wa.me/${qr.qr_phone}`}
                rel="noopener noreferrer"
                target="_blank"
                className="underline"
              >
                Escanea el código QR desde tu teléfono o da clic aquí para abrir
                WhatsApp en este dispositivo
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <img src={`${qr.qr_image}`} alt="" className="object-cover h-48 w-48" />
      </div>
    </Modal>
  );
};

export default WhatsappModal;
