import { useContext } from "react";
import { Modal } from "react-responsive-modal";

import { ModalContext } from "../../context/ModalContext";

const CalendarModal = () => {
  const { modalCalendarState, onCloseModalCalendar, profile, product } =
    useContext(ModalContext);
  const urlMeeting = `https://meetings.hubspot.com/info-sim/citassimhub?embed=true&firstName=${profile.nombre}&lastName=${profile.apellidos}&email=${profile.email}&phone=${profile.celular}&rol_en_la_organizacion=Otro&message=Me%20gustaria%20contactarles%20para%20recibir%20más%20información%20de%20${product.title}&aplicacion=${product.key}`;
  return (
    <Modal
      open={modalCalendarState}
      onClose={onCloseModalCalendar}
      center
      classNames={{ overlay: "customOverlay", modal: "customModalIframe" }}
    >
      <div className="sm:flex sm:items-start w-100 ">
        <iframe
          className="meetings-iframe-container"
          width={"100%"}
          height={670}
          src={urlMeeting}
        ></iframe>
      </div>
    </Modal>
  );
};

export default CalendarModal;
