import { PropTypes } from "prop-types";
import classNames from "../util/ClassNames";
import SimhubLogoIcon from "./icons/SimhubLogoIcon";
import ChevronsLeftIcon from "./icons/Dashboard/ChevronsLeftIcon";
import MenuIcon from "./icons/Dashboard/MenuIcon";
import principalNavigation from "../util/PrincipalNavigation";
import secondaryNavigation from "../util/SecondaryNavigation";
// import reportsNavigation from "../util/ReportsNavigation";
import { Link, NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import RssIcon from "../components/icons/RssIcon";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import { ChartBarIcon } from "@heroicons/react/outline";

SidebarDesktop.propTypes = {
  setSidebarOpenDesk: PropTypes.func,
  sidebarOpenDesk: PropTypes.bool,
};

function SidebarDesktop({ sidebarOpenDesk, setSidebarOpenDesk }) {
  const { authState } = useContext(AuthContext);

  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div
        className={classNames(
          sidebarOpenDesk ? "w32" : " w-64",
          "transition-all duration-700 delay-150 flex flex-col"
        )}
      >
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-grow border-r-2 border-neutral-300 pb-4 bg-white overflow-y-auto">
          <div className="flex items-center justify-between flex-shrink-0 px-4 border-b-2 border-neutral-300 py-5 h-24">
            <SimhubLogoIcon
              className={classNames(
                sidebarOpenDesk
                  ? "transition-all duration-300 ease-out hidden"
                  : "w-44",
                "transition-all duration-300 w-44"
              )}
            />

            {sidebarOpenDesk ? (
              <button
                className="pt-5 pb-2"
                onClick={() => setSidebarOpenDesk(!sidebarOpenDesk)}
              >
                <MenuIcon />
              </button>
            ) : (
              <button
                className="pt-5 pb-2"
                onClick={() => setSidebarOpenDesk(!sidebarOpenDesk)}
              >
                <ChevronsLeftIcon className="" />
              </button>
            )}
          </div>
          <div className="mt-5 flex-grow-0 flex flex-col">
            <nav className="flex-1 pb-8 bg-white space-y-1">
              {principalNavigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  activeClassName="border-l-2 border-black"
                  data-tip={item.name}
                  className={classNames(
                    "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                    sidebarOpenDesk ? "justify-center" : "",
                    "group flex items-center px-2 py-2 my-4 text-sm font-medium"
                  )}
                >
                  <item.icon
                    className={classNames(
                      item.current
                        ? "text-gray-500"
                        : "text-gray-400 group-hover:text-gray-500",
                      sidebarOpenDesk ? "mr-0 text-center" : "mr-3 ",
                      "flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  {sidebarOpenDesk ? "" : item.name}
                </NavLink>
              ))}
            </nav>

            <nav
              className={classNames(
                sidebarOpenDesk ? "px-0" : "px-2",
                "flex-1 bg-white space-y-1 pt-8 border-t-2 border-neutral-300"
              )}
            >
              <div
                className={classNames(
                  sidebarOpenDesk ? "m-0 justify-center" : "m-2",
                  "bg-neutral-300 rounded-md"
                )}
              >
                {secondaryNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    data-tip={item.name}
                    className={classNames(
                      item.current
                        ? "border-l-2 border-black"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      sidebarOpenDesk ? "justify-center" : "",
                      "group flex items-center px-2 py-2 my-4 text-sm font-medium"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        sidebarOpenDesk ? "mr-0 text-center" : "mr-3",
                        "flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {sidebarOpenDesk ? "" : item.name}
                  </Link>
                ))}
              </div>
            </nav>

            <nav
              className={classNames(
                sidebarOpenDesk ? "px-0" : "px-2",
                "flex-1 bg-white space-y-1 pt-8 border-t-2 border-neutral-300"
              )}
            >
              <div
                className={classNames(
                  sidebarOpenDesk ? "m-0 justify-center" : "m-2",
                  "bg-neutral-300 rounded-md"
                )}
              >
                {/* only if you have simindex permission */}
                {authState.profile.hasSimindex && (
                  <Link
                    to="/admin/simindex/list"
                    data-tip="Admin SimIndex"
                    className={classNames(
                      "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      sidebarOpenDesk ? "justify-center" : "",
                      "group flex items-center px-2 py-2 my-4 text-sm font-medium"
                    )}
                  >
                    <RssIcon
                      className={classNames(
                        "text-gray-400 group-hover:text-gray-500",
                        sidebarOpenDesk ? "mr-0 text-center" : "mr-3",
                        "flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />

                    {sidebarOpenDesk ? "" : "Admin SimIndex"}
                  </Link>
                )}

                {authState.profile.hasUsage && (
                  <Link
                    to="/usage/"
                    data-tip="Usage"
                    className={classNames(
                      "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      sidebarOpenDesk ? "justify-center" : "",
                      "group flex items-center px-2 py-2 my-4 text-sm font-medium"
                    )}
                  >
                    <ChartBarIcon
                      className={classNames(
                        "text-black group-hover:text-gray-500",
                        sidebarOpenDesk ? "mr-0 text-center" : "mr-3",
                        "flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />

                    {sidebarOpenDesk ? "" : "Usage"}
                  </Link>
                )}
              </div>
            </nav>

            <nav
              className={classNames(
                sidebarOpenDesk ? "px-0" : "px-2",
                "flex-1 bg-white space-y-1 pt-8 border-t-2 border-neutral-300"
              )}
            >
              <div
                className={classNames(
                  sidebarOpenDesk ? "m-0 justify-center" : "m-2",
                  "bg-neutral-300 rounded-md"
                )}
              >
                {/* {reportsNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    data-tip={item.name}
                    className={classNames(
                      item.current
                        ? "border-l-2 border-black"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      sidebarOpenDesk ? "justify-center" : "",
                      "group flex items-center px-2 py-2 my-4 text-sm font-medium"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        sidebarOpenDesk ? "mr-0 text-center" : "mr-3",
                        "flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {sidebarOpenDesk ? "" : item.name}
                  </Link>
                ))} */}
              </div>
            </nav>

            {sidebarOpenDesk && (
              <ReactTooltip place="right" type="dark" effect="solid" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SidebarDesktop;
