import { useEffect, useState, useContext } from "react";
import ReactMarkdown from "react-markdown";
import { FetchContext } from "../context/FetchContext";

function Privacy() {
  // Axios
  const { authAxios } = useContext(FetchContext);
  const [loading, setLoading] = useState(false);
  const [privacy, setPrivacy] = useState("");

  useEffect(() => {
    const getTerms = async () => {
      try {
        const { data } = await authAxios.get("terms-conditions/2");
        setLoading(true);
        setPrivacy(data.contenido_md);
      } catch (err) {
        console.log(err);
      }
    };

    getTerms();
  }, []);

  if (!loading) {
    return (
      <div className="container h-screen  mx-auto py-16 px-4 flex justify-center items-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-16 px-4 terms-conditions">
      <h1 className="title-terms">Políticas de privacidad</h1>
      <ReactMarkdown>{privacy}</ReactMarkdown>
    </div>
  );
}

export default Privacy;
