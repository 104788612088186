import { PropTypes } from "prop-types";

const SimAnalytics = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="526.000000pt"
      height="526.000000pt"
      viewBox="0 0 526.000000 526.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,526.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2385 5243 c-366 -43 -618 -115 -910 -258 -778 -382 -1308 -1110
-1441 -1980 -22 -148 -26 -559 -6 -700 33 -234 89 -452 166 -643 336 -836
1076 -1445 1950 -1607 488 -90 1001 -42 1449 136 862 342 1470 1095 1628 2020
27 158 37 512 20 685 -51 503 -247 981 -567 1384 -87 109 -285 307 -394 394
-392 311 -841 500 -1335 561 -108 14 -472 19 -560 8z m1197 -772 c192 -49 406
-204 522 -380 51 -77 128 -230 124 -248 -2 -7 -72 -55 -156 -107 l-153 -95
-62 46 c-86 64 -207 118 -306 138 -206 42 -318 -23 -392 -225 -61 -168 -117
-445 -159 -780 -98 -792 -189 -1211 -326 -1509 -132 -286 -375 -493 -624 -532
-337 -52 -723 202 -915 603 -19 40 -35 77 -35 83 0 9 433 310 462 320 7 3 24
-13 38 -36 32 -52 108 -121 163 -148 94 -46 209 -46 282 -1 136 84 237 316
285 655 6 44 22 146 35 226 13 80 42 302 65 495 45 378 79 575 135 757 114
377 270 578 533 688 161 66 344 86 484 50z m706 -769 c17 -58 52 -231 52 -259
0 -25 -1 -26 -52 -19 -29 3 -97 6 -151 6 -92 0 -98 1 -106 23 -5 12 -18 47
-30 77 -12 30 -23 59 -24 63 -2 6 273 143 294 146 3 1 11 -16 17 -37z m26
-389 l39 -6 -6 -116 c-5 -104 -24 -211 -40 -228 -6 -5 -257 136 -280 156 -4 4
-2 51 5 104 l12 97 116 0 c63 0 132 -3 154 -7z m-106 -417 l63 -38 -27 -46
c-47 -83 -104 -151 -162 -197 -31 -25 -60 -41 -64 -37 -3 4 -36 59 -71 122
l-65 115 20 30 c11 17 36 64 56 106 l37 76 75 -46 c41 -26 103 -64 138 -85z
m-2266 98 c110 -20 251 -58 354 -96 l84 -30 0 -39 c0 -47 -7 -48 -100 -17 -57
19 -86 22 -235 22 -159 0 -175 -2 -255 -28 -47 -16 -102 -37 -123 -48 l-38
-19 -13 33 c-8 18 -33 75 -55 127 -23 52 -39 96 -36 99 24 24 280 22 417 -4z
m-494 -191 c56 -78 97 -145 93 -148 -20 -12 -80 -114 -96 -163 -10 -29 -19
-52 -21 -52 -4 0 -375 210 -384 217 -2 2 5 20 15 41 30 56 173 193 235 224 29
15 54 27 56 25 1 -1 47 -66 102 -144z m2403 -196 c41 -73 54 -104 45 -109 -37
-21 -174 -50 -261 -55 -129 -7 -261 8 -460 55 l-160 38 2 38 c2 22 4 40 5 41
2 2 39 -4 83 -12 56 -10 129 -13 245 -10 144 3 175 7 245 30 44 15 105 42 135
61 30 19 57 33 59 30 3 -2 31 -51 62 -107z m-2616 -174 c192 -90 172 -66 194
-228 6 -44 14 -91 17 -104 l7 -24 -153 6 c-84 4 -206 7 -272 7 l-118 0 0 79
c0 92 22 231 51 318 l20 61 42 -18 c23 -10 118 -54 212 -97z m271 -498 c19
-44 34 -82 34 -85 0 -9 -465 -300 -477 -300 -14 0 -76 174 -108 300 -26 102
-30 139 -17 141 57 7 414 26 466 25 l69 -1 33 -80z"
        />
      </g>
    </svg>
  );
};

SimAnalytics.propTypes = {
  className: PropTypes.string,
};

export default SimAnalytics;
