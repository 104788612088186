import Modal from "react-responsive-modal";
import { PropTypes } from "prop-types";
import Whatsapp from "../icons/Dashboard/Whatsapp";
import PhoneInput from "react-phone-input-2";
import startsWith from "lodash.startswith";
import { useState, useEffect, useContext } from "react";
import { FetchContext } from "../../context/FetchContext";
import { useHistory } from "react-router-dom";

const IniviteWhatsModal = ({ modalWhats, setModalWhats }) => {
  const history = useHistory();
  const { authAxios } = useContext(FetchContext);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [mobil, setMobil] = useState("");
  const [config, setConfig] = useState({});

  const handleOpenWhats = () => {
    window.open(
      `https://wa.me/${phoneNumber}?text=${config.whats_message}`,
      "_blank"
    );
    setModalWhats(false);
  };

  useEffect(() => {
    const getQR = async () => {
      try {
        const { data } = await authAxios.get("info/config");
        setConfig(data);
        setMobil(data.celular.replace(/\D/g, ""));
        setPhoneNumber(data.celular.replace(/\D/g, ""));
      } catch (err) {
        const { data } = err.response;
        if (data.statusCode === 401 && data.message === "Unauthorized") {
          console.log("La sesión ha expirado");
          setTimeout(() => {
            history.push("/");
          }, 1500);
        } else {
          console.log(data.message);
        }
      }
    };
    getQR();
  }, []);

  const onRadionChange = (event) => {
    if (event.target.value === "friend") {
      setPhoneNumber("");
    } else {
      setPhoneNumber(mobil);
    }
  };

  return (
    <Modal
      open={modalWhats}
      onClose={() => setModalWhats(false)}
      center
      classNames={{ overlay: "customOverlay", modal: "whatsModal" }}
    >
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-simblue-300 sm:mx-0 sm:h-10 sm:w-10">
          <Whatsapp className="h-6 w-6 text-simblue-800" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Invitar a un amigo
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Confirma los datos de contacto
            </p>
          </div>
        </div>
      </div>

      <div className="sm:border-t sm:border-gray-200 sm:pt-5 pb-5">
        <fieldset className="mt-4">
          <div className="space-y-4">
            <div className="flex items-center">
              <input
                id="me"
                name="number-method"
                type="radio"
                defaultChecked
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                onChange={onRadionChange}
                value="me"
              />
              <label
                htmlFor="me"
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                Enviar a mi mismo para compartir entre mis contactos
              </label>
            </div>
          </div>
        </fieldset>
        <fieldset className="mt-4">
          <div className="space-y-4">
            <div className="flex items-center">
              <input
                id="friend"
                name="number-method"
                type="radio"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                onChange={onRadionChange}
                value="friend"
              />
              <label
                htmlFor="friend"
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                Enviar al siguiente número telefonico
              </label>
            </div>
          </div>
        </fieldset>
      </div>

      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="firstname"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Telefono
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <PhoneInput
            value={phoneNumber}
            onChange={(phone) => setPhoneNumber(phone)}
            country={"mx"}
            enableSearch={true}
            isValid={(inputNumber, country, countries) => {
              return countries.some((country) => {
                return (
                  startsWith(inputNumber, country.dialCode) ||
                  startsWith(country.dialCode, inputNumber)
                );
              });
            }}
          />
        </div>
      </div>

      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          onClick={handleOpenWhats}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Abrir Whatsapp
        </button>
      </div>
    </Modal>
  );
};

IniviteWhatsModal.propTypes = {
  modalWhats: PropTypes.bool,
  setModalWhats: PropTypes.func,
};

export default IniviteWhatsModal;
