import SimhubLogoIcon from "./icons/SimhubLogoIcon";
import Facebook from "./icons/Facebook";
import Linken from "./icons/Linken";
import Instagram from "./icons/Instagram";
import Youtube from "./icons/Youtube";
import { Link } from "react-router-dom";

function AppFooter() {
  return (
    <div className="py-4">
      <div className="max-w-7xl mx-auto px-4 flex flex-wrap justify-between flex-col sm:px-6 md:px-8 xl:flex-row">
        <div className="flex flex-wrap justify-center mb-8 xl:mb-0">
          <SimhubLogoIcon className="w-32" />
        </div>
        <div className="flex flex-wrap justify-center text-center flex-col content-center xl:flex-row">
          <div className="flex justify-center text-center space-x-6 mb-8 xl:mx-8 xl:mb-0">
            <Facebook />
            <Linken />
            <Instagram />
            <Youtube />
          </div>
          <div className="flex flex-wrap">
            <ul className=" flex flex-wrap flex-col text-center justify-center space-y-4 xl:space-y-0 xl:flex-row xl:space-x-4">
              <li>
                <Link to="/privacy-policies">Políticas de privacidad</Link>
              </li>
              <li>
                <Link to="/terms-conditions">Términos y condiciones</Link>
              </li>
              <li>
                <a href="#">Ayuda y soporte</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppFooter;
