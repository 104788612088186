import { PropTypes } from "prop-types";

const LocalizadorPartes = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="507.000000pt"
      height="507.000000pt"
      viewBox="0 0 507.000000 507.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,507.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2400 5064 c-385 -26 -721 -118 -1040 -285 -482 -253 -860 -642
-1101 -1133 -122 -249 -199 -506 -241 -801 -16 -117 -16 -503 0 -620 58 -409
194 -773 411 -1097 782 -1169 2358 -1476 3529 -686 197 133 449 372 588 558
283 378 453 803 504 1265 13 118 13 420 -1 543 -60 556 -301 1072 -689 1476
-472 491 -1075 758 -1760 779 -80 3 -170 3 -200 1z m1242 -753 c365 -94 591
-459 500 -806 -43 -164 -164 -417 -341 -715 -100 -167 -312 -490 -322 -490 -9
0 -222 322 -311 470 -275 458 -392 749 -373 930 9 100 23 147 72 250 39 80 58
107 132 180 96 95 184 147 306 180 95 26 239 26 337 1z m-982 -547 l55 -15 2
-99 1 -98 -47 20 c-144 62 -318 32 -423 -74 -24 -24 -84 -111 -135 -194 -78
-126 -98 -153 -130 -168 -21 -10 -74 -42 -118 -71 -310 -206 -501 -539 -522
-910 l-6 -110 -111 -180 c-133 -217 -150 -259 -144 -372 6 -104 45 -191 118
-257 102 -91 164 -106 438 -106 l195 0 46 -29 c148 -95 352 -164 528 -179 247
-21 484 31 689 151 l89 52 235 5 235 5 67 32 c160 76 250 243 219 411 -14 78
-17 82 -148 296 l-105 170 -5 99 c-3 55 -13 137 -22 181 l-16 81 59 93 60 93
14 -27 c28 -54 73 -259 79 -361 l6 -103 100 -162 c115 -187 141 -244 156 -348
41 -269 -129 -530 -399 -615 -42 -14 -104 -19 -269 -23 l-214 -4 -58 -34 c-91
-54 -214 -103 -339 -136 -153 -40 -381 -50 -541 -23 -140 23 -292 74 -415 139
l-101 54 -209 4 c-161 4 -222 9 -264 23 -184 58 -322 189 -382 363 -19 56 -23
87 -22 182 1 146 16 188 151 408 l100 162 7 93 c18 238 93 458 224 656 104
157 318 355 460 425 39 18 54 38 130 161 52 85 108 162 143 196 145 144 341
196 539 143z m-65 -323 c200 -90 140 -391 -78 -393 -229 -2 -291 316 -77 401
40 16 112 12 155 -8z m130 -621 c39 -12 106 -41 150 -63 100 -52 233 -179 293
-282 226 -386 78 -876 -323 -1070 -120 -58 -201 -76 -333 -76 -221 0 -412 84
-559 246 -398 435 -176 1130 402 1259 99 22 277 15 370 -14z m-1197 -1129 c47
-24 66 -45 91 -101 37 -80 21 -161 -44 -225 -68 -69 -149 -82 -236 -37 -170
86 -146 323 39 383 38 12 105 3 150 -20z m2133 14 c152 -54 187 -245 65 -353
-77 -67 -185 -67 -262 0 -121 106 -87 300 62 353 51 18 82 18 135 0z"
        />
        <path
          d="M3351 4115 c-207 -58 -352 -244 -354 -455 -1 -93 35 -205 128 -394
92 -189 336 -606 354 -606 8 0 143 217 227 365 92 162 192 373 225 475 27 84
30 105 26 180 -2 54 -12 106 -26 142 -47 123 -163 235 -289 280 -75 28 -216
34 -291 13z m204 -264 c117 -53 159 -194 88 -295 -45 -64 -94 -87 -175 -84
-57 3 -72 8 -105 34 -54 44 -83 98 -83 158 0 147 146 246 275 187z"
        />
        <path
          d="M2487 3266 c-8 -20 19 -47 39 -39 22 8 13 47 -12 51 -12 2 -24 -4
-27 -12z"
        />
        <path
          d="M2373 2656 c-134 -33 -263 -124 -341 -240 -65 -98 -92 -177 -99 -296
-10 -177 45 -320 173 -447 113 -112 245 -166 409 -166 264 1 484 168 561 428
25 85 23 239 -5 325 -61 191 -224 346 -416 395 -71 18 -209 18 -282 1z m287
-86 c244 -71 401 -334 349 -582 -27 -128 -128 -271 -235 -334 -235 -138 -532
-70 -683 158 -63 93 -85 177 -79 304 4 97 7 109 46 186 80 161 222 266 387
287 75 10 132 5 215 -19z"
        />
        <path
          d="M2451 2410 c-100 -21 -189 -89 -233 -178 -19 -40 -23 -63 -23 -147 0
-92 2 -104 30 -154 34 -61 86 -110 154 -142 36 -18 64 -23 131 -23 73 -1 93 3
141 27 66 32 132 99 162 165 34 73 31 198 -6 274 -63 129 -217 206 -356 178z"
        />
        <path
          d="M1410 1525 c-17 -20 -5 -45 20 -45 11 0 23 7 26 15 6 15 -11 45 -26
45 -4 0 -13 -7 -20 -15z"
        />
        <path
          d="M3574 1526 c-9 -24 4 -48 23 -44 12 2 18 12 18 28 0 29 -32 41 -41
16z"
        />
      </g>
    </svg>
  );
};

LocalizadorPartes.propTypes = {
  className: PropTypes.string,
};

export default LocalizadorPartes;
