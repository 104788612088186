const sectoresItems = [
  {
    name: "Sector 1",
  },
  {
    name: "Sector 2",
  },
  {
    name: "Sector 3",
  },
  {
    name: "Sector 4",
  },
];

export default sectoresItems;
