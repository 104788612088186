// import { EyeOffIcon } from "@heroicons/react/outline";
import { useContext, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Return from "../assets/svg/corner-up-left.svg";
import { FetchContext } from "../context/FetchContext";

function CancelChange() {
  const { authAxios } = useContext(FetchContext);
  const { token } = useParams();

  const [error, setError] = useState(false);
  const [responseError, setResponseError] = useState("");

  const [success, setSuccess] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState("");

  useEffect(() => {
    const cancelPassword = async () => {
      try {
        setError(false);
        setSuccess(false);
        setResponseSuccess("");
        setResponseError("");
        const { data } = await authAxios.get("/recover/cancel/", {
          params: { token },
        });
        setSuccess(true);
        setResponseSuccess(data.message);
      } catch (err) {
        setError(true);
        const { data } = err.response;
        setResponseError(data.message);
      }
    };
    cancelPassword();
  }, []);

  return (
    <div className="mx-auto px-4 md:px-0 w-full max-w-sm lg:w-login sm:mx-6 lg:mx-20 xl:mx-24">
      <div>
        <h2 className="mt-6 text-2xl text-neutral-800 text-center">
          Cancelar Recuperación de contraseña
        </h2>
      </div>

      <div className="mt-8">
        <div className="mt-6">
          <div className="space-y-6">
            {error && (
              <div className="text-lg p-2 text-red-600 font-medium text-center">
                {responseError}
              </div>
            )}

            {success && (
              <div className="text-lg p-2 text-green-600 font-medium text-center">
                {responseSuccess}
              </div>
            )}

            <div className="pt-3 text-sm text-gray-600 flex justify-center">
              <Link to="/">
                <img src={Return} className="h-6 w-auto" alt="return home" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancelChange;
