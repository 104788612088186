import { Form, Formik, Field, ErrorMessage } from "formik";
import {
  MailIcon,
  PhoneIcon,
  LocationMarkerIcon,
} from "@heroicons/react/outline";
import * as Yup from "yup";
import { useContext, useState, useEffect } from "react";
import { FetchContext } from "../context/FetchContext";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";

function WeListen() {
  const history = useHistory();
  const { authAxios } = useContext(FetchContext);

  const [responseError, setResponseError] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [config, setConfig] = useState([]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("El campo es obligatorio"),
    lastName: Yup.string().required("El campo es obligatorio"),
    email: Yup.string()
      .email("El correo es invalido")
      .required("El campo es obligatorio"),
    phone: Yup.string().required("El campo es obligatorio"),
    company: Yup.string().required("El campo es obligatorio"),
    position: Yup.string().required("El campo es obligatorio"),
    comment: Yup.string().required("El campo es obligatorio"),
    feature: Yup.string().required("El campo es obligatorio"),
  });

  const handleSubmit = async (values, actions) => {
    try {
      setSuccess(false);
      setLoading(true);
      await authAxios.post("/info/comment", values);
      setSuccess(true);
      setError(false);
      setResponseError("");
      setLoading(false);
      actions.resetForm({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        company: "",
        position: "",
        comment: "",
        feature: "",
      });
    } catch (err) {
      setError(true);
      const { data } = err.response;
      if (data.statusCode === 401 && data.message === "Unauthorized") {
        setResponseError("La sesión ha expirado");
        setTimeout(() => {
          history.push("/");
        }, 1500);
      } else {
        setResponseError(`${data.statusCode}: ${data.error}`);
      }
    }
  };

  useEffect(() => {
    const getConfig = async () => {
      try {
        const { data } = await authAxios.get("info/config");
        setConfig(data);
        setLoadingConfig(false);
      } catch (err) {
        const { data } = err.response;
        if (data.statusCode === 401 && data.message === "Unauthorized") {
          toast.error("La sesión ha expirado");
          setTimeout(() => {
            history.push("/");
          }, 1500);
        } else {
          toast.error(data.message);
        }
      }
    };
    getConfig();
  }, []);

  if (loadingConfig) {
    return (
      <div className="container mx-auto py-16 px-4 flex justify-center items-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }

  return (
    <main className="focus:outline-none relative flex-1 overflow-y-auto">
      <div className="py-4">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <div className="bg-white px-4 py-5 shadow sm:p-6">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                company: "",
                position: "",
                comment: "",
                feature: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(isSubmitting) => (
                <Form className="space-y-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 mt-5 sm:col-span-6 md:mt-0">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        ¿Tienes alguna sugerencia o comentario que quieras
                        darnos? ¡Te escuchamos!
                      </h3>
                    </div>
                    <div className="col-span-6 mt-5 sm:col-span-6 md:mt-0">
                      <h4 className="leading-5 text-simblue-800">
                        Por favor deja tus datos y te responderemos a la
                        brevedad
                      </h4>
                    </div>

                    {success && (
                      <div className="col-span-6 rounded-md bg-green-50 p-4">
                        <p className="text-sm font-medium text-green-800">
                          Datos enviados correctamente
                        </p>
                      </div>
                    )}

                    {error && (
                      <div className="col-span-6 rounded-md bg-red-50 p-4">
                        <p className="text-sm font-medium text-red-800">
                          {responseError}
                        </p>
                      </div>
                    )}

                    <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                      <label
                        htmlFor="firstName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nombre (s)
                      </label>
                      <Field
                        id="firstName"
                        type="text"
                        name="firstName"
                        placeholder="Nombre completo"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <ErrorMessage
                        name="firstName"
                        render={(msg) => (
                          <div className="text-sm p-2 text-red-600 font-medium">
                            {msg}
                          </div>
                        )}
                      />
                    </div>

                    <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                      <label
                        htmlFor="lastName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Apellidos (s)
                      </label>
                      <Field
                        id="lastName"
                        type="text"
                        name="lastName"
                        placeholder="Apellidos completo"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <ErrorMessage
                        name="lastName"
                        render={(msg) => (
                          <div className="text-sm p-2 text-red-600 font-medium">
                            {msg}
                          </div>
                        )}
                      />
                    </div>

                    <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Correo electrónico
                      </label>
                      <Field
                        id="email"
                        type="text"
                        name="email"
                        placeholder="micorreo@example.com"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <ErrorMessage
                        name="email"
                        render={(msg) => (
                          <div className="text-sm p-2 text-red-600 font-medium">
                            {msg}
                          </div>
                        )}
                      />
                    </div>

                    <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Teléfono
                      </label>
                      <Field
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="000000000"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <ErrorMessage
                        name="phone"
                        render={(msg) => (
                          <div className="text-sm p-2 text-red-600 font-medium">
                            {msg}
                          </div>
                        )}
                      />
                    </div>

                    <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                      <label
                        htmlFor="company"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nombre de la empresa
                      </label>
                      <Field
                        type="text"
                        name="company"
                        id="company"
                        placeholder="Nombre de la empresa"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <ErrorMessage
                        name="company"
                        render={(msg) => (
                          <div className="text-sm p-2 text-red-600 font-medium">
                            {msg}
                          </div>
                        )}
                      />
                    </div>

                    <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                      <label
                        htmlFor="position"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Puesto o rol de la empresa
                      </label>
                      <Field
                        type="text"
                        name="position"
                        id="position"
                        placeholder=" Ej. Gerente de puntos de venta"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      <ErrorMessage
                        name="position"
                        render={(msg) => (
                          <div className="text-sm p-2 text-red-600 font-medium">
                            {msg}
                          </div>
                        )}
                      />
                    </div>

                    <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                      <label
                        htmlFor="comment"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Por favor cuéntanos ¿en qué podemos mejorar?
                      </label>
                      <Field
                        as="textarea"
                        name="comment"
                        id="comment"
                        rows={4}
                        placeholder="Mensaje"
                        className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                      />
                      <ErrorMessage
                        name="comment"
                        render={(msg) => (
                          <div className="text-sm p-2 text-red-600 font-medium">
                            {msg}
                          </div>
                        )}
                      />
                    </div>

                    <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                      <label
                        htmlFor="feature"
                        className="block text-sm font-medium text-gray-700"
                      >
                        ¿Qué funcionalidades o herramientas te gustaría tener?
                      </label>
                      <Field
                        as="textarea"
                        name="feature"
                        id="feature"
                        rows={4}
                        placeholder="Mensaje"
                        className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                      />
                      <ErrorMessage
                        name="feature"
                        render={(msg) => (
                          <div className="text-sm p-2 text-red-600 font-medium">
                            {msg}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-span-6 mt-5 sm:col-span-6 md:mt-0">
                    <button
                      type="submit"
                      className="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-simblue-900 py-4 px-8 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      {loading && (
                        <svg
                          role="status"
                          className="inline w-4 h-4 mr-3 text-white animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                      )}
                      Enviar mensaje
                    </button>
                  </div>
                </Form>
              )}
            </Formik>

            <div className="grid grid-cols-3 gap-6 mt-4 bg-neutral-300 p-6">
              <div className="col-span-3 mt-5 sm:col-span-1 md:mt-0 grid grid-cols-6 gap-1">
                <div className="col-span-1">
                  <PhoneIcon className="h6 w-6" />
                </div>
                <div className="col-span-5">
                  <h5 className="font-medium">Teléfonos:</h5>
                  {config.phones.split(",").map((item, index) => (
                    <div className="text-sm" key={index}>
                      {item}
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-span-3 mt-5 sm:col-span-1 md:mt-0 grid grid-cols-6 gap-2">
                <div className="col-span-1">
                  <LocationMarkerIcon className="h6 w-6" />
                </div>
                <div className="col-span-5">
                  <h5 className="font-medium">Ubicación:</h5>
                  <p className="text-sm">{config.address}</p>
                </div>
              </div>

              <div className="col-span-3 mt-5 sm:col-span-1 md:mt-0 grid grid-cols-6 gap-2">
                <div className="col-span-1">
                  <MailIcon className="h6 w-6" />
                </div>
                <div className="col-span-5">
                  <h5 className="font-medium">Email:</h5>
                  <p className="text-sm">{config.email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default WeListen;
