import { PropTypes } from "prop-types";

const SimRecalls = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="531.000000pt"
      height="530.000000pt"
      viewBox="0 0 531.000000 530.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,530.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2490 5294 c-742 -56 -1401 -392 -1872 -954 -308 -367 -514 -836
-584 -1325 -26 -182 -26 -548 0 -730 81 -566 324 -1064 721 -1473 426 -440
953 -705 1565 -788 153 -21 491 -23 635 -5 338 43 620 128 903 272 673 343
1166 954 1357 1683 68 258 79 358 79 676 0 318 -11 418 -79 676 -253 964
-1040 1714 -2010 1914 -209 43 -533 67 -715 54z m-419 -693 l29 -29 0 -416 0
-416 320 0 320 0 0 416 0 416 29 29 c42 42 75 38 162 -20 192 -126 325 -301
393 -518 98 -312 21 -661 -198 -900 l-50 -56 34 8 c65 15 137 8 197 -20 49
-22 104 -72 361 -328 l302 -302 0 -124 0 -125 -362 361 c-386 384 -390 387
-461 364 -22 -8 -100 -79 -234 -214 -221 -221 -230 -236 -199 -310 26 -63 110
-96 169 -66 12 6 69 58 127 115 85 84 113 106 142 111 32 5 41 1 68 -25 21
-21 30 -40 30 -63 0 -28 -16 -49 -117 -151 -129 -131 -184 -165 -275 -175
-193 -20 -364 164 -329 353 16 83 45 124 190 269 l131 130 0 103 c0 57 4 111
9 120 5 9 51 60 103 112 69 71 104 116 135 175 154 295 107 652 -117 888 l-59
62 -1 -372 0 -371 -26 -31 -26 -31 -448 0 -448 0 -26 31 -26 31 0 371 0 371
-41 -39 c-22 -22 -66 -78 -96 -123 -175 -261 -173 -597 5 -859 22 -33 76 -92
119 -133 l78 -73 3 -157 3 -158 122 -4 c108 -3 128 -7 173 -30 61 -32 119 -94
147 -157 38 -86 21 -220 -39 -298 l-25 -33 21 -30 c67 -93 82 -210 40 -307
-12 -28 -30 -59 -41 -69 -24 -22 -24 -36 0 -58 11 -10 29 -41 41 -69 42 -97
27 -214 -40 -307 l-21 -30 25 -33 c60 -78 77 -212 39 -298 -28 -63 -87 -125
-147 -157 -44 -22 -66 -27 -163 -30 -62 -2 -113 -6 -113 -9 0 -3 14 -32 32
-65 60 -114 183 -199 312 -217 197 -28 374 67 459 246 l32 68 5 462 5 461 24
26 c36 38 91 36 125 -5 l26 -31 0 -379 0 -379 349 0 c229 0 359 -4 380 -11 63
-22 77 -107 24 -149 -25 -19 -40 -20 -398 -20 l-373 0 -6 -34 c-14 -74 -77
-179 -155 -257 -123 -124 -256 -179 -431 -179 -175 0 -306 55 -431 181 -77 76
-140 181 -155 256 l-6 31 -132 4 c-119 3 -136 6 -182 30 -64 34 -128 104 -148
165 -25 72 -21 167 10 233 14 30 32 63 41 72 14 15 13 20 -6 47 -80 112 -82
267 -6 367 30 39 31 46 5 69 -32 29 -60 118 -60 191 0 69 15 116 57 177 24 34
25 39 10 55 -9 9 -27 42 -41 72 -31 66 -35 161 -10 233 21 61 84 132 148 165
45 23 65 27 174 30 l122 4 0 117 0 117 -61 59 c-247 243 -336 615 -227 955 63
198 206 384 382 499 93 60 124 64 167 22z"
        />
        <path
          d="M1571 2624 c-64 -53 -60 -156 8 -201 33 -23 38 -23 327 -23 l292 0
31 25 c63 53 63 146 0 199 l-31 26 -298 0 -298 0 -31 -26z"
        />
        <path
          d="M1595 2206 c-65 -28 -91 -126 -49 -183 44 -60 63 -63 351 -63 284 0
325 6 357 54 41 63 24 149 -36 186 -31 19 -49 20 -313 20 -210 -1 -288 -4
-310 -14z"
        />
        <path
          d="M1616 1780 c-84 -25 -117 -123 -68 -198 32 -49 50 -52 354 -52 266 0
285 1 316 20 93 57 72 200 -34 229 -46 13 -525 13 -568 1z"
        />
        <path
          d="M1580 1328 c-69 -46 -73 -148 -9 -202 l31 -26 298 0 298 0 31 25 c60
52 63 141 5 196 l-26 24 -297 3 c-296 3 -297 3 -331 -20z"
        />
      </g>
    </svg>
  );
};

SimRecalls.propTypes = {
  className: PropTypes.string,
};

export default SimRecalls;
