import React, { useCallback, useState, useEffect, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { FetchContext } from "../context/FetchContext";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { AvatarContext } from "../context/AvatarContext";

const UploadAvatar = () => {
  const history = useHistory();
  const { authAxios } = useContext(FetchContext);
  const { avatar, setAvatar } = useContext(AvatarContext);
  const [file, setFile] = useState({});

  const onDrop = useCallback((acceptedFiles) => {
    // console.log("acceptedFiles", acceptedFiles[0]);
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragReject, fileRejections } =
    useDropzone({
      onDrop,
      maxFiles: 1,
      multiple: false,
      accept: "image/jpeg, image/png, image/jpg",
      maxSize: 1048576,
      noDrag: true,
    });

  useEffect(() => {
    const uploadAvatar = async () => {
      try {
        if (file && Object.keys(file).length !== 0) {
          const formData = new FormData();
          formData.append("avatar", file);
          const { data } = await authAxios.post("/profile/upload", formData);
          setAvatar(data.avatar);
          toast.success("Avatar subido con exito");
        }
      } catch (err) {
        const { data } = err.response;
        if (data.statusCode === 401 && data.message === "Unauthorized") {
          toast.error("La sesión ha expirado");
          setTimeout(() => {
            history.push("/");
          }, 1500);
        } else {
          toast.error(data.message);
        }
      }
    };
    uploadAvatar();
  }, [file]);

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <>
        {errors.map((e) => (
          <React.Fragment key={e.code}>
            {e.code === "file-too-large" && (
              <li className="text-xs text-red-500">Archivo demasiado grande</li>
            )}

            {e.code === "file-invalid-type" && (
              <li className="text-xs text-red-500">
                Tipo de archivo no soportado
              </li>
            )}
            {e.code !== "file-too-large" && e.code !== "file-invalid-type" && (
              <li className="text-xs text-red-500">{e.message}</li>
            )}
          </React.Fragment>
        ))}
      </>
    );
  });

  return (
    <>
      <div className="flex items-center bg-white md:col-span-1 flex-col">
        <div className="flex h-40 w-40 items-center justify-center bg-neutral-300">
          <div className="flex h-32 w-32 items-center justify-center rounded-full bg-white">
            {avatar ? (
              <img
                src={`data:image/png;base64,${avatar}`}
                alt=""
                className="h-32 w-32 rounded-full"
              />
            ) : (
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        </div>

        <div
          className="w-40 mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
          {...getRootProps()}
        >
          <div className="space-y-1 text-center">
            <div className="flex justify-center text-sm text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                <span>Sube una foto</span>
                <input {...getInputProps()} />
              </label>
            </div>
            <p className="text-xs text-gray-500">PNG, JPG máximo 1MB</p>
            {isDragReject && (
              <p className="text-xs text-red-500">Archivo no soportado</p>
            )}
            <ul>{fileRejectionItems}</ul>
          </div>
        </div>
      </div>
      {/* <Toaster position="top-right" /> */}
    </>
  );
};

export default UploadAvatar;
