import { PropTypes } from "prop-types";

const Facebook = ({ className }) => {
  return (
    <svg
      width="14"
      height="25"
      viewBox="0 0 14 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.3333 8.75005H8.86778V6.25006C8.86778 4.96006 8.97419 4.14756 10.8478 4.14756H13.2143V0.172578C12.0627 0.055078 10.9048 -0.00242187 9.74569 7.81247e-05C6.30879 7.81247e-05 3.80048 2.07132 3.80048 5.87381V8.75005H0V13.75L3.80048 13.7488V25H8.86778V13.7463L12.7519 13.745L13.3333 8.75005Z"
        fill="#0B55AE"
      />
    </svg>
  );
};

Facebook.propTypes = {
  className: PropTypes.string,
};

export default Facebook;
