import { PropTypes } from "prop-types";

const SimValidation = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="526.000000pt"
      height="526.000000pt"
      viewBox="0 0 526.000000 526.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,526.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2445 5253 c-375 -30 -688 -117 -1012 -282 -942 -478 -1510 -1493
-1424 -2541 45 -535 236 -1020 564 -1434 367 -461 894 -794 1459 -920 232 -52
316 -61 593 -61 297 0 403 12 651 75 195 50 313 93 509 190 525 257 937 668
1195 1195 97 196 140 313 190 509 52 209 72 349 77 573 13 471 -81 887 -292
1291 -338 648 -946 1136 -1646 1321 -275 73 -606 105 -864 84z m489 -853 c341
-74 625 -220 885 -458 l64 -58 -137 -113 -136 -112 -63 56 c-125 114 -311 224
-478 283 -172 61 -274 77 -484 77 -162 0 -205 -4 -294 -23 -292 -65 -520 -190
-727 -396 -203 -203 -333 -441 -395 -721 -26 -115 -36 -366 -20 -488 80 -600
507 -1072 1108 -1224 102 -26 120 -28 333 -27 218 0 228 1 340 31 141 37 258
83 356 139 366 208 617 543 711 946 23 100 27 135 27 298 1 102 -3 210 -7 240
l-8 55 148 122 c115 95 150 118 156 107 5 -8 19 -66 32 -128 140 -661 -102
-1344 -628 -1770 -124 -99 -216 -157 -361 -226 -498 -237 -1056 -236 -1551 5
-468 227 -807 630 -950 1126 -91 314 -91 674 -1 984 164 566 599 1023 1151
1212 218 74 348 93 615 89 172 -3 235 -8 314 -26z m1448 -649 l157 -189 -1117
-923 c-614 -507 -1121 -922 -1127 -923 -10 -1 -695 788 -695 800 0 11 375 323
380 317 4 -5 89 -104 189 -221 l182 -212 76 62 c861 713 1789 1478 1793 1478
3 0 76 -85 162 -189z"
        />
      </g>
    </svg>
  );
};

SimValidation.propTypes = {
  className: PropTypes.string,
};

export default SimValidation;
