import { PropTypes } from "prop-types";

const Instagram = ({ className }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.28963 0C3.26984 0 0 3.27287 0 7.2937V17.7104C0 21.7302 3.27287 25 7.2937 25H17.7104C21.7302 25 25 21.7271 25 17.7063V7.28963C25 3.26984 21.7271 0 17.7063 0H7.28963ZM19.7917 4.16667C20.3667 4.16667 20.8333 4.63333 20.8333 5.20833C20.8333 5.78333 20.3667 6.25 19.7917 6.25C19.2167 6.25 18.75 5.78333 18.75 5.20833C18.75 4.63333 19.2167 4.16667 19.7917 4.16667ZM12.5 6.25C15.9469 6.25 18.75 9.05312 18.75 12.5C18.75 15.9469 15.9469 18.75 12.5 18.75C9.05312 18.75 6.25 15.9469 6.25 12.5C6.25 9.05312 9.05312 6.25 12.5 6.25ZM12.5 8.33333C11.3949 8.33333 10.3351 8.77232 9.55372 9.55372C8.77232 10.3351 8.33333 11.3949 8.33333 12.5C8.33333 13.6051 8.77232 14.6649 9.55372 15.4463C10.3351 16.2277 11.3949 16.6667 12.5 16.6667C13.6051 16.6667 14.6649 16.2277 15.4463 15.4463C16.2277 14.6649 16.6667 13.6051 16.6667 12.5C16.6667 11.3949 16.2277 10.3351 15.4463 9.55372C14.6649 8.77232 13.6051 8.33333 12.5 8.33333V8.33333Z"
        fill="#0B55AE"
      />
    </svg>
  );
};

Instagram.propTypes = {
  className: PropTypes.string,
};

export default Instagram;
