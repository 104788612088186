import { PropTypes } from "prop-types";

const OperationsBoard = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="530.000000pt"
      height="530.000000pt"
      viewBox="0 0 530.000000 530.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,530.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2415 5289 c-624 -59 -1193 -326 -1636 -768 -224 -225 -376 -439
-515 -726 -237 -488 -315 -1046 -223 -1593 69 -415 249 -823 516 -1175 105
-138 342 -373 483 -479 380 -286 802 -460 1270 -524 172 -24 511 -24 680 -1
605 84 1141 352 1556 778 395 407 638 896 726 1464 31 201 31 569 0 770 -92
594 -357 1108 -783 1520 -410 397 -918 643 -1489 721 -134 18 -455 26 -585 13z
m1675 -726 c29 -26 32 -38 61 -203 16 -96 31 -176 33 -177 2 -2 81 -17 177
-33 163 -29 176 -32 202 -60 53 -58 44 -76 -115 -238 -79 -79 -157 -151 -175
-158 -24 -10 -83 -14 -225 -14 l-193 0 -690 -691 c-380 -379 -702 -697 -717
-705 -50 -26 -96 -18 -139 25 -43 43 -51 89 -25 139 8 15 328 340 710 722
l696 695 0 209 0 209 133 135 c181 184 208 198 267 145z m-1385 -532 c187 -39
370 -109 535 -206 41 -25 88 -55 103 -67 l28 -23 -158 -157 -158 -157 -49 30
c-177 111 -465 180 -683 165 -550 -39 -992 -401 -1130 -926 -31 -118 -43 -360
-23 -492 36 -248 154 -483 338 -672 233 -240 537 -368 872 -368 150 0 226 10
356 48 193 55 374 163 519 309 352 352 459 871 275 1332 -17 43 -48 108 -69
144 l-39 67 157 156 158 157 24 -33 c43 -57 98 -154 143 -250 232 -499 203
-1075 -78 -1551 -320 -545 -946 -872 -1565 -819 -523 46 -974 309 -1263 737
-395 586 -377 1354 44 1927 81 111 261 287 378 370 212 151 493 261 760 298
109 15 411 4 525 -19z m-146 -786 c75 -16 231 -72 231 -83 0 -4 -75 -82 -167
-174 l-168 -168 -66 0 c-166 0 -319 -95 -391 -242 -120 -245 15 -545 277 -613
206 -53 416 47 508 242 26 57 32 83 35 158 l4 90 166 168 c92 92 170 167 173
167 9 0 43 -82 71 -170 20 -66 23 -95 23 -240 0 -160 -1 -168 -33 -260 -47
-135 -104 -230 -201 -332 -97 -102 -189 -167 -304 -213 -509 -206 -1076 98
-1188 638 -45 219 0 458 123 649 51 80 196 223 270 266 85 50 204 99 283 116
92 19 266 20 354 1z"
        />
      </g>
    </svg>
  );
};

OperationsBoard.propTypes = {
  className: PropTypes.string,
};

export default OperationsBoard;
