import { useContext } from "react";
import SwitchButton from "../components/SwitchButton";
import Check from "../components/icons/Dashboard/Check";
// import Social from "../components/icons/Dashboard/Social";
import Video from "../components/Video";
import { Tab } from "@headlessui/react";
import { categoriesContext } from "../context/CategoriesContext";
import { AppsIconEnum } from "../util/AppsEnum";
import { ModalContext } from "../context/ModalContext";
import MainModal from "./modals/MainModal";
import WhatsappModal from "./modals/WhatsappModal";
import PhoneModal from "./modals/PhoneModal";
import EmailModal from "./modals/EmailModal";
import { Toaster } from "react-hot-toast";
import CalendarModal from "./modals/CalendarModal";

function AppsTab() {
  const { categories } = useContext(categoriesContext);
  const { openModalMain, setProduct } = useContext(ModalContext);

  const handleMainModal = (title, key) => {
    setProduct({ title, key });
    openModalMain();
  };

  const { apps } = categories;
  const categoriesApp = apps.map((cat) => {
    const apps = cat.categoria.elementos.map((el) => {
      const app = {
        title: el.aplicacion.nombre,
        key: el.aplicacion.clave,
        icon: AppsIconEnum[el.aplicacion.clave],
        active: el.aplicacion.tienePermiso,
        description: el.aplicacion.descripcion,
        video: el.aplicacion.videoId,
        image: `${el.aplicacion.clave}.jpg`,
        sso: el.aplicacion.sso,
        ssoUrl: el.aplicacion.sso_url,
        url: el.aplicacion.url,
        available: el.available,
      };
      return app;
    });

    const item = {
      name: cat.categoria.nombre,
      key: cat.categoria.alias,
      apps,
    };

    return item;
  });

  return (
    <>
      <MainModal />
      <WhatsappModal />
      <PhoneModal />
      <EmailModal />
      <CalendarModal />

      <Tab.Panels>
        {categoriesApp.map((cat) => (
          <Tab.Panel
            key={cat.key}
            className="mx-auto grid max-w-7xl grid-cols-1 gap-2 px-2 sm:px-6 lg:grid-cols-2 lg:gap-4 2xl:grid-cols-3"
          >
            {cat.apps.map((app) => (
              <div
                key={app.title}
                className={`${
                  app.active ? "bg-white" : "border border-neutral-500"
                } flex flex-col justify-between p-4 shadow`}
              >
                <div flex flex-col>
                  <div className="mb-4 flex flex-wrap items-center justify-between">
                    <div className="flex items-center space-x-4">
                      <app.icon className="h-12 w-auto grow-0 fill-current text-primary-darkblue" />
                      <h1 className="text text-lg font-medium text-primary-lightblue">
                        {app.title}
                      </h1>
                    </div>
                    {app.available === 1 && (
                      <div className="">
                        {app.active ? (
                          <Check className="h-10 w-10 rounded-full bg-neutral-300 p-2" />
                        ) : (
                          <SwitchButton />
                        )}
                      </div>
                    )}
                  </div>

                  <div className="mb-4">{app.description}</div>
                </div>

                <div className="flex flex-col">
                  <Video
                    image={app.image}
                    video={app.video}
                    title={app.title}
                    appkey={app.key}
                  />

                  <div className="mt-4 flex justify-end">
                    {/* <button
                      type="button"
                      className="focus:outline-none inline-flex items-center rounded-md border border-simblue-900 bg-transparent px-4 py-2 text-sm font-medium text-simblue-900 shadow-sm focus:ring-2 focus:ring-simblue-400 focus:ring-offset-2"
                    >
                      Compartir
                      <Social className="ml-6" />
                    </button> */}

                    {app.active ? (
                      <a
                        href={app.sso ? app.ssoUrl : app.url}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-simblue-900 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-simblue-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Entrar
                      </a>
                    ) : (
                      <button
                        type="button"
                        onClick={() => handleMainModal(app.title, app.key)}
                        className="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-simblue-900 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-simblue-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Saber más
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </Tab.Panel>
        ))}
      </Tab.Panels>
      <Toaster />
    </>
  );
}

export default AppsTab;
