import { Link, NavLink } from "react-router-dom";
import { PropTypes } from "prop-types";
import logo from "../assets/svg/logo.svg";

TermsShell.propTypes = {
  children: PropTypes.object,
};

function TermsShell({ children }) {
  return (
    <div className="bg-gray-50 w-full min-h-screen">
      <div className="flex items-center bg-white fixed left-0 right-0 shadow-sm">
        <div className="px-8 py-4">
          <Link to="/">
            <img className="h-8 w-auto" src={logo} alt="Workflow" />
          </Link>
        </div>
        <div className="px-8 py-4 flex-1">
          <nav className="flex justify-end space-x-8 text-neutral-600">
            <NavLink
              to="/terms-conditions"
              activeClassName="text-simblue-900 underline"
            >
              Términos y condiciones
            </NavLink>
            <NavLink
              to="/privacy-policies"
              activeClassName="text-simblue-900 underline"
            >
              Políticas de privacidad
            </NavLink>
            {/* <Link to="/">Ayuda y soporte</Link> */}
          </nav>
        </div>
      </div>
      {children}
    </div>
  );
}

export default TermsShell;
