import React from "react";
import { PropTypes } from "prop-types";

// Ant-design
import { Empty } from "antd";

export class TableEmpty extends React.Component {
  descripcion = "Sin resultados";

  /**
   * Verificar si todas las filas estan ocultas
   */
  sinResultados = () => {
    if (this.props.datos.length > 0) {
      const ocultos = this.props.datos.filter((dato) => !dato.visible).length;

      return ocultos === this.props.datos.length;
    } else {
      return false;
    }
  };

  render() {
    return this.sinResultados() ? (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={this.descripcion}
        className="table-no-results"
      />
    ) : (
      ""
    );
  }
}

TableEmpty.propTypes = {
  datos: PropTypes.array,
};

export default TableEmpty;
