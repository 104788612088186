import { useState } from "react";

// eslint-disable-next-line react/prop-types
function PreviewCover({ cover }) {
  const imageMimeType = /image\/(png|jpg|jpeg)/i;

  // eslint-disable-next-line react/prop-types
  if (!cover.type.match(imageMimeType)) {
    console.log("Archivo no permitido");
    return <></>;
  }

  const [preview, setPreview] = useState(null);
  const reader = new FileReader();
  reader.readAsDataURL(cover);
  reader.onload = () => {
    setPreview(reader.result);
  };
  return (
    <div>
      {preview ? (
        <img
          src={preview}
          alt="image preview"
          className="object-contain h-48 w-96"
        />
      ) : (
        "Loading ..."
      )}
    </div>
  );
}

export default PreviewCover;
