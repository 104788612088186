import { createContext, useState } from "react";
import { PropTypes } from "prop-types";

const sectionTitleContext = createContext(null);
const { Provider } = sectionTitleContext;

const SectionTitleProvider = ({ children }) => {
  const [sectionTitle, setSectionTitle] = useState("Simhub");

  return (
    <Provider
      value={{
        sectionTitle,
        setSectionTitle: (sims) => setSectionTitle(sims),
      }}
    >
      {children}
    </Provider>
  );
};

SectionTitleProvider.propTypes = {
  children: PropTypes.object,
};

export { sectionTitleContext, SectionTitleProvider };
