import { Fragment, useContext } from "react";
import { PropTypes } from "prop-types";
import { Menu, Transition } from "@headlessui/react";
import { MenuAlt2Icon } from "@heroicons/react/outline";
import classNames from "../util/ClassNames";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { FetchContext } from "../context/FetchContext";
import { sectionTitleContext } from "../context/SectionTitleContext";
import User from "./icons/Dashboard/User";
import { AvatarContext } from "../context/AvatarContext";

NavBarTop.propTypes = {
  setSidebarOpen: PropTypes.func,
};

function NavBarTop({ setSidebarOpen }) {
  const { authAxios } = useContext(FetchContext);
  const { avatar } = useContext(AvatarContext);
  const { authState, logout } = useContext(AuthContext);
  const { sectionTitle } = useContext(sectionTitleContext);

  const handleLogout = async () => {
    try {
      if (process.env.REACT_APP_LOGIN_CLASSIC === "true") {
        await authAxios.post("auth/logout");
        logout();
      } else {
        window.location.href = `${process.env.REACT_APP_SSO_URI}/session/end`;
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div className="relative z-10 flex h-24 flex-shrink-0 border-b-2 border-neutral-300 bg-white shadow-sm">
      <button
        type="button"
        className="focus:outline-none border-r border-gray-200 px-4 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex flex-1 justify-between px-8">
        <div className="flex flex-1 items-center justify-items-center">
          <h1 className="text-xl pt-2 md:text-3xl text-gray-900">
            {sectionTitle}
          </h1>
        </div>
        <div className="ml-4 flex items-center md:ml-6">
          {/* Profile dropdown */}
          <Menu as="div" className="relative ml-3">
            <div>
              <Menu.Button className="hidden md:flex focus:outline-none space-x-2  max-w-xs items-center border-2 px-4 py-2 rounded-lg bg-white text-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                {avatar ? (
                  <div>
                    <img
                      src={`data:image/png;base64,${avatar}`}
                      alt=""
                      className="h-8 w-8 rounded-full"
                    />
                  </div>
                ) : (
                  <div className="bg-neutral-400 rounded-full p-2">
                    <User className="w-6 h-auto" />
                  </div>
                )}

                <span>{authState.profile.nombreCompleto}</span>
              </Menu.Button>

              <Menu.Button className="md:hidden focus:outline-none space-x-2 flex max-w-xs items-center px-4 py-2 rounded-lg bg-white text-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <div className="bg-neutral-300 rounded-sm p-2">
                  <User className="w-6 h-auto" />
                </div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="focus:outline-none absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5">
                {/* {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <Link
                        to={item.href}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        {item.name}
                      </Link>
                    )}
                  </Menu.Item>
                ))} */}

                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/profile"
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700"
                      )}
                    >
                      Perfil
                    </Link>
                  )}
                </Menu.Item>

                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700"
                      )}
                      onClick={handleLogout}
                    >
                      Cerrar sesión
                    </a>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
}

export default NavBarTop;
