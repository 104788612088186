import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import { useContext } from "react";

// Context
import { SectionTitleProvider } from "./context/SectionTitleContext";
import { AuthContext, AuthProvider } from "./context/AuthContext";
import { CategoriesProviders } from "./context/CategoriesContext";
import { MySimsProviders } from "./context/MySimsContext";
import { AvatarProvider } from "./context/AvatarContext";
import { FetchProvider } from "./context/FetchContext";
import { ModalProvider } from "./context/ModalContext";
// import { UsageProvider } from "./context/UsageContext";

// Pages
import useZendesk, { ZendeskWidget } from "./hooks/useZendesk";
import { AuthTermsShell } from "./components/AuthTermsShell";
import { AuthAppShell } from "./components/AuthAppShell";
import RecoveryPassword from "./pages/RecoveryPassword";
import ChangePassword from "./pages/ChangePassword";
import { LoginForm } from "./components/LoginForm";
import RecoveryShell from "./pages/RecoveryShell";
import { FourOhFour } from "./pages/FourOhFour";
import CancelChange from "./pages/CancelChange";
import InviteFriend from "./pages/InviteFriend";
import SimIndexList from "./pages/SimIndexList";
import LoginShell from "./pages/LoginShell";
import WeListen from "./pages/WeListen";
import AllApps from "./pages/AllApps";
import Privacy from "./pages/Privacy";
import Profile from "./pages/Profile";
import Session from "./pages/Session";
import MySims from "./pages/MySims";
import Terms from "./pages/Terms";
import Home from "./pages/Home";
import Logout from "./pages/Logout";
import SimIndexNew from "./pages/SimIndexNew";
import SimIndexEdit from "./pages/SimIndexEdit";
import SimIndex from "./pages/SimIndex";
import Usage from "./pages/Usage";
import UsageClient from "./pages/UsageClient";
import UsageUser from "./pages/UsageUser";
import Quickwins from "./pages/Quickwins";

// const Home = lazy(() => import("./pages/Home"));
// const Home = lazy(() => import("./pages/Home"));

const AppRoutes = () => {
  const { isAuthenticated } = useContext(AuthContext);
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() =>
          isAuthenticated() ? (
            <Redirect to="/home" />
          ) : (
            <LoginShell>
              <LoginForm />
            </LoginShell>
          )
        }
      />
      <AuthAppShell path="/home" sectionTitle="Home">
        <Home />
      </AuthAppShell>

      <AuthAppShell path="/my-sims" sectionTitle="My Sims Apps">
        <MySims />
      </AuthAppShell>

      <AuthAppShell
        path="/all-apps/:selectedTab?"
        sectionTitle="Todas las apps"
      >
        <ModalProvider>
          <AllApps />
        </ModalProvider>
      </AuthAppShell>

      <AuthAppShell path="/profile" sectionTitle="Perfil">
        <Profile />
      </AuthAppShell>

      <AuthAppShell
        path="/usage/client/:app/:fecha/:id?"
        sectionTitle="Usage Client"
      >
        <UsageClient />
      </AuthAppShell>

      <AuthAppShell
        path="/usage/user/:app/:fecha/:id/:email/:nombreCliente?"
        sectionTitle="Usage User"
      >
        <UsageUser />
      </AuthAppShell>

      <AuthAppShell path="/usage" sectionTitle="Usage">
        <Usage />
      </AuthAppShell>

      <AuthAppShell path="/quickwins" sectionTitle="Quickwins">
        <Quickwins />
      </AuthAppShell>

      <AuthAppShell path="/simindex" sectionTitle="SimIndex">
        <SimIndex />
      </AuthAppShell>

      <AuthAppShell path="/admin/simindex/list" sectionTitle="Admin SimIndex">
        <SimIndexList />
      </AuthAppShell>

      <AuthAppShell path="/admin/simindex/new" sectionTitle="Admin SimIndex">
        <SimIndexNew />
      </AuthAppShell>

      <AuthAppShell
        path="/admin/simindex/edit/:id"
        sectionTitle="Admin SimIndex"
      >
        <SimIndexEdit />
      </AuthAppShell>

      <AuthAppShell path="/invite-friend" sectionTitle="Invitar a un amigo">
        <InviteFriend />
      </AuthAppShell>

      <AuthAppShell path="/we-listen" sectionTitle="Te escuchamos">
        <WeListen />
      </AuthAppShell>

      <Route path="/password/step-1">
        <RecoveryShell>
          <RecoveryPassword />
        </RecoveryShell>
      </Route>

      <Route path="/password/change/:token">
        <RecoveryShell>
          <ChangePassword />
        </RecoveryShell>
      </Route>

      <Route path="/password/cancel/:token">
        <RecoveryShell>
          <CancelChange />
        </RecoveryShell>
      </Route>

      <AuthTermsShell path="/terms-conditions">
        <Terms />
      </AuthTermsShell>

      <AuthTermsShell path="/privacy-policies">
        <Privacy />
      </AuthTermsShell>

      <Route path="/session">
        <Session />
      </Route>

      <Route path="/logout">
        <Logout />
      </Route>

      <Route path="*">
        <FourOhFour />
      </Route>
    </Switch>
  );
};

function App() {
  useZendesk(process.env.REACT_APP_ZENDESK_KEY, {}, () =>
    ZendeskWidget("webWidget", "hide")
  );
  return (
    <>
      <Router>
        <AuthProvider>
          <FetchProvider>
            <CategoriesProviders>
              <MySimsProviders>
                <AvatarProvider>
                  <SectionTitleProvider>
                    <AppRoutes />
                  </SectionTitleProvider>
                </AvatarProvider>
              </MySimsProviders>
            </CategoriesProviders>
          </FetchProvider>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
