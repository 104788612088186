import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper/core";
import { useEffect, useContext, useState } from "react";
import { FetchContext } from "../context/FetchContext";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

SwiperCore.use([Navigation]);

function SimIndex() {
  const { authAxios } = useContext(FetchContext);
  const [simindex, setSimindex] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const getSimindex = async () => {
      try {
        const { data } = await authAxios.get("/simindex");
        setSimindex(data);
        setLoading(true);
      } catch (err) {
        const { data } = err.response;
        if (data.statusCode === 401 && data.message === "Unauthorized") {
          toast.error("La sesión ha expirado");
          // console.log("La sesión ha expirado");
          setTimeout(() => {
            history.push("/");
          }, 1500);
        } else {
          toast.error(data.message);
          // console.log(data.message);
        }
      }
    };
    getSimindex();
  }, []);

  if (!loading) {
    return (
      <div className="container mx-auto py-16 px-4 flex justify-center items-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }

  return (
    <main className="focus:outline-none relative flex-1 overflow-y-auto">
      <div className="py-4">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <div className="bg-white px-4 py-5 shadow sm:p-6">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              navigation={true}
            >
              {simindex.map((sim) => (
                <SwiperSlide key={sim.id}>
                  <div className="col-span-2">
                    <a href={sim.url} target="_blank" rel="noopener noreferrer">
                      <img
                        className="object-cover h-48 w-96 "
                        src={`data:image/png;base64,${sim.image}`}
                      />
                    </a>
                  </div>
                  {/* <div className="col-span-1 bg-black p-4">
                    <h3 className="text-simblue-600 text-sm font-bold mb-2">
                      {sim.tags}
                    </h3>
                    <h1 className="text-2xl font-extrabold text-white sm:text-3xl mb-3">
                      {sim.title}
                    </h1>
                    <p className="text-white mb-3">{sim.description}</p>
                    <a
                      className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      href={sim.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LEER MÁS
                    </a>
                  </div> */}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      <Toaster />
    </main>
  );
}

export default SimIndex;
