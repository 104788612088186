import SimBusiness from "../components/icons/Categories/SimBusiness";
import AutomotrizSuite from "../components/icons/Categories/AutomotrizSuite";
import SimHuman from "../components/icons/Categories/SimHuman";
import SimPolls from "../components/icons/Categories/SimPolls";
import SimLogistics from "../components/icons/Categories/SimLogistics";
import SimAnalytics from "../components/icons/Apps/SimAnalytics";
import SimStatements from "../components/icons/Apps/SimStatements";
import DBDigger from "../components/icons/Apps/DBDigger";
import SimInventory from "../components/icons/Apps/SimInventory";
import SimReports from "../components/icons/Apps/SimReports";
import Simetrcis from "../components/icons/Apps/Simetrcis";
import DBU from "../components/icons/Apps/DBU";
import CrissCross from "../components/icons/Apps/CrissCross";
import SimRecalls from "../components/icons/Apps/SimRecalls";
import Demografia from "../components/icons/Apps/Demografia";
import LocalizadorPartes from "../components/icons/Apps/LocalizadorPartes";
import LocalizadorAutos from "../components/icons/Apps/LocalizadorAutos";
import HistorialServicio from "../components/icons/Apps/HistorialServicio";
import PotencialVentas from "../components/icons/Apps/PotencialVentas";
import Top20 from "../components/icons/Apps/Top20";
import OperationsBoard from "../components/icons/Apps/OperationBoard";
import SimValidation from "../components/icons/Apps/SimValidation";
import SimProcess from "../components/icons/Apps/SimProcess";
import CustomerPulse from "../components/icons/Apps/CustomerPulse";

export const CategoryIconEnum = {
  "sim-business": SimBusiness,
  "suite-oem": AutomotrizSuite,
  "sim-human": SimHuman,
  "sim-polls": SimPolls,
  "sim-logistic": SimLogistics,
  "sim-finance": SimBusiness,
};

export const AppsIconEnum = {
  SimAnalytics: SimAnalytics,
  estfinancieroclient: SimStatements,
  SimInv: SimInventory,
  dbr: SimReports,
  Scorecard: Simetrcis,
  Parametrix: Simetrcis,
  DBU: DBU,
  DealerCC: CrissCross,
  vrecall: SimRecalls,
  Demografia: Demografia,
  LocalizadorPartes: LocalizadorPartes,
  LocalizadorAutos: LocalizadorAutos,
  histservicio: HistorialServicio,
  refpot: PotencialVentas,
  topclient: Top20,
  DataBaseDigger: DBDigger,
  evalcorpclient: OperationsBoard,
  SimVal: SimValidation,
  simProcs: SimProcess,
  Encuesta360: SimAnalytics,
  ClimaOrganizacional: SimAnalytics,
  BuzonAnonimo: SimAnalytics,
  CustomerPulse: CustomerPulse,
};
