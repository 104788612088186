import { useState, useEffect, useContext } from "react";
import { PropTypes } from "prop-types";
import SidebarDesktop from "../components/SidebarDesktop";
import SidebarMobile from "../components/SidebarMobile";
import { categoriesContext } from "../context/CategoriesContext";
import { mySimsContext } from "../context/MySimsContext";
import { FetchContext } from "../context/FetchContext";
import NavBarTop from "../components/NavBarTop";
import Zendesk from "../components/Zendesk";
import { AvatarContext } from "../context/AvatarContext";

AppShell.propTypes = {
  children: PropTypes.object,
};

function AppShell({ children }) {
  // Axios
  const { authAxios } = useContext(FetchContext);
  const { setAvatar } = useContext(AvatarContext);

  // loadings
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingSims, setLoadingSims] = useState(false);
  const [loadingAvatar, setLoadingAvatar] = useState(false);

  // Context
  const { setCategories } = useContext(categoriesContext);
  const { setMySims } = useContext(mySimsContext);

  // Sidebar
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarOpenDesk, setSidebarOpenDesk] = useState(false);

  useEffect(() => {
    // categories
    const getCategories = async () => {
      try {
        const { data } = await authAxios.get("menu/categories");
        setCategories({ apps: data.apps.categoriaSets });
        setLoadingCategories(true);
      } catch (err) {
        console.log(err);
      }
    };
    // my sims
    const getSims = async () => {
      try {
        const { data } = await authAxios.get("application");
        setMySims(data);
        setLoadingSims(true);
      } catch (error) {
        console.log(error);
      }
    };

    // avatar
    const getAvatar = async () => {
      try {
        const { data } = await authAxios.get("profile/avatar");
        setAvatar(data.avatar);
        setLoadingAvatar(true);
      } catch (error) {
        console.log(error);
      }
    };

    getSims();
    getCategories();
    getAvatar();
  }, []);

  if (!loadingCategories) {
    return (
      <div className="container h-screen  mx-auto py-16 px-4 flex justify-center items-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }

  if (!loadingSims) {
    return (
      <div className="container h-screen  mx-auto py-16 px-4 flex justify-center items-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }

  if (!loadingAvatar) {
    return (
      <div className="container h-screen  mx-auto py-16 px-4 flex justify-center items-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }

  return (
    <div className="h-screen flex overflow-hidden bg-neutral-300">
      {/* Static sidebar for mobile */}
      <SidebarMobile
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />

      {/* Static sidebar for desktop */}
      <SidebarDesktop
        sidebarOpenDesk={sidebarOpenDesk}
        setSidebarOpenDesk={setSidebarOpenDesk}
      />

      <div className="flex flex-col w-0 flex-1">
        {/* Nav Bar principal */}
        <NavBarTop setSidebarOpen={setSidebarOpen} />

        {children}
      </div>
      <Zendesk />
    </div>
  );
}

export default AppShell;
