import { PropTypes } from "prop-types";

const Linken = ({ className }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M22.7385 0H2.26145C1.0125 0 0 1.0125 0 2.26145V22.7385C0 23.9875 1.0125 25 2.26145 25H22.7385C23.9875 25 25 23.9875 25 22.7385V2.26145C25 1.0125 23.9875 0 22.7385 0ZM7.73606 21.5866C7.73606 21.9501 7.44143 22.2448 7.07795 22.2448H4.27651C3.91302 22.2448 3.61839 21.9501 3.61839 21.5866V9.84313C3.61839 9.47965 3.91302 9.18501 4.27651 9.18501H7.07795C7.44143 9.18501 7.73606 9.47965 7.73606 9.84313V21.5866ZM5.67723 8.07801C4.2074 8.07801 3.01584 6.88645 3.01584 5.41662C3.01584 3.94679 4.2074 2.75524 5.67723 2.75524C7.14706 2.75524 8.33861 3.94679 8.33861 5.41662C8.33861 6.88645 7.14712 8.07801 5.67723 8.07801ZM22.3763 21.6397C22.3763 21.9738 22.1054 22.2448 21.7712 22.2448H18.7651C18.4309 22.2448 18.1599 21.9738 18.1599 21.6397V16.1313C18.1599 15.3096 18.401 12.5304 16.0125 12.5304C14.1598 12.5304 13.784 14.4327 13.7086 15.2863V21.6397C13.7086 21.9738 13.4377 22.2448 13.1035 22.2448H10.196C9.86185 22.2448 9.5909 21.9738 9.5909 21.6397V9.79012C9.5909 9.45596 9.86185 9.18501 10.196 9.18501H13.1035C13.4376 9.18501 13.7086 9.45596 13.7086 9.79012V10.8147C14.3955 9.7837 15.4165 8.98796 17.5902 8.98796C22.4039 8.98796 22.3763 13.4851 22.3763 15.956V21.6397Z"
        fill="#0B55AE"
      />
    </svg>
  );
};

Linken.propTypes = {
  className: PropTypes.string,
};

export default Linken;
