const columnsResumenUser = [
  {
    propiedad: "Email",
    etiquetaTraduccion: "Email",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "Email",
    width: 100,
  },
  {
    propiedad: "application",
    etiquetaTraduccion: "Aplicación",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "application",
    width: 130,
  },
  {
    propiedad: "Fecha",
    etiquetaTraduccion: "Fecha de Acceso",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "Fecha",
    width: 150,
  },
  {
    propiedad: "ip",
    etiquetaTraduccion: "IP",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "ip",
    width: 100,
  },
  {
    propiedad: "isp",
    etiquetaTraduccion: "ISP",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "isp",
    width: 140,
  },
  {
    propiedad: "city",
    etiquetaTraduccion: "Ciudad",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "city",
    width: 130,
  },
  {
    propiedad: "region_name",
    etiquetaTraduccion: "Región",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "region_name",
    width: 130,
  },
  {
    propiedad: "country_code",
    etiquetaTraduccion: "Codigo Pais",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "country_code",
    width: 130,
  },
  {
    propiedad: "lat",
    etiquetaTraduccion: "Latitud",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "lat",
    width: 130,
  },
  {
    propiedad: "lon",
    etiquetaTraduccion: "Longitud",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "lon",
    width: 130,
  },
  {
    propiedad: "ip",
    etiquetaTraduccion: "IP",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "ip",
    width: 100,
  },
  {
    propiedad: "browser",
    etiquetaTraduccion: "Navegador",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "browser",
    width: 130,
  },
  {
    propiedad: "os_version",
    etiquetaTraduccion: "Versión",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "os_version",
    width: 130,
  },
  {
    propiedad: "device",
    etiquetaTraduccion: "Equipo",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "device",
    width: 130,
  },
  {
    propiedad: "os",
    etiquetaTraduccion: "Sistema Operativo",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "os",
    width: 120,
  },
];

export default columnsResumenUser;
