import { useEffect, useContext } from "react";
import { FetchContext } from "../context/FetchContext";
import { AuthContext } from "../context/AuthContext";

const Logout = () => {
  const { authAxios } = useContext(FetchContext);
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    const getLogout = async () => {
      try {
        await authAxios.post("auth/logout");
        logout();
      } catch (err) {
        console.log(err);
      }
    };

    getLogout();
  }, []);
  return <></>;
};

export default Logout;
