import Modal from "react-responsive-modal";
import { MailIcon } from "@heroicons/react/outline";
import { PropTypes } from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState, useContext, Fragment } from "react";
import { FetchContext } from "../../context/FetchContext";
import { useHistory } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/solid";

const InviteEmailModal = ({ modalEmail, setModalEmail }) => {
  const history = useHistory();

  const { authAxios } = useContext(FetchContext);
  const [error, setError] = useState(false);
  const [responseError, setResponseError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("El campo es obligatorio"),
    lastname: Yup.string().required("El campo es obligatorio"),
    email: Yup.string()
      .email("El correo es invalido")
      .required("El correo es requerido"),
  });

  const handleSubmitFriendEmail = async (values) => {
    try {
      // console.log(values);
      setLoading(true);
      await authAxios.post("/info/invite/email", values);
      setSuccess(true);
      setError(false);
      setResponseError("");
      setLoading(false);

      setTimeout(() => {
        // setModalEmail(false); // cierra el modal
        // onCloseModalMain();
        // setSuccess(false); //quita el mensaje de envio
      }, 1500);
    } catch (err) {
      setError(true);
      const { data } = err.response;
      if (data.statusCode === 401 && data.message === "Unauthorized") {
        setResponseError("La sesión ha expirado");
        setTimeout(() => {
          history.push("/");
        }, 1500);
      } else {
        setResponseError(`${data.statusCode}: ${data.error}`);
      }
    }
  };

  const handleOnCloseModal = () => {
    setSuccess(false);
    setModalEmail(false);
  };

  const handleOnNewInvite = () => {
    setSuccess(false);
  };

  return (
    <Modal
      open={modalEmail}
      onClose={() => handleOnCloseModal()}
      center
      classNames={{ overlay: "customOverlay", modal: "emailModal" }}
    >
      {success ? (
        <div className="">
          <div>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <CheckIcon
                className="h-6 w-6 text-green-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                El correo ha sido enviado a tu amigo
              </h3>
              {/* <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius
                  aliquam laudantium explicabo pariatur iste dolorem animi vitae
                  error totam. At sapiente aliquam accusamus facere veritatis.
                </p>
              </div> */}
            </div>
          </div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-simblue-800 text-base font-medium text-white hover:bg-simblue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-simblue-600 sm:col-start-2 sm:text-sm"
              onClick={() => handleOnNewInvite()}
            >
              Inivitar otro amigo
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-simblue-600 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={() => handleOnCloseModal()}
            >
              Regresar
            </button>
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-simblue-300 sm:mx-0 sm:h-10 sm:w-10">
              <MailIcon
                className="h-6 w-6 text-simblue-800"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Invitar a un amigo
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Confirma los datos de contacto
                </p>
              </div>
            </div>
          </div>

          {error && (
            <div className="rounded-md bg-red-50 p-4">
              <p className="text-sm font-medium text-red-800">
                {responseError}
              </p>
            </div>
          )}

          <Formik
            initialValues={{
              firstname: "",
              lastname: "",
              email: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmitFriendEmail}
          >
            {({ values, errors, touched }) => (
              <Form>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="firstname"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Nombre
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Field
                      type="text"
                      name="firstname"
                      autoComplete="given-name"
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      name="firstname"
                      component="div"
                      className="text-sm text-red-600"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="lastname"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Apellido
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Field
                      type="text"
                      name="lastname"
                      autoComplete="given-name"
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      name="lastname"
                      component="div"
                      className="text-sm text-red-600"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Email
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Field
                      type="text"
                      name="email"
                      autoComplete="given-name"
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-sm text-red-600"
                    />
                  </div>
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    {loading && (
                      <svg
                        role="status"
                        className="inline w-4 h-4 mr-3 text-white animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    )}
                    Enviar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Fragment>
      )}
    </Modal>
  );
};

InviteEmailModal.propTypes = {
  modalEmail: PropTypes.bool,
  setModalEmail: PropTypes.func,
};

export default InviteEmailModal;
