import Whatsapp from "../components/icons/Dashboard/Whatsapp";
import { MailIcon } from "@heroicons/react/outline";
import { useState } from "react";
import InviteEmailModal from "../components/modals/InviteEmailModal";
import IniviteWhatsModal from "../components/modals/InviteWhatsModal";
import ReactTooltip from "react-tooltip";

function InviteFriend() {
  const [modalEmail, setModalEmail] = useState(false);
  const [modalWhats, setModalWhats] = useState(false);

  return (
    <main className="focus:outline-none relative flex-1 overflow-y-auto">
      <InviteEmailModal modalEmail={modalEmail} setModalEmail={setModalEmail} />
      <IniviteWhatsModal
        modalWhats={modalWhats}
        setModalWhats={setModalWhats}
      />
      <div className="py-4">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div className="bg-white">
              <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                <div className="bg-simblue-800 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-1 lg:gap-4">
                  <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                    <ReactTooltip
                      place="top"
                      type="dark"
                      effect="solid"
                      id="whatsapptooltip"
                    >
                      Enviar por whatsapp
                    </ReactTooltip>

                    <ReactTooltip
                      place="top"
                      type="dark"
                      effect="solid"
                      id="emailtooltip"
                    >
                      Enviar por correo electrónico
                    </ReactTooltip>

                    <div className="lg:self-center">
                      {/* <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                        <span className="block">
                          Comparte nuestros productos
                        </span>
                      </h2> */}
                      <p className="mt-4 text-2xl text-justify text-white">
                        Si crees que nuestras herramientas pueden ayudar a un
                        amigo a llevar su negocio al siguiente nivel invitalo
                        seleccionando una opción
                      </p>
                      <div className="flex justify-evenly pt-10">
                        <button
                          data-tip
                          data-for="whatsapptooltip"
                          type="button"
                          onClick={() => setModalWhats(true)}
                          className="w-full inline-flex justify-center rounded-md border border-transparent  px-4 py-2 text-base font-medium sm:w-auto sm:text-sm"
                        >
                          <Whatsapp className="h-14 w-14 animate-bounce" />
                        </button>

                        <button
                          type="button"
                          data-tip
                          data-for="emailtooltip"
                          onClick={() => setModalEmail(true)}
                          className="w-full inline-flex justify-center rounded-md border border-transparent  px-4 py-2  text-base font-medium text-white hover:text-gray-50 sm:w-auto sm:text-sm"
                        >
                          <MailIcon className="h-14 w-14 animate-bounce" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
                    <img
                      className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                      src="https://tailwindui.com/img/component-images/full-width-with-sidebar.jpg"
                      alt="App screenshot"
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default InviteFriend;
