import React, { useState, useContext } from "react";
import ModalVideo from "react-modal-video";
import { PropTypes } from "prop-types";
import { useHistory } from "react-router-dom";
import { FetchContext } from "../context/FetchContext";

function Video({ image, video, title, appkey }) {
  const history = useHistory();
  const { authAxios } = useContext(FetchContext);
  const [isOpen, setOpen] = useState(false);
  const [data] = useState({
    title: title,
    key: appkey,
  });

  const handleOpenVideo = async () => {
    try {
      setOpen(true);
      await authAxios.post("/info/video", data);
    } catch (err) {
      if (data.statusCode === 401 && data.message === "Unauthorized") {
        console.log("La sesión ha expirado");
        setTimeout(() => {
          history.push("/");
        }, 1500);
      } else {
        console.log(`${data.statusCode}: ${data.error}`);
      }
    }
  };
  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={video}
        onClose={() => setOpen(false)}
      />

      <div className="relative cursor-pointer" onClick={handleOpenVideo}>
        <div className="relative w-full">
          <img
            className="h-full w-full object-cover object-center"
            src={`${process.env.PUBLIC_URL}/assets/images/${image}`}
            alt=""
          />
        </div>

        <div className="absolute inset-0 flex items-center justify-center overflow-hidden rounded-lg bg-transparent p-4">
          <div className="relative cursor-pointer rounded-full bg-white p-4">
            <svg
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="#0049A2"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 8L16 12L10 16V8Z"
                stroke="#0049A2"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
}

Video.propTypes = {
  image: PropTypes.string,
  video: PropTypes.string,
  title: PropTypes.string,
  appkey: PropTypes.string,
};

export default Video;
