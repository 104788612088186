import { PropTypes } from "prop-types";

const Top20 = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="408.000000pt"
      height="408.000000pt"
      viewBox="0 0 408.000000 408.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,408.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M1870 4064 c-14 -2 -65 -9 -115 -15 -119 -15 -328 -68 -446 -115
-315 -124 -580 -310 -796 -559 -313 -361 -481 -784 -500 -1260 -10 -257 22
-481 103 -724 106 -319 274 -584 521 -821 301 -289 654 -468 1068 -541 187
-33 493 -33 680 0 411 73 767 252 1062 535 132 126 209 219 305 366 523 799
408 1875 -273 2550 -302 299 -683 494 -1109 565 -90 16 -444 29 -500 19z m311
-727 c111 -54 201 -188 234 -350 47 -233 22 -506 -62 -672 -36 -71 -120 -148
-190 -176 -46 -18 -78 -23 -158 -23 -89 -1 -107 2 -162 28 -125 57 -207 179
-245 363 -17 84 -19 123 -15 273 5 190 16 249 68 359 78 168 186 233 374 228
85 -3 109 -7 156 -30z m-661 -52 l0 -65 -160 0 -160 0 -2 -542 -3 -543 -67 -3
-68 -3 0 546 0 545 -160 0 -160 0 0 65 0 65 390 0 390 0 0 -65z m1666 25 c114
-58 164 -156 164 -321 0 -268 -133 -378 -457 -379 l-93 0 -2 -237 -3 -238 -67
-3 -68 -3 0 611 0 612 229 -4 228 -3 69 -35z m-52 -1524 l166 -180 -31 -32
-32 -33 -81 87 c-44 48 -107 114 -138 147 l-58 60 0 -530 c0 -400 -3 -534 -12
-543 -16 -16 -55 -15 -65 1 -4 6 -10 250 -13 540 l-5 529 -129 -141 c-71 -78
-132 -141 -135 -141 -3 0 -20 13 -38 28 l-32 27 192 207 192 207 27 -27 c14
-15 101 -108 192 -206z m-1789 120 c129 -59 195 -205 167 -371 -19 -114 -61
-185 -171 -289 -217 -206 -286 -289 -305 -368 l-6 -28 245 0 245 0 0 -65 0
-65 -325 0 -325 0 0 53 c1 71 15 130 52 207 27 54 61 94 208 240 214 212 235
246 235 380 0 140 -42 202 -148 216 -96 13 -167 -50 -201 -179 -8 -33 -16 -61
-18 -62 -5 -6 -117 11 -124 18 -9 9 13 93 42 157 43 95 122 160 217 180 61 13
155 2 212 -24z m831 4 c89 -38 158 -143 191 -291 26 -112 25 -493 -1 -604 -28
-120 -64 -193 -123 -246 -60 -53 -105 -69 -199 -69 -152 0 -249 92 -298 284
-58 223 -38 622 39 776 30 61 85 124 128 146 69 37 185 38 263 4z"
        />
        <path
          d="M1931 3237 c-132 -50 -201 -220 -201 -497 0 -331 95 -504 275 -503
97 0 157 37 211 129 57 98 88 343 65 517 -32 238 -114 353 -258 363 -33 2 -73
-2 -92 -9z"
        />
        <path
          d="M2800 2979 l0 -252 123 5 c88 3 132 10 159 22 86 41 124 114 122 237
-1 120 -53 204 -141 228 -21 6 -89 11 -150 11 l-113 0 0 -251z"
        />
        <path
          d="M2000 1814 c-34 -14 -81 -71 -100 -121 -31 -83 -43 -224 -37 -437 7
-262 38 -373 118 -420 34 -20 101 -21 139 -1 36 18 77 86 96 157 24 95 36 321
25 471 -14 169 -39 255 -90 310 -32 34 -44 40 -87 43 -27 2 -56 1 -64 -2z"
        />
      </g>
    </svg>
  );
};

Top20.propTypes = {
  className: PropTypes.string,
};

export default Top20;
