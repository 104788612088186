import React, { useState } from "react";

import AppFooter from "../components/AppFooter";
import { Table, Tabs, Col, Divider, Row, Space } from "antd";
import columsResumenMensual from "../util/tableConfiguration/tablaResumenMensual";
import TablaSort from "../components/table/tableSort";
import TableSearch from "../components/table/tableSearch";
import TableFilter from "../components/table/tableFilter";
import TableEmpty from "../components/table/tableEmpty";
import TablaDatoService from "../components/services/table-service";

import "../index.css";

function Quickwins() {
  const { TabPane } = Tabs;
  const [ordenamientosTabla, setOrdenamientosTabla] = useState([]);
  const [filtrosBusqueda, setfiltrosBusqueda] = useState([]);
  const [filtrosFila, setFiltrosFila] = useState([]);

  const onChange = (key) => {
    console.log(key);
  };

  const dataSource = [
    {
      key: "1",
      Oportunidad: "Autos Nuevos",
      mesActual: 32,
      mesActualPor: "10 Downing Street",
      MesAnte: 32,
      MesAntePor: "10 Downing Street",
      MesAnoAnte: 32,
      MesAnoAntePor: "10 Downing Street",
    },
    {
      key: "2",
      Oportunidad: "Autos Seminuevos",
      mesActual: 12,
      mesActualPor: "10 Downing Street",
      MesAnte: 13,
      MesAntePor: "10 Downing Street",
      MesAnoAnte: 14,
      MesAnoAntePor: "10 Downing Street",
      visible: true,
    },
    {
      key: "3",
      Oportunidad: "Servicios",
      mesActual: 34,
      mesActualPor: "10 Downing Street",
      MesAnte: 5,
      MesAntePor: "10 Downing Street",
      MesAnoAnte: 45,
      MesAnoAntePor: "10 Downing Street",
      visible: true,
    },
    {
      key: "4",
      Oportunidad: "Refacciones",
      mesActual: 35,
      mesActualPor: "10 Downing Street",
      MesAnte: 32,
      MesAntePor: "10 Downing Street",
      MesAnoAnte: 25,
      MesAnoAntePor: "10 Downing Street",
      visible: true,
    },
    {
      key: "5",
      Oportunidad: "Administración",
      mesActual: 5,
      mesActualPor: "10 Downing Street",
      MesAnte: 23,
      MesAntePor: "10 Downing Street",
      MesAnoAnte: 25,
      MesAnoAntePor: "10 Downing Street",
      visible: true,
    },
  ];

  const [listaRegistros, setListaRegistros] = useState(dataSource);

  const eventoTabla = (evento) => {
    if (evento) {
      let datos = [];
      switch (evento.accion) {
        case "ordenar": {
          const ordenamiento = TablaDatoService.generarOrdenamientoTabla(
            evento,
            ordenamientosTabla
          );
          datos = TablaDatoService.ordenarDatos(
            dataSource,
            evento,
            ordenamiento
          );

          setOrdenamientosTabla([...ordenamiento]);
          setListaRegistros([...datos]);

          break;
        }
        case "buscar": {
          const busquedas = TablaDatoService.generarFiltrosBusquedaTabla(
            evento,
            filtrosBusqueda
          );
          console.log(busquedas);

          datos = TablaDatoService.filtrarDatos(
            listaRegistros,
            busquedas,
            filtrosFila
          );

          setfiltrosBusqueda([...busquedas]);
          setListaRegistros([...datos]);

          break;
        }
        case "filtrar": {
          const filtros = TablaDatoService.generarFiltrosTabla(
            evento,
            filtrosFila
          );

          datos = TablaDatoService.filtrarDatos(
            listaRegistros,
            filtrosBusqueda,
            filtros
          );

          setFiltrosFila([...filtros]);
          setListaRegistros([...datos]);
          break;
        }
      }
    }
  };

  const [tabPosition] = useState("bottom");

  const columns = columsResumenMensual.map((config) => {
    return {
      title: (
        <Row>
          <Col span="24"> {config.etiquetaTraduccion} </Col>
          <Col span="24">
            {" "}
            <Divider className="col-divider" />{" "}
          </Col>
          <Col span="24">
            <Space size="small" align="center" direction="horizontal">
              {config.ordenar ? (
                <TablaSort
                  propiedadObjeto={config.propiedad}
                  emiteEvento={eventoTabla}
                />
              ) : (
                ""
              )}
              {config.buscar ? (
                <TableSearch
                  propiedadObjeto={config.propiedad}
                  emiteEvento={eventoTabla}
                />
              ) : (
                ""
              )}
              {config.filtrar ? (
                <TableFilter
                  propiedadObjeto={config.propiedad}
                  datos={listaRegistros}
                  emiteEvento={eventoTabla}
                />
              ) : (
                ""
              )}
            </Space>
          </Col>
        </Row>
      ),
      dataIndex: config.propiedad,
      key: config.propiedad,
      width: config.width,
    };
  });

  /**
   * Evento para ocultar las filas que no cumplan con las busquedas o filtros
   *
   * @param usuario Usuario - el objeto tipo Usuario
   *
   * @returns string
   */
  const ocultarFilas = (val) => {
    return val.visible ? "" : "hidden";
  };

  return (
    <>
      <main className="focus:outline-none relative flex-1 overflow-y-auto">
        <div className="py-4">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <div className="bg-white px-4 py-5 shadow sm:p-6">
              <Tabs onChange={onChange} type="card" tabPosition={tabPosition}>
                <TabPane tab="Resumen" key="1">
                  <Table
                    dataSource={listaRegistros}
                    columns={columns}
                    rowClassName={ocultarFilas}
                    pagination={false}
                    scroll={{
                      x: 1500,
                    }}
                  />
                  <TableEmpty datos={listaRegistros} />
                </TabPane>
                <TabPane tab="Autos Nuevos" key="2">
                  Content of Tab Pane 2
                </TabPane>
                <TabPane tab="Autos Seminuevos" key="3">
                  Content of Tab Pane 3
                </TabPane>
                <TabPane tab="Servicios" key="4">
                  Content of Tab Pane 3
                </TabPane>
                <TabPane tab="Refacciones" key="5">
                  Content of Tab Pane 3
                </TabPane>
                <TabPane tab="Administación" key="6">
                  Content of Tab Pane 3
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        <AppFooter />
      </main>
    </>
  );
}

export default Quickwins;
