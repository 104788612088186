import { createContext, useState } from "react";
import { PropTypes } from "prop-types";

const ModalContext = createContext(null);
const { Provider } = ModalContext;

const ModalProvider = ({ children }) => {
  const [modalMainState, setModalMainState] = useState(false);
  const [modalEmailState, setModalEmailState] = useState(false);
  const [modalPhoneState, setModalPhoneState] = useState(false);
  const [modalCalendarState, setModalCalendarState] = useState(false);
  const [modalWhatsappState, setModalWhatsappState] = useState(false);
  const [product, setProduct] = useState({});
  const [profile, setProfile] = useState({});

  const openModalMain = () => {
    setModalMainState(true);
  };

  const onCloseModalMain = () => {
    setModalMainState(false);
  };

  const openModalEmail = () => {
    setModalMainState(false);
    setModalEmailState(true);
  };

  const onCloseModalEmail = () => {
    setModalEmailState(false);
    setModalMainState(true);
  };

  const openModalPhone = () => {
    setModalMainState(false);
    setModalPhoneState(true);
  };

  const onCloseModalPhone = () => {
    setModalPhoneState(false);
    setModalMainState(true);
  };

  const openModalWhatsapp = () => {
    setModalMainState(false);
    setModalWhatsappState(true);
  };

  const onCloseModalWhatsapp = () => {
    setModalWhatsappState(false);
    setModalMainState(true);
  };

  const openModalCalendar = () => {
    setModalMainState(false);
    setModalCalendarState(true);
  };

  const onCloseModalCalendar = () => {
    setModalCalendarState(false);
    setModalMainState(true);
  };

  return (
    <Provider
      value={{
        modalMainState,
        modalEmailState,
        modalPhoneState,
        modalWhatsappState,
        modalCalendarState,
        profile,
        product,
        onCloseModalWhatsapp: () => onCloseModalWhatsapp(),
        openModalWhatsapp: () => openModalWhatsapp(),
        openModalCalendar: () => openModalCalendar(),
        onCloseModalCalendar: () => onCloseModalCalendar(),
        onCloseModalPhone: () => onCloseModalPhone(),
        openModalPhone: () => openModalPhone(),
        onCloseModalEmail: () => onCloseModalEmail(),
        openModalEmail: () => openModalEmail(),
        onCloseModalMain: () => onCloseModalMain(),
        openModalMain: () => openModalMain(),
        setProfile: (profile) => setProfile(profile),
        setProduct: (product) => setProduct(product),
      }}
    >
      {children}
    </Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.object,
};

export { ModalContext, ModalProvider };
