import { PropTypes } from "prop-types";

const DBDigger = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="538.000000pt"
      height="538.000000pt"
      viewBox="0 0 538.000000 538.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,538.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2505 5370 c-468 -32 -911 -183 -1297 -440 -177 -117 -279 -201 -418
-340 -421 -421 -678 -938 -767 -1545 -26 -181 -24 -553 5 -740 92 -594 346
-1100 761 -1516 614 -614 1473 -889 2340 -749 567 92 1078 361 1487 782 363
374 599 822 704 1333 67 326 67 742 0 1070 -172 844 -749 1566 -1530 1915
-395 177 -857 259 -1285 230z m1031 -1226 c629 -363 828 -483 835 -501 5 -14
9 -427 9 -988 0 -902 -1 -964 -17 -984 -14 -16 -1511 -888 -1640 -954 -17 -9
-40 -14 -50 -10 -35 12 -1628 936 -1651 956 l-22 20 0 982 c0 958 0 982 19
999 39 36 1646 956 1668 956 13 0 395 -214 849 -476z"
        />
        <path
          d="M1953 4047 c-403 -232 -731 -426 -730 -430 1 -4 301 -181 667 -392
437 -252 667 -380 671 -372 4 7 12 61 18 122 24 211 77 412 144 539 85 163
257 266 443 266 93 0 159 -22 252 -85 87 -57 155 -135 202 -232 38 -76 42 -68
-75 -140 l-80 -50 -55 38 c-98 69 -243 99 -310 64 -43 -22 -75 -71 -104 -155
-26 -78 -71 -298 -63 -306 6 -6 561 315 566 327 1 6 28 21 60 35 90 40 601
338 593 345 -6 7 -1462 849 -1466 849 0 0 -330 -191 -733 -423z"
        />
        <path
          d="M1142 2619 l3 -870 675 -390 c371 -215 703 -406 738 -426 l62 -35 0
407 0 407 -52 -46 c-67 -58 -148 -101 -221 -116 -156 -32 -352 61 -477 227
-56 75 -105 167 -97 185 2 7 65 54 139 105 l135 92 30 -43 c35 -49 118 -96
170 -96 45 0 98 23 129 57 36 38 79 127 98 199 16 61 66 361 66 393 0 13 -28
35 -92 71 -92 53 -92 53 -163 47 -60 -4 -104 -17 -186 -51 -12 -5 -23 9 -48
70 -18 42 -30 78 -28 80 2 2 36 5 76 5 40 1 69 5 64 10 -9 9 -1012 589 -1019
589 -2 0 -3 -392 -2 -871z m832 149 c47 -66 56 -85 47 -98 -23 -31 -51 -90
-51 -105 0 -8 -3 -15 -7 -15 -15 0 -227 125 -230 136 -8 24 139 164 171 164 7
0 38 -37 70 -82z m-137 -220 l112 -51 6 -46 c3 -25 10 -64 15 -85 l9 -39 -121
6 c-67 4 -141 7 -166 7 l-45 0 6 83 c8 111 32 191 55 182 9 -3 67 -29 129 -57z
m171 -300 c18 -42 20 -55 10 -64 -26 -25 -272 -172 -280 -168 -9 6 -63 174
-72 227 l-7 37 43 1 c41 1 236 14 269 17 9 1 23 -17 37 -50z"
        />
        <path
          d="M3970 3342 c-241 -140 -255 -149 -252 -175 3 -26 1 -27 -48 -27 -47
0 -71 -12 -408 -207 l-358 -207 -27 -196 c-15 -110 -47 -277 -72 -378 l-45
-181 2 -534 3 -535 723 418 c398 230 728 422 733 427 5 5 8 348 7 876 l-3 866
-255 -147z m-252 -312 c4 -52 -5 -139 -17 -158 -6 -10 -29 0 -93 38 -70 42
-85 55 -82 73 2 12 6 39 9 60 l6 38 87 -3 87 -3 3 -45z m-129 -177 c42 -26 78
-48 80 -50 16 -12 -114 -173 -139 -173 -5 0 -26 32 -47 72 l-40 71 31 63 c17
34 33 63 35 63 2 1 38 -20 80 -46z m-166 -205 c17 -32 28 -60 25 -64 -15 -14
-127 -34 -198 -34 -69 0 -192 21 -297 49 -31 9 -40 15 -37 29 5 15 22 17 167
17 161 0 162 0 227 32 36 17 69 30 73 29 5 -1 22 -27 40 -58z"
        />
      </g>
    </svg>
  );
};

DBDigger.propTypes = {
  className: PropTypes.string,
};

export default DBDigger;
