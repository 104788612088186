const columsResumenMensual = [
  {
    propiedad: "Oportunidad",
    etiquetaTraduccion: "Resumen Oportunidad",
    ordenar: true,
    buscar: true,
    filtrar: true,
    width: 100,
  },
  {
    propiedad: "mesActual",
    etiquetaTraduccion: "Oportunidad Mayo 2022",
    ordenar: true,
    buscar: true,
    filtrar: true,
    width: 100,
  },
  {
    propiedad: "mesActualPor",
    etiquetaTraduccion: "% Mayo 2022",
    ordenar: true,
    buscar: true,
    filtrar: true,
    width: 100,
  },
  {
    propiedad: "MesAnte",
    etiquetaTraduccion: "Oportunidad Abril 2022",
    ordenar: true,
    buscar: true,
    filtrar: true,
    width: 100,
  },
  {
    propiedad: "MesAntePor",
    etiquetaTraduccion: "% Abril 2022",
    ordenar: true,
    buscar: true,
    filtrar: true,
    width: 100,
  },
  {
    propiedad: "MesAnoAnte",
    etiquetaTraduccion: "Oportunidad Mayo 2021",
    ordenar: true,
    buscar: true,
    filtrar: true,
    width: 100,
  },
  {
    propiedad: "MesAnoAntePor",
    etiquetaTraduccion: "% Mayo 2021",
    ordenar: true,
    buscar: true,
    filtrar: true,
    width: 100,
  },
];

export default columsResumenMensual;
