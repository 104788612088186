import { ZendeskWidget } from "../hooks/useZendesk";
import Help from "./icons/Dashboard/Help";

const Zendesk = () => {
  const handleSupport = () => {
    ZendeskWidget("webWidget", "show");
    ZendeskWidget("webWidget", "toggle");
  };

  return (
    <div className="fixed bottom-0 right-0">
      <button
        onClick={handleSupport}
        className="flex bottom-0 my-8 mr-8 float-right px-5 py-2 bg-simblue-900 text-white text-sm tracking-wide rounded-full focus:outline-none"
      >
        <Help className="mr-2" />
        Ayuda
      </button>
    </div>
  );
};

export default Zendesk;
