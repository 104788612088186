// import Tag from "../components/icons/Dashboard/Tag";
import { useContext } from "react";
// import { FetchContext } from "../context/FetchContext";
import AppFooter from "../components/AppFooter";
import Check from "../components/icons/Dashboard/Check";
import SecondaryNavBar from "../components/SecondaryNavBar";
import { AppsIconEnum } from "../util/AppsEnum";
import { mySimsContext } from "../context/MySimsContext";

function MySims() {
  const { mySims } = useContext(mySimsContext);

  const mySimsApps = mySims.map((sim) => {
    const item = {
      title: sim.nombre,
      description: sim.descripcion,
      icons: AppsIconEnum[sim.clave],
      color: sim.color,
      sso: sim.sso,
      ssoUrl: sim.sso_url,
      url: sim.url,
      tags: [
        {
          title: "",
        },
        {
          title: "",
        },
      ],
    };
    return item;
  });

  return (
    <>
      <SecondaryNavBar />
      <main className="focus:outline-none relative flex-1 overflow-y-auto">
        <div className="py-4">
          <div className="mx-auto grid max-w-7xl grid-cols-1 gap-4 px-4 sm:px-6 md:px-8 xl:grid-cols-2 ">
            {mySimsApps.map((app) => (
              <div key={app.title} className="shadow">
                <div className="bg-white p-4">
                  <div
                    className={`my-4 flex items-center justify-between text-2xl font-semibold text-primary-${app.color}`}
                  >
                    {app.title}
                    <Check className="h-10 w-10 rounded-full bg-neutral-300 p-2" />
                  </div>

                  <div className="mb-4 flex space-x-4">
                    <div>
                      <app.icons
                        className={`h-16 w-16 fill-current p-4 text-white xl:h-32 xl:w-32 xl:p-8 bg-primary-${app.color}`}
                      />
                    </div>
                    <div>
                      <p>{app.description}</p>
                    </div>
                  </div>

                  {/* Implementacion en futuros sprint */}
                  {/* <ul className="flex flex-wrap lg:space-x-4">
                    {app.tags.map((tag) => (
                      <li
                        key={tag.title}
                        className="flex rounded border border-simblue-800 px-4 py-2 text-xs text-simblue-700"
                      >
                        <Tag className="mr-2" />
                        {tag.title}
                      </li>
                    ))}
                  </ul> */}
                </div>
                <div className="boder-neutral-400 block border-t bg-white py-3 px-6 text-right">
                  <a
                    href={app.sso ? app.ssoUrl : app.url}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="focus:outline-none  inline-flex items-center justify-center rounded-md border border-transparent bg-simblue-900 py-2 px-6 text-sm font-medium text-white shadow-sm hover:bg-simblue-800 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Entrar
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 18L15 12L9 6"
                        stroke="#F3F3F3"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
        <AppFooter />
      </main>
    </>
  );
}

export default MySims;
