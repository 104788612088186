/* eslint-disable react/display-name */
import { useEffect, useContext, useState } from "react";
import TableHeader from "../components/TableHeader";
import TableTitle from "../components/TableTitle";
import { FetchContext } from "../context/FetchContext";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale";
import { Link, useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { ExclamationIcon } from "@heroicons/react/solid";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../context/AuthContext";

function SimIndexList() {
  const { authAxios } = useContext(FetchContext);
  const { authState } = useContext(AuthContext);
  const [simindex, setSimindex] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const headers = ["ID", "Inicio", "Final"];

  const deleteIndex = async (id) => {
    try {
      toast.success("Registro eliminado correctamente");
      await authAxios.delete(`/simindex/${id}`);
      setLoading(false);
      const { data } = await authAxios.get("/simindex");
      setSimindex(data);
      setLoading(true);
    } catch (err) {
      const { data } = err.response;
      if (data.statusCode === 401 && data.message === "Unauthorized") {
        toast.error("La sesión ha expirado");
        console.log("La sesión ha expirado");
        setTimeout(() => {
          history.push("/");
        }, 1500);
      } else {
        toast.error(data.message);
        console.log(data.message);
      }
    }
  };

  const submitDelete = (id) => {
    confirmAlert({
      overlayClassName: "customOverlay",
      // eslint-disable-next-line react/prop-types
      customUI: ({ onClose }) => {
        return (
          <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationIcon
                  className="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  as="h3"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  Eliminar registro
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    ¿Está seguro de que quiere eliminar el registro? Todos los
                    sus datos serán eliminados permanentemente de nuestros
                    servidores para siempre. Esta acción no se puede deshacer.
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => {
                  deleteIndex(id);
                  onClose();
                }}
              >
                Eliminar
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={onClose}
              >
                Cancelar
              </button>
            </div>
          </div>
        );
      },
    });
  };

  useEffect(() => {
    const getSimindex = async () => {
      try {
        const { data } = await authAxios.get("/simindex");
        setSimindex(data);
        setLoading(true);
      } catch (err) {
        const { data } = err.response;
        if (data.statusCode === 401 && data.message === "Unauthorized") {
          toast.error("La sesión ha expirado");
          // console.log("La sesión ha expirado");
          setTimeout(() => {
            history.push("/");
          }, 1500);
        } else {
          toast.error(data.message);
          // console.log(data.message);
        }
      }
    };
    getSimindex();
  }, []);

  if (!authState.profile.hasSimindex) {
    history.push("/");
  }

  if (!loading) {
    return (
      <div className="container mx-auto py-16 px-4 flex justify-center items-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }

  return (
    <main className="focus:outline-none relative flex-1 overflow-y-auto">
      <div className="py-4">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <TableTitle
              title="SimIndex"
              subtitle="Lista de todas las publicaciones de SimIndex"
              href="/admin/simindex/new"
              button="Nueva Publicacion"
            />

            <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <TableHeader headers={headers} />

                <tbody className="divide-y divide-gray-200 bg-white">
                  {simindex.map((sim) => (
                    <tr key={sim.id}>
                      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                        {sim.id}
                        <dl className="font-normal lg:hidden">
                          <dt className="sr-only">Inicio</dt>
                          <dd className="mt-1 truncate text-gray-700">
                            {sim.init}
                          </dd>
                          <dt className="sr-only sm:hidden">Final</dt>
                          <dd className="mt-1 truncate text-gray-500 sm:hidden">
                            {sim.end}
                          </dd>
                        </dl>
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                        {format(parseISO(sim.init), "yyyy LLLL ", {
                          locale: es,
                        })}
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                        {format(parseISO(sim.end), " yyyy LLLL", {
                          locale: es,
                        })}
                      </td>
                      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <Link
                          to={`/admin/simindex/edit/${sim.id}`}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Editar<span className="sr-only">, {sim.id}</span>
                        </Link>
                      </td>
                      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <button
                          className="text-red-600 hover:text-red-900"
                          onClick={() => submitDelete(sim.id)}
                        >
                          Eliminar<span className="sr-only">, {sim.id}</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </main>
  );
}

export default SimIndexList;
