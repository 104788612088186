import { Link } from "react-router-dom";
import moment from "moment";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

const columnsResumenUsage = [
  {
    propiedad: "ClientName",
    etiquetaTraduccion: "Cliente",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "ClientName",
    width: 120,
    render: (_, Client) => tolinck(_, Client),
  },
  {
    propiedad: "TotalUsedDaysCurrentMonth",
    key: "TotalUsedDaysCurrentMonth",
    etiquetaTraduccion: "Uso Licencia Mes Actual",
    ordenar: true,
    buscar: true,
    filtrar: true,
    width: 200,
    render: (TotalUsedDaysCurrentMonth, TotalLicenses) =>
      `${TotalUsedDaysCurrentMonth} / ${TotalLicenses.TotalLicenses}`,
  },
  {
    propiedad: "TotalUsedDaysPreviousMonth",
    etiquetaTraduccion: "Uso Licencia Mes Anterior",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "TotalUsedDaysPreviousMonth",
    width: 200,
    render: (TotalUsedDaysPreviousMonth, TotalLicenses) =>
      `${TotalUsedDaysPreviousMonth} / ${TotalLicenses.TotalLicenses}`,
  },
  {
    propiedad: "TotalUsedDaysCurrentMonth",
    etiquetaTraduccion: "Dias Uso Mes Actual",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "TotalUsedDaysCurrentMonth",
    width: 200,
  },
  {
    propiedad: "TotalUsedDaysPreviousMonth",
    etiquetaTraduccion: "Dias Uso Mes Anterior",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "TotalUsedDaysPreviousMonth",
    width: 200,
  },
  {
    propiedad: "TotalAccessCurrentMonth",
    etiquetaTraduccion: "Total Accesos Usuario / dia  Mes Actual",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "TotalAccessCurrentMonth",
    width: 250,
  },
  {
    propiedad: "TotalAccessPreviousMonth",
    etiquetaTraduccion: "Total Accesos Usuario / dia Mes Anterior",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "TotalAccessPreviousMonth",
    width: 300,
  },
  {
    propiedad: "PercentUseUsersCurrentMonth",
    etiquetaTraduccion: "% de Uso usuario Mes Actual",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "PercentUseUsersCurrentMonth",
    width: 200,
  },
  {
    propiedad: "PercentUseUsersPreviousMonth",
    etiquetaTraduccion: "% de Uso usuario Mes Anterior",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "PercentUseUsersPreviousMonth",
    width: 200,
  },
  {
    propiedad: "PercentUseUsersCurrentMonthVSTotal",
    etiquetaTraduccion: "% de Uso usuario Mes Actual vs total lic",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "PercentUseUsersCurrentMonthVSTotal",
    width: 250,
  },
  {
    propiedad: "PercentUseUsersPreviousMonthVSTotal",
    etiquetaTraduccion: "% de Uso usuario Mes Anterior vs total lic",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "PercentUseUsersPreviousMonthVSTotal",
    width: 250,
  },
  {
    propiedad: "RS",
    etiquetaTraduccion: "Ranking",
    ordenar: true,
    buscar: true,
    filtrar: true,
    key: "RS",
    width: 120,
    render: (_, Client) => rankingNumber(_, Client),
  },
  {
    propiedad: "icon",
    etiquetaTraduccion: "",
    ordenar: false,
    buscar: false,
    filtrar: false,
    key: "icon",
    width: 100,
    render: (_, Client) => rankingIcon(_, Client),
  },
];

const tolinck = (_, Client) => {
  const fechaLocal = localStorage.getItem("fechaSelected");
  const fecha = moment(new Date(fechaLocal)).format("M");
  const mes = MesesLetra(fecha);
  const app = localStorage.getItem("aplicacionSelected");
  return (
    <Link
      key={Client.Client.toString()}
      to={`/usage/client/${app}/${mes}/${Client.Client}`}
    >
      {Client.ClientName}
    </Link>
  );
};

export const MesesLetra = (mes) => {
  const MesLetra = [
    "",
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  return MesLetra[mes];
};
const rankingNumber = (_, Client) => {
  return <p>{Client.rak}</p>;
};

const rankingIcon = (Client) => {
  if (Client.icon === "sub") {
    return (
      <p>
        <CaretUpOutlined style={{ fontSize: "30px", color: "#46C844" }} />
      </p>
    );
  } else {
    return (
      <p>
        <CaretDownOutlined style={{ fontSize: "30px", color: "#CE3131" }} />
      </p>
    );
  }
};

export default columnsResumenUsage;
