import React, { useState, useEffect, useContext } from "react";
import { Empty, Table, Col, Divider, Row, Space } from "antd";
import { useParams, useHistory } from "react-router-dom";
import RadialGrafica from "../util/graficas/Radial";
import LinealGrafica from "../util/graficas/Lineal";
import toast from "react-hot-toast";
import { AuthContext } from "../context/AuthContext";
import { FetchContext } from "../context/FetchContext";
import columnsResumenUser from "../util/tableConfiguration/tablaResumenUser";
import moment from "moment";
import TablaSort from "../components/table/tableSort";
import TableSearch from "../components/table/tableSearch";
import TableFilter from "../components/table/tableFilter";
import TablaDatoService from "../components/services/table-service";
import ChevronsLeftIcon from "../components/icons/Dashboard/ChevronsLeftIcon";
import { MesesLetra } from "../util/tableConfiguration/tablaResumenUsage";
import Files from "../components/icons/FileIcon";
import { Excel } from "antd-table-saveas-excel";

function UsageUser() {
  const { authAxios } = useContext(FetchContext);
  const { authState } = useContext(AuthContext);
  const { app, id, fecha, email, nombreCliente } = useParams();
  const [loading, setLoading] = useState(false);

  const [categorias, setCategorias] = useState([]);
  const [valor, setValor] = useState([]);

  const [ordenamientosTabla, setOrdenamientosTabla] = useState([]);
  const [filtrosBusqueda, setfiltrosBusqueda] = useState([]);
  const [filtrosFila, setFiltrosFila] = useState([]);
  const history = useHistory();
  const [listaRegistros, setListaRegistros] = useState([]);
  const [filterUser, setFilterUser] = useState([]);

  useEffect(() => {
    const getDataUsuarioFinal = async () => {
      try {
        const dataUsuario = JSON.parse(
          localStorage.getItem("usageResumenUser")
        );
        if (dataUsuario) {
          const filterUsuario = dataUsuario.filter(
            (Usuario) => Usuario.Email === email
          );
          setFilterUser(filterUsuario);
          const cliente = filterUsuario[0].Client;
          const url = `/simusage/useraccessdates/${app}/${fecha}/${cliente}/${email}`;
          const { data } = await authAxios.get(url);
          if (data.length > 0) {
            data.forEach((valor) => {
              setValor((v) => [...v, Number(valor.AccesosDia)]);
              setCategorias((c) => [...c, valor.Fecha]);
            });
          }

          const urlTabla = `/simusage/userlogs/${app}/${fecha}/${id}/${nombreCliente}/${email}`;
          const dataCLienteUsuario = await authAxios.get(urlTabla);

          dataCLienteUsuario.data.map((dataUsuario) => {
            dataUsuario.visible = true;
            return dataUsuario;
          });
          setListaRegistros(dataCLienteUsuario.data);
          setLoading(true);
        }
      } catch (err) {
        const { data } = err.response;
        if (data.statusCode === 401 && data.message === "Unauthorized") {
          toast.error("La sesión ha expirado");
          setTimeout(() => {
            history.push("/");
          }, 1500);
        } else {
          toast.error(data.message);
        }
      }
    };
    getDataUsuarioFinal();
  }, []);

  if (!authState.profile.hasUsage) {
    history.push("/");
  }
  const eventoTabla = (evento) => {
    if (evento) {
      let datos = [];
      switch (evento.accion) {
        case "ordenar": {
          const ordenamiento = TablaDatoService.generarOrdenamientoTabla(
            evento,
            ordenamientosTabla
          );
          datos = TablaDatoService.ordenarDatos(
            listaRegistros,
            evento,
            ordenamiento
          );

          setOrdenamientosTabla([...ordenamiento]);
          setListaRegistros([...datos]);

          break;
        }
        case "buscar": {
          const busquedas = TablaDatoService.generarFiltrosBusquedaTabla(
            evento,
            filtrosBusqueda
          );

          datos = TablaDatoService.filtrarDatos(
            listaRegistros,
            busquedas,
            filtrosFila
          );

          setfiltrosBusqueda([...busquedas]);
          setListaRegistros([...datos]);

          break;
        }
        case "filtrar": {
          const filtros = TablaDatoService.generarFiltrosTabla(
            evento,
            filtrosFila
          );

          datos = TablaDatoService.filtrarDatos(
            listaRegistros,
            filtrosBusqueda,
            filtros
          );

          setFiltrosFila([...filtros]);
          setListaRegistros([...datos]);
          break;
        }
      }
    }
  };

  const columns = columnsResumenUser.map((config, i) => {
    return {
      title: (
        <>
          <Row>
            <Col
              span="24"
              key={config.propiedad}
              align="center"
              className="tablaHeigthUsage"
            >
              {" "}
              {config.etiquetaTraduccion}{" "}
            </Col>
            <Col span="24">
              <Divider>{""}</Divider>
            </Col>
          </Row>

          <Row align="center">
            <Col span="24" justify="space-between" align="center">
              <Space
                size="small"
                justify="space-between"
                align="end"
                direction="horizontal"
              >
                {config.ordenar ? (
                  <TablaSort
                    propiedadObjeto={config.propiedad}
                    emiteEvento={eventoTabla}
                  />
                ) : (
                  ""
                )}
                {config.buscar ? (
                  <TableSearch
                    propiedadObjeto={config.propiedad}
                    emiteEvento={eventoTabla}
                  />
                ) : (
                  ""
                )}
                {config.filtrar ? (
                  <TableFilter
                    propiedadObjeto={config.propiedad}
                    datos={listaRegistros}
                    emiteEvento={eventoTabla}
                  />
                ) : (
                  ""
                )}
              </Space>
            </Col>
          </Row>
        </>
      ),
      dataIndex: config.propiedad,
      render: config.render,
      key: config.propiedad,
      width: config.width,
    };
  });

  const regresar = () => {
    const app = localStorage.getItem("aplicacionSelected");
    const id = localStorage.getItem("idClient");
    const Mes = moment(new Date(localStorage.getItem("fechaSelected"))).format(
      "M"
    );
    const fecha = MesesLetra(Mes);
    history.push(`/usage/client/${app}/${fecha}/${id}`);
  };
  const empt = {
    emptyText: (
      <span>
        <Empty></Empty>
      </span>
    ),
  };

  const ocultarFilas = (val) => {
    return val.visible ? "" : "hidden";
  };
  const columnsExport = columnsResumenUser.map((config) => {
    return {
      title: config.etiquetaTraduccion,
      dataIndex: config.propiedad,
      key: config.propiedad,
    };
  });
  const exportExcel = () => {
    setLoading(false);
    const listadoFIlter = listaRegistros.filter((f) => f.visible === true);
    const excel = new Excel();
    excel
      .setTHeadStyle({ background: "FFFFFF", fontSize: 10 })
      .setTBodyStyle({
        fontName: 10,
      })
      .addSheet("Hoja1")
      .addColumns(columnsExport)
      .addDataSource(listadoFIlter, {
        str2Percent: true,
      })
      .saveAs("tablauser.xlsx");
    setLoading(true);
  };

  if (!loading) {
    return (
      <div className="container mx-auto py-16 px-4 flex justify-center items-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }

  return (
    <main className="focus:outline-none relative flex-1 overflow-y-auto">
      <div className="py-4">
        <div className="sticky top-0 mb-2">
          <button
            className="hover:bg-simblue-900  py-2 px-2 rounded-full"
            onClick={regresar}
          >
            <ChevronsLeftIcon className="" />
          </button>
        </div>
        {filterUser.map((usuario, i) => (
          <div
            className="flex flex-auto grid pl-10 gap-2 sm:grid-cols-1 md:grid-cols-4 gap-2"
            key={`${usuario.Email}${i}`}
          >
            <div className="auto-cols-min">
              <div className={`text-center`}>
                <div className="bg-simblue-900  text-white mb-2 pb-1 pt-1  mr-2 text-center rounded">
                  Usuario
                </div>
                <div className="mb-2">{usuario.Usuario}</div>
              </div>
              <div className={`mb-2 text-center`}>
                <div className="bg-simblue-900  text-white mb-2 pb-1 pt-1 mr-2 pl-3 rounded">
                  Puesto
                </div>
                <div className="">{usuario.Puesto}</div>
              </div>
              <div className={`mb-2 text-center`}>
                <div className="bg-simblue-900  text-white mb-2 pb-1 pt-1 mr-2 pl-3 rounded">
                  <p>Teléfono</p>
                </div>
                <div className="text-center">{usuario.Telefono}</div>
              </div>
              <div className={`mb-2 text-center`}>
                <div className="bg-simblue-900  text-white mb-2 mr-2 pb-1 pt-1 text-center rounded">
                  <p>Email</p>
                </div>
                <div className="text-center">{usuario.Email}</div>
              </div>
              <div className="text-center mb-2">
                <div className="bg-simblue-900  text-white mb-2 mr-2 pb-1 pt-1 text-center  rounded">
                  <p>Cliete</p>
                </div>
                <div className="flex flex-wrap">
                  <div className="item w-14 flex-auto">{id}</div>
                  <div className="item w-32 flex-auto">{nombreCliente}</div>
                </div>
              </div>
            </div>
            <div className="text-center mb-2">
              <div className="text-center bg-simblue-900 mr-2 text-white pb-1 pt-1 rounded">
                % Mes actual
              </div>
              <div className="max-w-sm rounded overflow-hidden ml-5 mt-2">
                <RadialGrafica
                  key={"act"}
                  valor={usuario.PctMesActual}
                  color={
                    Number(usuario.PctMesActual) >
                    Number(usuario.PctMesAnterior)
                      ? "#46C844"
                      : "#CE3131"
                  }
                />
              </div>
            </div>
            <div className="items-stretch">
              <div className="text-center bg-simblue-900 mr-2 text-white pb-1 pt-1 rounded">
                % Mes anterior
              </div>
              <div className="max-w-sm rounded overflow-hidden ml-5 mt-2">
                <RadialGrafica
                  key={"ant"}
                  valor={usuario.PctMesAnterior}
                  color={
                    Number(usuario.PctMesActual) <
                    Number(usuario.PctMesAnterior)
                      ? "#46C844"
                      : "#CE3131"
                  }
                />
              </div>
            </div>
            <div className="pl-1 mr-3 col-span-1 ">
              <div className="">
                <div className="flex flex-wrap">
                  <div className="flex-auto pb-2">
                    <div className="text-center text-white">
                      <div className="bg-simblue-900  mb-3 pb-1 pt-1 rounded">
                        <p>Total de Accesos</p>
                      </div>
                      <div className="h-5">
                        <p className="text-black text-lg ">
                          {usuario.TotalAccesos}
                        </p>
                      </div>
                    </div>
                    <div className="text-center mt-2">
                      <div className="bg-simblue-900 mb-3 text-white pb-1 pt-1 rounded">
                        Promedio
                      </div>
                      <div className="h-5">
                        <p className="text-lg"> {usuario.PromUsuarioDia} </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="text-center text-white">
                    <div className="bg-simblue-900  pb-1 pt-1 mb-3 rounded">
                      Creación de Lic.
                    </div>
                    <div className="h-6">
                      <p className="text-black text-lg ">
                        {usuario.FechaCreacion}
                      </p>
                    </div>
                  </div>
                  <div className="text-center mt-2">
                    <div className="bg-simblue-900  text-white pb-1 pt-1 rounded">
                      Ultimo Acceso
                    </div>
                    <div className="h-6">
                      <p className="text-lg">{usuario.UltimoLogin}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="flex flex-col items-center justify-center mt-10">
          <p className="text-xl mb-3">Accesos Totales</p>
          <LinealGrafica categorias={categorias} valor={valor} />
        </div>
        <div className="flex flex-row-reverse pr-10 ">
          <button
            type="button"
            onClick={exportExcel}
            className="flex bottom-0 mt-2 ml-20  border border-white hover:border-indigo-500 focus:ring-4 focus:outline-none focus:ring-indigo-500font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-indigo-500 dark:text-indigo-500 dark:hover:text-white dark:hover:bg-indigo-500 dark:focus:ring-indigo-500"
          >
            <Files className="mr-2" />
            <span className="mt-1">Exportar</span>
          </button>
        </div>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 ">
          <div className=" px-4 py-5 shadow sm:p-6 mb-10">
            <div className="ag-theme-alpine" style={{ width: "100%" }}>
              <Table
                locale={empt}
                rowKey="Fecha"
                dataSource={listaRegistros}
                columns={columns}
                rowClassName={ocultarFilas}
                scroll={{ x: "max-content", y: "500px" }}
                height
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default UsageUser;
