import UserPlusIcon from "../components/icons/UserPlusIcon";
// import InboxIcon from "../components/icons/InboxIcon";
// import KeyIcon from "../components/icons/KeyIcon";
import MessageSquareIcon from "../components/icons/MessageSquareIcon";
import RssIcon from "../components/icons/RssIcon";

// import HomeIcon from "../components/icons/HomeIcon";

const secondaryNavigation = [
  {
    name: "Invita a un amigo",
    href: "/invite-friend",
    icon: UserPlusIcon,
    current: false,
  },
  // { name: "Hoy", href: "#", icon: InboxIcon, current: false },
  // { name: "Licencias", href: "#", icon: KeyIcon, current: false },
  {
    name: "Te escuchamos",
    href: "/we-listen",
    icon: MessageSquareIcon,
    current: false,
  },
  { name: "SimIndex", href: "/simindex", icon: RssIcon, current: false },
];

export default secondaryNavigation;
