import { Disclosure } from "@headlessui/react";
// import { SearchIcon } from "@heroicons/react/solid";
// import Sectores from "./Sectores";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import internalNavigation from "../util/InternalNavigation";
import { NavLink } from "react-router-dom";
import sectoresItems from "../util/SectoresItems";

function SecondaryNavBar() {
  return (
    <Disclosure as="nav" className="bg-neutral-300 shadow">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex px-2 lg:px-0">
                <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                  {internalNavigation.map((nav) => (
                    <NavLink
                      key={nav.name}
                      to={nav.href}
                      className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                      activeClassName="border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                    >
                      {nav.name}
                    </NavLink>
                  ))}
                </div>
              </div>
              {/* Implementar en futuros sprints */}
              {/* <div className="flex flex-1 items-center justify-center space-x-4 px-2 lg:ml-6 lg:justify-end">
                <div className="hidden lg:flex">
                  <Sectores />
                </div>
                <div className="hidden lg:flex">
                  <a
                    href="#"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  >
                    Staff
                  </a>
                </div>
                <div className="w-full max-w-lg lg:max-w-xs">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <SearchIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      id="search"
                      name="search"
                      className="focus:outline-none block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 leading-5 placeholder-gray-500 focus:border-indigo-500 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                      placeholder="Search"
                      type="search"
                    />
                  </div>
                </div>
              </div> */}
              <div className="flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="focus:outline-none inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="divide-y divide-gray-300 lg:hidden">
            <div className="space-y-1 pt-2 pb-3">
              {internalNavigation.map((nav) => (
                <NavLink
                  key={nav.name}
                  to={nav.href}
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
                  activeClassName="bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                >
                  {nav.name}
                </NavLink>
              ))}
            </div>

            <div className="space-y-1 px-4 pt-2 pb-3">
              <label htmlFor="tabs" className="sr-only">
                Sectores
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
              >
                {sectoresItems.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default SecondaryNavBar;
