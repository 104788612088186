import { PropTypes } from "prop-types";

const Youtube = ({ className }) => {
  return (
    <svg
      width="32"
      height="24"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M31.1068 4.125C30.8109 2.47559 29.4015 1.27441 27.7658 0.899414C25.3181 0.375 20.7881 0 15.8869 0C10.9886 0 6.38608 0.375 3.93547 0.899414C2.3027 1.27441 0.890339 2.39941 0.594526 4.125C0.295813 6 0 8.625 0 12C0 15.375 0.295813 18 0.66703 19.875C0.965742 21.5244 2.3752 22.7256 4.00798 23.1006C6.60649 23.625 11.0611 24 15.9623 24C20.8635 24 25.3181 23.625 27.9166 23.1006C29.5494 22.7256 30.9589 21.6006 31.2576 19.875C31.5534 18 31.9246 15.2988 32 12C31.8492 8.625 31.478 6 31.1068 4.125ZM11.8789 17.25V6.75L20.936 12L11.8789 17.25Z"
        fill="#0B55AE"
      />
    </svg>
  );
};

Youtube.propTypes = {
  className: PropTypes.string,
};

export default Youtube;
