// import { EyeOffIcon } from "@heroicons/react/outline";
import { useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Return from "../assets/svg/corner-up-left.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FetchContext } from "../context/FetchContext";

function ChangePassword() {
  const { authAxios } = useContext(FetchContext);
  const { token } = useParams();

  const [error, setError] = useState(false);
  const [responseError, setResponseError] = useState("");

  const [success, setSuccess] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState("");

  const changeSchema = Yup.object().shape({
    password: Yup.string().required("El campo es obligatorio"),
    passwordConfirmation: Yup.string()
      .required("El campo es obligatorio")
      .oneOf([Yup.ref("password"), null], "Los passwords deben coincidir"),
  });

  const handleSubmitChangePassword = async (
    { password, token },
    { resetForm }
  ) => {
    try {
      setError(false);
      setSuccess(false);
      setResponseSuccess("");
      setResponseError("");
      const { data } = await authAxios.patch("/recover/password/", {
        password,
        token,
      });
      setSuccess(true);
      setResponseSuccess(data.message);
      resetForm({});
    } catch (err) {
      const { data } = err.response;
      setError(true);
      data.statusCode === 500
        ? setResponseError("Token CSRF caducado, refresque la pagina")
        : setResponseError(data.message);
    }
  };

  return (
    <Formik
      initialValues={{ password: "", passwordConfirmation: "", token: token }}
      validationSchema={changeSchema}
      onSubmit={handleSubmitChangePassword}
    >
      {({ isSubmitting }) => (
        <div className="mx-auto px-4 md:px-0 w-full max-w-sm lg:w-login sm:mx-6 lg:mx-20 xl:mx-24">
          <div>
            <h2 className="mt-6 text-2xl text-neutral-800 text-center">
              Recuperación de contraseña
            </h2>
            <p className="text-center text-sm text-neutral-600 mt-4">
              Introduce tu nueva contraseña, te recomendamos incluir 1 letra
              mayúscula, 1 número y 1 simbolo para mayor seguridad.
            </p>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <Form action="#" method="POST" className="space-y-6">
                <Field type="hidden" name="token" id="token" />

                <div className="space-y-1">
                  <label
                    htmlFor="password"
                    className="block text-sm text-neutral-600"
                  >
                    Nueva contraseña
                  </label>
                  <div className="mt-1 relative">
                    <Field
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      placeholder="******"
                      required
                      className="appearance-none block w-full px-3 py-4 border border-simblue-300 rounded-md placeholder-neutral-500 focus:outline-none focus:ring-simblue-600 focus:border-simblue-600 sm:text-sm"
                    />
                    {/* <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                      <EyeOffIcon
                        className="h-5 w-5 text-neutral-600"
                        aria-hidden="true"
                      />
                    </div> */}
                  </div>
                  <ErrorMessage
                    name="password"
                    render={(msg) => (
                      <div className="text-sm p-2 text-red-600 font-medium">
                        {msg}
                      </div>
                    )}
                  />
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="passwordConfirmation"
                    className="block text-sm text-neutral-600"
                  >
                    Confirmar contraseña
                  </label>
                  <div className="mt-1 relative">
                    <Field
                      id="passwordConfirmation"
                      name="passwordConfirmation"
                      type="password"
                      autoComplete="current-password"
                      placeholder="******"
                      required
                      className="appearance-none block w-full px-3 py-4 border border-simblue-300 rounded-md placeholder-neutral-500 focus:outline-none focus:ring-simblue-600 focus:border-simblue-600 sm:text-sm"
                    />
                    {/* <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                      <EyeOffIcon
                        className="h-5 w-5 text-neutral-600"
                        aria-hidden="true"
                      />
                    </div> */}
                  </div>
                  <ErrorMessage
                    name="passwordConfirmation"
                    render={(msg) => (
                      <div className="text-sm p-2 text-red-600 font-medium">
                        {msg}
                      </div>
                    )}
                  />
                </div>

                {error && (
                  <div className="text-sm p-2 text-red-600 font-medium">
                    {responseError}
                  </div>
                )}

                {success && (
                  <div className="text-sm p-2 text-green-600 font-medium">
                    {responseSuccess}
                  </div>
                )}

                <div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full flex justify-center py-4 px-4 border border-transparent rounded-md shadow-sm text-sm text-neutral-400 bg-simblue-900 hover:bg-simblue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-simblue-500"
                  >
                    Guardar
                  </button>
                </div>
                <div className="pt-3 text-sm text-gray-600 flex justify-center">
                  <Link to="/">
                    <img
                      src={Return}
                      className="h-6 w-auto"
                      alt="return home"
                    />
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default ChangePassword;
