import { useContext, useEffect } from "react";
import { PropTypes } from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import TermsShell from "../pages/TermsShell";
import { sectionTitleContext } from "../context/SectionTitleContext";

export const AuthTermsShell = ({ children, sectionTitle, ...rest }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { setSectionTitle } = useContext(sectionTitleContext);
  useEffect(() => {
    setSectionTitle(sectionTitle);
  }, []);

  return (
    <Route
      {...rest}
      render={() =>
        isAuthenticated() ? (
          <TermsShell>{children}</TermsShell>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

AuthTermsShell.propTypes = {
  children: PropTypes.object,
  sectionTitle: PropTypes.string,
};
