import { PropTypes } from "prop-types";

const HistorialServicio = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="489.000000pt"
      height="488.000000pt"
      viewBox="0 0 489.000000 488.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,488.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2270 4874 c-448 -40 -817 -167 -1173 -404 -570 -379 -936 -946
   -1058 -1637 -30 -169 -37 -481 -15 -667 64 -553 307 -1058 696 -1446 705 -706
   1754 -911 2679 -524 713 299 1258 951 1425 1706 45 204 51 269 51 548 -1 296
   -14 404 -80 643 -178 648 -624 1198 -1225 1511 -252 131 -489 207 -785 251
   -98 15 -426 27 -515 19z m-407 -588 c332 -98 567 -351 642 -690 24 -106 17
   -319 -14 -421 -47 -158 -130 -293 -247 -406 -177 -168 -379 -251 -619 -251
   -411 -2 -761 264 -876 664 -27 94 -36 271 -20 374 60 370 341 664 711 745 122
   27 302 20 423 -15z m1724 -857 c43 -16 73 -37 118 -83 103 -105 95 4 95 -1273
   l0 -1112 -22 -58 c-29 -78 -103 -152 -182 -182 l-58 -21 -826 2 -827 3 -47 23
   c-62 31 -121 89 -155 154 l-28 53 -3 706 -3 705 68 -7 c38 -4 94 -5 126 -2
   l57 6 0 -681 0 -681 26 -20 c26 -21 33 -21 801 -21 l775 0 29 29 29 29 0 1075
   c0 1070 0 1074 -21 1101 l-20 26 -427 2 -427 3 -7 77 c-4 43 -12 95 -17 115
   -6 21 -11 41 -11 46 0 4 202 7 450 7 433 0 451 -1 507 -21z m-380 -964 c60
   -10 84 -86 41 -132 l-21 -23 -477 0 -477 0 -46 31 c-26 17 -56 44 -67 59 -21
   30 -26 66 -10 72 16 5 1022 -2 1057 -7z m38 -520 c35 -34 34 -86 -1 -114 -26
   -21 -36 -21 -499 -21 -436 0 -474 1 -494 18 -35 28 -37 73 -6 110 l27 32 474
   0 475 0 24 -25z m-1 -516 c35 -28 36 -80 1 -114 l-24 -25 -473 0 c-356 1 -477
   4 -489 13 -27 20 -41 56 -34 88 13 60 4 59 521 59 462 0 472 0 498 -21z"
        />
        <path
          d="M1480 4115 c-141 -28 -259 -93 -365 -200 -127 -127 -192 -277 -202
   -460 -7 -133 13 -232 71 -350 88 -177 223 -297 408 -362 96 -35 286 -44 391
   -19 147 34 296 127 387 239 222 274 212 676 -21 931 -171 186 -425 270 -669
   221z m86 -230 c57 -23 64 -55 64 -275 l0 -190 236 0 c142 0 243 -4 255 -10 28
   -16 51 -67 44 -99 -3 -16 -17 -39 -31 -52 -26 -24 -26 -24 -319 -27 -255 -2
   -299 -1 -325 13 -55 28 -60 58 -59 317 0 266 5 294 59 320 42 21 33 21 76 3z"
        />
      </g>
    </svg>
  );
};

HistorialServicio.propTypes = {
  className: PropTypes.string,
};

export default HistorialServicio;
