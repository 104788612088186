import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useRef, useContext } from "react";
import PreviewCover from "./PreviewCover";
import { PhotographIcon } from "@heroicons/react/solid";
import DatePickerField from "./DatePickerField";
import { useHistory } from "react-router-dom";
import { FetchContext } from "../context/FetchContext";
import toast from "react-hot-toast";
import { AuthContext } from "../context/AuthContext";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const SimIndexNewForm = () => {
  const history = useHistory();
  const imageRef = useRef(null);
  const { authAxios } = useContext(FetchContext);
  const { authState } = useContext(AuthContext);

  const initialValues = {
    // title: "",
    // tags: "",
    url: "",
    // description: "",
    image: null,
    init: "",
    end: "",
  };

  const validationSchema = Yup.object().shape({
    // title: Yup.string().required("El campo es obligatorio"),
    // tags: Yup.string().required("El campo es obligatorio"),
    url: Yup.string().required("El campos es obligatorio"),
    // description: Yup.string().required("El campo es obligatorio"),
    init: Yup.string().required("El campo es obligatorio").nullable(),
    end: Yup.string().required("El campo es obligatorio").nullable(),
    image: Yup.mixed()
      .nullable()
      .required("La imagen de portada es obligatoria")
      .test(
        "FILE_FORMAT",
        "Formato no soportado (jpeg, jpg, png)",
        (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
      )
      .test(
        "FILE_SIZE",
        "Imagen demasiado grande.",
        (value) => !value || (value && value?.size <= 3 * 1024 * 1024)
      ),
  });

  const handleSubmit = async (dataForm, { resetForm }) => {
    try {
      const { url, init, end, image } = dataForm;
      const formData = new FormData();
      // formData.append("title", title);
      // formData.append("tags", tags);
      formData.append("url", url);
      // formData.append("description", description);
      formData.append("init", init);
      formData.append("end", end);
      formData.append("image", image);

      await authAxios({
        method: "post",
        url: "/simindex",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      resetForm();
      toast.success("El registro ha sido creado");
      setTimeout(() => {
        history.push("/admin/simindex/list");
      }, 1500);
    } catch (err) {
      const { data } = err.response;
      if (data.statusCode === 401 && data.message === "Unauthorized") {
        toast.error("La sesión ha expirado");

        setTimeout(() => {
          history.push("/");
        }, 1500);
      } else {
        toast.error(data.message);
        // console.log(data.message);
      }
    }
  };

  if (!authState.profile.hasSimindex) {
    history.push("/");
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="space-y-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 mt-5 sm:col-span-6 md:mt-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Nuevo SimIndex
                </h3>
              </div>

              {/* title */}
              {/* <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700"
                >
                  Titulo
                </label>
                <Field
                  type="text"
                  name="title"
                  placeholder="Titulo"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <ErrorMessage
                  name="title"
                  render={(msg) => (
                    <div className="text-sm p-2 text-red-600 font-medium">
                      {msg}
                    </div>
                  )}
                />
              </div> */}

              {/* Tags */}
              {/* <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                <label
                  htmlFor="tags"
                  className="block text-sm font-medium text-gray-700"
                >
                  Tags
                </label>
                <Field
                  type="text"
                  name="tags"
                  placeholder="Tag 1, Tag 2, Tag 3"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <ErrorMessage
                  name="tags"
                  render={(msg) => (
                    <div className="text-sm p-2 text-red-600 font-medium">
                      {msg}
                    </div>
                  )}
                />
              </div> */}

              {/* URL */}
              <div className="col-span-6 mt-5 md:mt-0">
                <label
                  htmlFor="url"
                  className="block text-sm font-medium text-gray-700"
                >
                  URL
                </label>
                <Field
                  type="text"
                  name="url"
                  placeholder="https://example.com/simindex"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <ErrorMessage
                  name="url"
                  render={(msg) => (
                    <div className="text-sm p-2 text-red-600 font-medium">
                      {msg}
                    </div>
                  )}
                />
              </div>

              <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                <label
                  htmlFor="init"
                  className="block text-sm font-medium text-gray-700"
                >
                  Fecha de inicio
                </label>

                <DatePickerField
                  name="init"
                  dateFormat="yyyy/MM/dd"
                  showMonthYearPicker
                />
                <ErrorMessage
                  name="init"
                  render={(msg) => (
                    <div className="text-sm p-2 text-red-600 font-medium">
                      {msg}
                    </div>
                  )}
                />
              </div>

              <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                <label
                  htmlFor="end"
                  className="block text-sm font-medium text-gray-700"
                >
                  Fecha final
                </label>

                <DatePickerField
                  name="end"
                  dateFormat="yyyy/MM/dd"
                  showMonthYearPicker
                />
                <ErrorMessage
                  name="end"
                  render={(msg) => (
                    <div className="text-sm p-2 text-red-600 font-medium">
                      {msg}
                    </div>
                  )}
                />
              </div>

              {/* description */}
              {/* <div className="col-span-6 mt-5 md:mt-0">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Descripcion
                </label>
                <Field
                  as="textarea"
                  name="description"
                  rows={4}
                  placeholder="Descripcion"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <ErrorMessage
                  name="description"
                  render={(msg) => (
                    <div className="text-sm p-2 text-red-600 font-medium">
                      {msg}
                    </div>
                  )}
                />
              </div> */}

              {/* Image Cover */}
              <div className="col-span-6 mt-5 md:mt-0">
                <label
                  htmlFor="image"
                  className="block text-sm font-medium text-gray-700"
                >
                  Imagen de Portada
                </label>
                <input
                  ref={imageRef}
                  hidden
                  type="file"
                  accept="image/png, image/jpg, image/jpeg"
                  name="image"
                  onChange={(event) => {
                    if (
                      !event.target.files[0]?.type.match(
                        /image\/(png|jpg|jpeg)/i
                      )
                    ) {
                      setFieldValue("image", null);
                    } else {
                      setFieldValue("image", event.target.files[0]);
                    }
                  }}
                />

                <div className="bg-gray-100 w-full h-52 my-3 flex justify-center items-center border-dashed border-2 border-gray-400">
                  {values.image ? (
                    <PreviewCover cover={values.image} />
                  ) : (
                    <PhotographIcon
                      className="mr-2 h-24 w-24 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </div>

                <button
                  type="button"
                  className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    imageRef.current.click();
                  }}
                >
                  <PhotographIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  Sube una imagen
                </button>

                <ErrorMessage
                  name="image"
                  render={(msg) => (
                    <div className="text-sm p-2 text-red-600 font-medium">
                      {msg}
                    </div>
                  )}
                />
              </div>

              <div className="col-span-6 mt-5 sm:col-span-6 md:mt-0">
                <button
                  type="submit"
                  className="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-simblue-900 py-4 px-8 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Crear Registro
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SimIndexNewForm;
