import { PropTypes } from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext } from "react";
import { XIcon, ChartBarIcon } from "@heroicons/react/outline";
import classNames from "../util/ClassNames";
import SimhubLogoIcon from "./icons/SimhubLogoIcon";
import principalNavigation from "../util/PrincipalNavigation";
import secondaryNavigation from "../util/SecondaryNavigation";
import { NavLink, Link } from "react-router-dom";
import RssIcon from "../components/icons/RssIcon";
import { AuthContext } from "../context/AuthContext";

SidebarMobile.propTypes = {
  setSidebarOpen: PropTypes.func,
  sidebarOpen: PropTypes.bool,
};

function SidebarMobile({ sidebarOpen, setSidebarOpen }) {
  const { authState } = useContext(AuthContext);

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 flex z-40 md:hidden"
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 flex items-center px-4">
              <SimhubLogoIcon />
            </div>
            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              <nav className="px-2 space-y-1">
                {principalNavigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    activeClassName="border-l-2 border-black"
                    className={classNames(
                      "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "group flex items-center px-2 py-2 text-base font-medium"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-4 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </NavLink>
                ))}
              </nav>

              <nav className="flex-1 px-2 bg-white space-y-1 pt-8 border-t-2">
                <div className="m-2 bg-neutral-300 rounded-md">
                  {secondaryNavigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current
                          ? "border-l-2 border-black"
                          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                        "group flex items-center px-2 py-2 my-4 text-sm font-medium"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? "text-gray-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  ))}
                </div>
              </nav>

              <nav className="flex-1 px-2 bg-white space-y-1 pt-8 border-t-2">
                {authState.profile.hasSimindex && (
                  <div className="m-2 bg-neutral-300 rounded-md">
                    <Link
                      to="/admin/simindex/list"
                      className={classNames(
                        "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                        "group flex items-center px-2 py-2 my-4 text-sm font-medium"
                      )}
                    >
                      <RssIcon
                        className={classNames(
                          "text-gray-400 group-hover:text-gray-500",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      Admin SimIndex
                    </Link>
                  </div>
                )}

                {authState.profile.hasUsage && (
                  <div className="m-2 bg-neutral-300 rounded-md">
                    <Link
                      to="/usage/"
                      className={classNames(
                        "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                        "group flex items-center px-2 py-2 my-4 text-sm font-medium"
                      )}
                    >
                      <ChartBarIcon
                        className={classNames(
                          "text-black group-hover:text-gray-500",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      Usage
                    </Link>
                  </div>
                )}
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default SidebarMobile;
