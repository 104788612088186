import { PropTypes } from "prop-types";

const LocalizadorAutos = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="538.000000pt"
      height="538.000000pt"
      viewBox="0 0 538.000000 538.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,538.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2450 5364 c-14 -2 -65 -9 -115 -15 -457 -55 -945 -264 -1325 -565
 -109 -87 -327 -305 -414 -414 -267 -336 -454 -737 -536 -1149 -43 -216 -53
 -350 -47 -610 5 -245 23 -378 77 -591 239 -945 985 -1691 1936 -1935 230 -59
 386 -78 654 -79 247 0 342 8 545 49 700 141 1301 545 1705 1146 376 557 526
 1272 409 1947 -93 539 -356 1046 -744 1436 -403 405 -932 673 -1505 762 -99
 15 -576 29 -640 18z m884 -868 c298 -58 521 -290 567 -594 21 -139 -8 -264
 -116 -505 -99 -220 -299 -563 -493 -845 l-84 -123 -78 113 c-262 382 -494 804
 -583 1062 -28 83 -31 102 -31 216 0 111 3 134 27 205 117 334 454 535 791 471z
 m-641 -1372 c32 -58 57 -107 55 -108 -2 -2 -43 -15 -91 -30 -254 -78 -484
 -252 -634 -479 -49 -74 -74 -102 -99 -113 -26 -11 -96 -14 -314 -14 -258 0
 -286 -2 -343 -21 -191 -64 -317 -233 -317 -427 0 -79 26 -130 80 -161 34 -19
 53 -21 181 -21 l144 1 25 40 c32 53 91 101 166 136 52 24 71 28 154 28 88 0
 101 -3 162 -33 45 -22 84 -52 122 -93 31 -33 56 -64 56 -70 0 -5 249 -9 650
 -9 l649 0 15 28 c8 15 37 49 65 75 169 159 431 136 574 -49 l42 -54 138 0
 c161 0 193 10 235 73 26 39 27 45 27 191 0 145 -1 153 -28 208 -46 94 -142
 158 -234 158 -65 0 -97 23 -155 111 -103 157 -205 260 -362 363 l-64 42 55 95
 c30 52 58 98 63 102 9 10 93 -41 184 -111 86 -67 198 -181 268 -275 57 -78 63
 -83 118 -98 200 -54 354 -218 389 -413 14 -78 14 -291 0 -358 -23 -108 -97
 -215 -186 -271 -81 -50 -121 -60 -272 -66 l-144 -6 -12 -42 c-30 -101 -112
 -194 -213 -241 -50 -24 -69 -27 -162 -27 -96 0 -110 3 -165 29 -33 17 -81 51
 -108 78 -46 46 -107 154 -107 191 0 16 -36 17 -609 17 l-608 0 -13 -46 c-17
 -58 -78 -150 -124 -187 -20 -15 -60 -40 -88 -55 -48 -24 -63 -27 -158 -27 -93
 0 -112 3 -162 27 -100 47 -177 133 -214 241 l-15 45 -142 4 c-168 5 -221 22
 -311 95 -174 142 -203 376 -79 633 39 81 59 108 137 185 74 74 107 99 176 132
 142 69 172 75 466 80 l261 5 18 30 c66 112 257 299 400 390 118 77 334 176
 385 178 9 0 38 -42 73 -106z"
        />
        <path
          d="M3080 4286 c-161 -45 -300 -181 -345 -337 -43 -151 -14 -274 130
 -564 83 -167 323 -576 341 -582 19 -5 270 424 370 631 85 176 124 296 124 378
 0 133 -48 246 -145 343 -68 68 -135 107 -220 130 -70 18 -192 18 -255 1z m206
 -266 c69 -26 124 -110 124 -190 0 -186 -222 -272 -348 -136 -68 74 -78 162
 -25 243 56 86 152 118 249 83z m-66 -751 c107 -16 170 -32 170 -42 0 -6 -26
 -55 -57 -109 l-58 -99 -60 7 c-33 3 -68 10 -77 15 -14 7 -148 230 -148 245 0
 6 151 -5 230 -17z"
        />
        <path
          d="M1655 1709 c-40 -12 -58 -28 -84 -69 -40 -65 -18 -153 48 -197 62
 -42 158 -23 203 40 30 42 31 125 2 163 -44 59 -107 83 -169 63z"
        />
        <path
          d="M3637 1706 c-69 -25 -97 -66 -97 -141 0 -81 49 -135 130 -143 52 -5
 90 11 129 52 35 37 41 127 12 173 -35 56 -111 81 -174 59z"
        />
      </g>
    </svg>
  );
};

LocalizadorAutos.propTypes = {
  className: PropTypes.string,
};

export default LocalizadorAutos;
