import { useContext, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import {
  InformationCircleIcon,
  // PhoneIcon,
  MailIcon,
} from "@heroicons/react/outline";
import Whatsapp from "../../components/icons/Dashboard/Whatsapp";
import { ModalContext } from "../../context/ModalContext";
import toast from "react-hot-toast";
import { FetchContext } from "../../context/FetchContext";
import ReactTooltip from "react-tooltip";
import Calendar from "../icons/Dashboard/CalendarIcon";

const MainModal = () => {
  const { authAxios } = useContext(FetchContext);

  const {
    modalMainState,
    onCloseModalMain,
    openModalWhatsapp,
    // openModalPhone,
    openModalCalendar,
    openModalEmail,
    setProfile,
  } = useContext(ModalContext);

  useEffect(() => {
    const getProfile = async () => {
      try {
        const { data } = await authAxios.get("profile");
        setProfile(data);
      } catch (err) {
        const { data } = err.response;
        if (data.statusCode === 401 && data.message === "Unauthorized") {
          toast.error("La sesión ha expirado");
          setTimeout(() => {
            history.push("/");
          }, 1500);
        } else {
          toast.error(data.message);
        }
      }
    };
    getProfile();
  }, []);

  return (
    <Modal
      open={modalMainState}
      onClose={onCloseModalMain}
      center
      classNames={{ overlay: "customOverlay", modal: "customModal" }}
    >
      <ReactTooltip place="top" type="dark" effect="solid" id="whatsapptooltip">
        Contactar con un asesor <br />
        por whatsapp
      </ReactTooltip>

      <ReactTooltip place="top" type="dark" effect="solid" id="Calendartooltip">
        Agenda una cita con uno <br /> de nuestros especialistas
      </ReactTooltip>

      <ReactTooltip place="top" type="dark" effect="solid" id="emailtooltip">
        Recibir información <br /> del producto en mi <br />
        cuenta de correo
      </ReactTooltip>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-simblue-300 sm:mx-0 sm:h-10 sm:w-10">
          <InformationCircleIcon
            className="h-6 w-6 text-simblue-800"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Saber mas
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Elige una opción para saber mas sobre este producto
            </p>
          </div>
        </div>
      </div>

      <div className="mt-6 sm:mt-8 sm:mb-8  sm:flex sm:justify-around">
        <button
          type="button"
          onClick={openModalWhatsapp}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 sm:w-auto sm:text-sm"
        >
          <div data-tip data-for="whatsapptooltip">
            <Whatsapp className="h-14 w-14 animate-bounce" />
          </div>
        </button>

        <button
          type="button"
          onClick={openModalCalendar}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 sm:w-auto sm:text-sm"
        >
          <div data-tip data-for="Calendartooltip">
            <Calendar className="h-14 w-14 animate-bounce" />
          </div>
        </button>

        {/* <button
          type="button"
          onClick={openModalPhone}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 sm:w-auto sm:text-sm"
        >
          <PhoneIcon className="h-14 w-14" />
        </button> */}
        <button
          type="button"
          onClick={openModalEmail}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 sm:w-auto sm:text-sm"
        >
          <div data-tip data-for="emailtooltip">
            <MailIcon className="h-14 w-14 animate-bounce" />
          </div>
        </button>
      </div>
    </Modal>
  );
};

export default MainModal;
