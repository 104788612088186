import { PropTypes } from "prop-types";

const SimHuman = ({ className }) => {
  return (
    <svg
      width="186"
      height="70"
      viewBox="0 0 186 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M59.1282 65.0292H17.467C13.5724 65.0292 10.1498 62.6688 9.20561 59.2462L1.88835 34.58C1.53429 33.2817 1.53429 31.8655 1.88835 30.4493L9.20561 5.783C10.1498 2.36041 13.5724 0 17.467 0H59.2462C61.6067 0 63.495 1.77031 63.495 3.89467V23.132L71.2843 30.2132C71.9924 30.8033 72.3465 31.6295 72.3465 32.4556C72.3465 33.2817 71.9924 34.1079 71.2843 34.698L63.495 41.7792V61.0165C63.377 63.2589 61.4886 65.0292 59.1282 65.0292ZM17.467 0.826142C13.9264 0.826142 10.8579 2.95051 10.0318 6.01904L2.71449 30.6853C2.36043 31.8655 2.36043 33.1637 2.71449 34.3439L9.91373 59.0102C10.8579 62.0787 13.9264 64.2031 17.349 64.2031H59.1282C61.0165 64.2031 62.5508 62.7868 62.5508 61.1345V41.5432L70.5762 34.2259C71.0483 33.7538 71.2843 33.1637 71.2843 32.5736C71.2843 31.9835 71.0483 31.3934 70.5762 30.9213L62.5508 23.486V3.89467C62.5508 2.24239 61.0165 0.826142 59.1282 0.826142H17.467Z"
        fill="white"
      />
      <path
        d="M55.5877 67.0355H32.2196C31.9836 67.0355 31.7476 66.7994 31.7476 66.5634C31.7476 66.3274 31.9836 66.0913 32.2196 66.0913H55.5877C55.8237 66.0913 56.0598 66.3274 56.0598 66.5634C56.0598 66.7994 55.8237 67.0355 55.5877 67.0355Z"
        fill="white"
      />
      <path
        d="M52.2832 69.0423H43.9037C43.6677 69.0423 43.4316 68.8063 43.4316 68.5702C43.4316 68.3342 43.6677 68.0981 43.9037 68.0981H52.2832C52.5192 68.0981 52.7553 68.3342 52.7553 68.5702C52.6372 68.8063 52.5192 69.0423 52.2832 69.0423Z"
        fill="white"
      />
      <path
        d="M0.472081 29.1509H0.354061C0.11802 29.0329 0 28.7968 0 28.5608L6.60914 6.25494C6.60914 6.0189 6.84518 5.90088 7.08122 5.90088C7.31726 6.0189 7.43528 6.25494 7.43528 6.49098L0.944163 28.7968C0.826142 28.9148 0.708122 29.1509 0.472081 29.1509Z"
        fill="white"
      />
      <path
        d="M65.3831 31.1575L58.656 24.6664V7.19939C58.656 5.5471 57.2397 4.13086 55.4694 4.13086H19.9453C16.7587 4.13086 14.0443 6.13721 13.2181 9.08771L6.96306 31.1575C6.60899 32.3377 6.60899 33.5179 6.96306 34.5801L13.2181 56.6499C14.0443 59.6004 16.7587 61.6068 19.9453 61.6068H55.4694C57.2397 61.6068 58.656 60.1905 58.656 58.5382V41.0712L65.3831 34.5801C66.4453 33.754 66.4453 32.1017 65.3831 31.1575ZM27.7346 22.424C29.623 21.7159 31.6293 22.6601 32.3374 24.5484C33.0456 26.4367 32.1014 28.4431 30.2131 29.1512C28.3247 29.8593 26.3184 28.9151 25.6103 27.0268C25.0202 25.2565 25.9643 23.1321 27.7346 22.424ZM18.175 35.1702C19.3552 34.108 21.0075 34.226 22.0697 35.2882C23.1318 36.4684 23.0138 38.1207 21.8336 39.1829C20.7714 40.2451 19.0011 40.1271 17.9389 39.0649C16.9948 38.0027 17.1128 36.2324 18.175 35.1702ZM29.2689 51.9291L17.4669 41.7794C19.8273 42.0154 22.6598 41.1893 24.9021 39.1829L32.1014 47.0903C30.9212 48.3885 29.859 50.0408 29.2689 51.9291ZM32.5735 46.7362L23.958 30.0953C26.6725 31.5116 30.4491 31.8656 34.2258 30.4494L39.1826 43.4316C36.4681 43.9037 34.2258 45.0839 32.5735 46.7362ZM47.5621 45.32C45.6737 44.0217 43.3133 43.3136 40.9529 43.3136C40.4808 43.3136 40.0088 43.3136 39.5367 43.4316L37.0582 22.542C39.3006 25.1385 43.1953 26.9088 47.6801 27.0268L47.5621 45.32ZM44.1395 24.1943C41.8971 24.1943 40.1268 22.424 40.1268 20.1816C40.1268 17.9392 41.8971 16.1689 44.1395 16.1689C46.3819 16.1689 48.1522 17.9392 48.1522 20.1816C48.1522 22.306 46.2638 24.1943 44.1395 24.1943Z"
        fill="white"
      />
      <path
        d="M75.6511 37.5308C75.7691 37.6488 76.0052 37.7668 76.2412 38.0028C76.4772 38.1209 76.9493 38.3569 77.7755 38.711C78.4836 38.947 79.3097 39.065 80.0179 39.065C80.962 39.065 81.7882 38.829 82.2602 38.4749C82.7323 38.1209 82.9684 37.5308 82.9684 36.9406C82.9684 36.3505 82.7323 35.8785 82.2602 35.5244C81.7882 35.1703 80.844 34.8163 79.5458 34.3442C77.7755 33.8721 76.4772 33.164 75.6511 32.5739C74.825 31.8658 74.4709 31.0396 74.4709 29.8594C74.4709 28.5612 74.943 27.499 75.8871 26.6729C76.8313 25.8467 78.2476 25.4927 80.0179 25.4927C81.1981 25.4927 82.1422 25.6107 83.0864 25.8467C84.0306 26.0828 84.6207 26.3188 84.8567 26.6729L85.3288 27.0269L83.9125 29.1513C83.7945 29.0333 83.6765 28.9153 83.4404 28.7972C83.2044 28.6792 82.7323 28.4432 82.1422 28.2071C81.4341 27.9711 80.726 27.8531 80.0179 27.8531C79.0737 27.8531 78.4836 27.9711 77.8935 28.3252C77.4214 28.6792 77.1854 29.1513 77.1854 29.7414C77.1854 30.2135 77.4214 30.6856 77.8935 31.0396C78.3656 31.3937 79.3097 31.7478 80.608 32.1018C82.4963 32.5739 83.7945 33.282 84.6206 33.9901C85.4468 34.6983 85.8009 35.6424 85.8009 36.8226C85.8009 38.2389 85.3288 39.3011 84.3846 40.1272C83.4404 40.9533 82.0242 41.4254 80.1359 41.4254C79.4278 41.4254 78.7196 41.4254 78.0115 41.1894C77.3034 41.0714 76.8313 40.9533 76.3592 40.7173C75.8871 40.4813 75.5331 40.3632 75.179 40.1272C74.825 39.8912 74.5889 39.7731 74.4709 39.6551L74.2349 39.5371L75.6511 37.5308Z"
        fill="white"
      />
      <path
        d="M88.279 27.8523C87.9249 27.4982 87.8069 27.1442 87.8069 26.7901C87.8069 26.318 87.9249 25.964 88.279 25.7279C88.633 25.4919 88.9871 25.2559 89.3411 25.2559C89.8132 25.2559 90.1673 25.3739 90.4033 25.7279C90.7574 25.964 90.8754 26.318 90.8754 26.7901C90.8754 27.2622 90.7574 27.6163 90.4033 27.8523C90.0493 28.0883 89.6952 28.3244 89.3411 28.3244C88.8691 28.3244 88.515 28.2064 88.279 27.8523ZM88.0429 30.3307H90.5213V41.0706H88.0429V30.3307Z"
        fill="white"
      />
      <path
        d="M93.708 30.3313H95.3603L95.7144 31.8655H95.8324C96.6585 30.6853 97.8387 30.0952 99.373 30.0952C101.143 30.0952 102.323 30.8033 103.15 31.9835H103.268C104.094 30.6853 105.274 30.0952 106.926 30.0952C108.343 30.0952 109.405 30.5673 110.113 31.3934C110.821 32.2196 111.293 33.3998 111.293 34.9341V41.0711H108.815V35.1701C108.815 34.3439 108.579 33.6358 108.225 33.0457C107.752 32.4556 107.162 32.2196 106.336 32.2196C105.51 32.2196 104.92 32.4556 104.566 32.9277C104.094 33.3998 103.858 33.9899 103.858 34.58V40.9531H101.379V34.816C101.379 33.9899 101.143 33.3998 100.671 32.9277C100.199 32.4556 99.609 32.2196 98.7829 32.2196C97.9567 32.2196 97.3666 32.4556 97.0126 32.9277C96.5405 33.3998 96.3045 33.9899 96.3045 34.58V40.9531H93.826L93.708 30.3313Z"
        fill="white"
      />
      <path
        d="M127.226 40.9527H124.748V34.4615H116.958V40.9527H114.48V25.728H116.958V32.3372H124.748V25.728H127.226V40.9527Z"
        fill="white"
      />
      <path
        d="M137.966 30.3315H140.444V41.0714H138.792L138.32 39.5371H138.202C137.375 40.7173 136.195 41.3074 134.661 41.3074C133.245 41.3074 132.183 40.8354 131.474 40.0092C130.648 39.1831 130.294 38.0029 130.294 36.4686V30.3315H132.773V36.2326C132.773 37.1767 133.009 37.8848 133.363 38.3569C133.717 38.947 134.425 39.1831 135.133 39.1831C135.959 39.1831 136.667 38.947 137.021 38.3569C137.493 37.7668 137.729 37.1767 137.729 36.5866L137.966 30.3315Z"
        fill="white"
      />
      <path
        d="M143.513 30.3313H145.165L145.519 31.8655H145.637C146.463 30.6853 147.644 30.0952 149.178 30.0952C150.948 30.0952 152.128 30.8033 152.955 31.9835H153.073C153.899 30.6853 155.079 30.0952 156.731 30.0952C158.147 30.0952 159.21 30.5673 159.918 31.3934C160.626 32.2196 161.098 33.3998 161.098 34.9341V41.0711H158.62V35.1701C158.62 34.3439 158.383 33.6358 158.029 33.0457C157.557 32.4556 156.967 32.2196 156.141 32.2196C155.315 32.2196 154.725 32.4556 154.371 32.9277C153.899 33.3998 153.663 33.9899 153.663 34.58V40.9531H151.184V34.816C151.184 33.9899 150.948 33.3998 150.476 32.9277C150.004 32.4556 149.414 32.2196 148.588 32.2196C147.762 32.2196 147.172 32.4556 146.818 32.9277C146.345 33.3998 146.109 33.9899 146.109 34.58V40.9531H143.631L143.513 30.3313Z"
        fill="white"
      />
      <path
        d="M172.9 40.9525H171.129L170.775 39.5362H170.657C169.949 40.5984 168.769 41.1885 167.117 41.1885C165.937 41.1885 164.874 40.8344 164.166 40.2443C163.458 39.6542 163.104 38.8281 163.104 37.7659C163.104 36.7037 163.576 35.7596 164.402 35.1695C165.228 34.5794 166.409 34.2253 168.061 34.2253C168.887 34.2253 169.713 34.3433 170.539 34.5794V34.2253C170.539 32.691 169.595 31.9829 167.825 31.9829C167.353 31.9829 166.881 32.1009 166.409 32.2189C165.936 32.337 165.582 32.455 165.346 32.573L164.992 32.8091L164.166 30.9207C165.228 30.2126 166.527 29.9766 168.297 29.9766C169.831 29.9766 171.011 30.3306 171.838 31.1568C172.664 31.9829 173.018 33.0451 173.018 34.3433L172.9 40.9525ZM169.713 38.7101C170.185 38.238 170.539 37.7659 170.539 37.0578V36.2316C169.949 35.9956 169.241 35.8776 168.415 35.8776C166.527 35.8776 165.7 36.4677 165.7 37.7659C165.7 38.238 165.936 38.7101 166.291 39.0641C166.645 39.4182 167.235 39.5362 167.825 39.5362C168.533 39.4182 169.241 39.1821 169.713 38.7101Z"
        fill="white"
      />
      <path
        d="M175.85 30.3313H177.503L177.857 31.8655H177.975C178.801 30.6853 179.981 30.0952 181.515 30.0952C182.932 30.0952 183.994 30.5673 184.82 31.3934C185.646 32.2196 186 33.3998 186 34.9341V41.0711H183.522V35.1701C183.522 34.3439 183.286 33.6358 182.814 33.0457C182.341 32.4556 181.751 32.2196 180.925 32.2196C180.099 32.2196 179.509 32.4556 179.037 32.9277C178.565 33.3998 178.329 33.9899 178.329 34.58V40.9531H175.85V30.3313Z"
        fill="white"
      />
      <path
        d="M78.4833 46.854H76.0049V49.0964H75.4148V44.0215H78.9554V44.6116H76.0049V46.2639H78.4833V46.854Z"
        fill="white"
      />
      <path
        d="M81.316 48.5063C80.8439 48.0342 80.6079 47.3261 80.6079 46.6179C80.6079 45.9098 80.8439 45.2017 81.316 44.7296C81.7881 44.2575 82.3782 44.0215 83.0863 44.0215C83.7945 44.0215 84.5026 44.2575 84.8566 44.7296C85.2107 45.2017 85.5648 45.9098 85.5648 46.6179C85.5648 47.3261 85.3287 48.0342 84.8566 48.5063C84.3846 48.9783 83.7945 49.2144 83.0863 49.2144C82.3782 49.2144 81.7881 48.9783 81.316 48.5063ZM84.5026 48.0342C84.8566 47.6801 84.9747 47.09 84.9747 46.4999C84.9747 45.9098 84.8566 45.4377 84.5026 44.9656C84.1485 44.6116 83.6764 44.3755 83.0863 44.3755C82.4962 44.3755 82.0241 44.6116 81.6701 44.9656C81.316 45.3197 81.198 45.9098 81.198 46.4999C81.198 47.09 81.434 47.5621 81.6701 48.0342C82.0241 48.3882 82.4962 48.6243 83.0863 48.6243C83.6764 48.6243 84.1485 48.5063 84.5026 48.0342Z"
        fill="white"
      />
      <path
        d="M91.7018 48.6244C91.5837 48.7424 91.5837 48.7424 91.4657 48.8605C91.3477 48.9785 91.2297 48.9785 91.1117 49.0965C90.9936 49.2145 90.8756 49.2145 90.6396 49.2145C90.4035 49.2145 90.2855 49.3325 90.0495 49.3325C89.3414 49.3325 88.7513 49.0965 88.2792 48.6244C87.8071 48.1523 87.571 47.4442 87.571 46.7361C87.571 46.028 87.8071 45.3199 88.2792 44.8478C88.7513 44.3757 89.3414 44.1396 90.0495 44.1396C90.4035 44.1396 90.7576 44.2577 90.9936 44.2577C91.2297 44.3757 91.4657 44.4937 91.5837 44.6117L91.7018 44.7298L91.3477 45.2018L91.2297 45.0838C91.2297 45.0838 90.9936 44.9658 90.7576 44.8478C90.5216 44.7297 90.2855 44.7298 90.0495 44.7298C89.4594 44.7298 88.9873 44.9658 88.6332 45.3199C88.2792 45.6739 88.0431 46.264 88.0431 46.8541C88.0431 47.4442 88.2792 47.9163 88.6332 48.3884C88.9873 48.7424 89.4594 48.9785 90.0495 48.9785C90.1675 48.9785 90.4035 48.9785 90.5216 48.8605C90.6396 48.7424 90.7576 48.7424 90.8756 48.7424C90.9936 48.6244 91.1117 48.6244 91.1117 48.6244C91.2297 48.6244 91.2297 48.5064 91.3477 48.5064V48.3884L91.7018 48.6244Z"
        fill="white"
      />
      <path
        d="M94.2981 48.6243C93.944 48.2702 93.708 47.6801 93.708 46.972V44.0215H94.2981V46.972C94.2981 47.4441 94.4161 47.9162 94.6522 48.1522C94.8882 48.5063 95.2423 48.6243 95.7144 48.6243C96.1864 48.6243 96.5405 48.5063 96.7765 48.1522C97.0126 47.7981 97.1306 47.4441 97.1306 46.972V44.0215H97.7207V46.972C97.7207 47.6801 97.4847 48.1522 97.1306 48.6243C96.7765 49.0964 96.3045 49.2144 95.5963 49.2144C95.1243 49.2144 94.6522 48.9783 94.2981 48.6243Z"
        fill="white"
      />
      <path
        d="M100.317 48.1525L100.435 48.2706C100.553 48.3886 100.671 48.3886 100.907 48.5066C101.143 48.6246 101.379 48.6246 101.733 48.6246C102.206 48.6246 102.442 48.5066 102.678 48.3886C102.914 48.2706 103.032 48.0345 103.032 47.7985C103.032 47.5624 102.914 47.3264 102.796 47.2084C102.56 47.0904 102.206 46.8543 101.733 46.7363C101.143 46.5003 100.671 46.3822 100.435 46.1462C100.199 45.9102 100.081 45.6741 100.081 45.202C100.081 44.848 100.199 44.4939 100.553 44.2579C100.907 44.0218 101.379 43.9038 101.851 43.9038C102.088 43.9038 102.324 43.9038 102.442 43.9038C102.56 43.9038 102.796 44.0218 102.914 44.0218C103.032 44.0218 103.15 44.1399 103.268 44.1399C103.386 44.1399 103.386 44.2579 103.504 44.2579H103.622L103.15 44.848L103.032 44.73C103.032 44.73 102.796 44.6119 102.56 44.4939C102.324 44.3759 102.088 44.3759 101.851 44.3759C101.497 44.3759 101.261 44.4939 101.025 44.6119C100.789 44.7299 100.671 44.966 100.671 45.202C100.671 45.4381 100.789 45.5561 100.907 45.6741C101.143 45.7921 101.497 45.9102 101.969 46.1462C102.56 46.3822 103.032 46.5003 103.268 46.7363C103.504 46.9723 103.622 47.3264 103.622 47.6805C103.622 48.1525 103.504 48.5066 103.15 48.7426C102.796 48.9787 102.324 49.0967 101.733 49.0967C101.497 49.0967 101.261 49.0967 101.143 48.9787C100.907 48.9787 100.789 48.8607 100.671 48.8607C100.553 48.7426 100.435 48.7426 100.317 48.6246C100.199 48.5066 100.199 48.5066 100.081 48.5066L99.9631 48.3886L100.317 48.1525Z"
        fill="white"
      />
      <path
        d="M108.933 46.854H106.454V48.5063H109.405V49.0964H105.864V44.0215H109.405V44.6116H106.454V46.2639H108.933V46.854Z"
        fill="white"
      />
      <path
        d="M115.188 48.3882C114.716 48.8603 114.126 49.0964 113.3 49.0964H111.647V44.0215H113.3C114.008 44.0215 114.716 44.2575 115.188 44.7296C115.66 45.2017 115.896 45.7918 115.896 46.6179C115.896 47.3261 115.66 47.9162 115.188 48.3882ZM112.238 44.6116V48.6243H113.3C113.89 48.6243 114.362 48.5063 114.716 48.1522C115.07 47.7981 115.306 47.3261 115.306 46.736C115.306 46.1458 115.07 45.6738 114.716 45.3197C114.362 44.9656 113.89 44.7296 113.3 44.7296L112.238 44.6116Z"
        fill="white"
      />
      <path
        d="M121.561 48.5063C121.089 48.0342 120.853 47.3261 120.853 46.6179C120.853 45.9098 121.089 45.2017 121.561 44.7296C122.033 44.2575 122.623 44.0215 123.331 44.0215C124.039 44.0215 124.747 44.2575 125.102 44.7296C125.574 45.2017 125.81 45.9098 125.81 46.6179C125.81 47.3261 125.574 48.0342 125.102 48.5063C124.629 48.9783 124.039 49.2144 123.331 49.2144C122.623 49.2144 122.033 48.9783 121.561 48.5063ZM124.747 48.0342C125.102 47.6801 125.22 47.09 125.22 46.4999C125.22 45.9098 125.102 45.4377 124.747 44.9656C124.393 44.6116 123.921 44.3755 123.331 44.3755C122.741 44.3755 122.269 44.6116 121.915 44.9656C121.561 45.3197 121.443 45.9098 121.443 46.4999C121.443 47.09 121.679 47.5621 121.915 48.0342C122.269 48.3882 122.741 48.6243 123.331 48.6243C123.921 48.6243 124.393 48.5063 124.747 48.0342Z"
        fill="white"
      />
      <path
        d="M132.301 49.0964H131.947L128.642 45.0837V49.0964H128.052V44.0215H128.524L131.711 48.0342V44.0215H132.301V49.0964Z"
        fill="white"
      />
      <path
        d="M140.444 44.9656L139.382 49.0964H138.91L137.258 44.0215H137.848L139.146 48.1522L140.208 44.0215H140.798L141.86 48.1522L143.159 44.0215H143.749L142.096 49.0964H141.624L140.444 44.9656Z"
        fill="white"
      />
      <path
        d="M148.942 46.854H146.345V48.5063H149.296V49.0964H145.755V44.0215H149.296V44.6116H146.345V46.2639H148.942V46.854Z"
        fill="white"
      />
      <path
        d="M151.538 49.0964V44.0215H152.128V48.5063H154.843V49.0964H151.538Z"
        fill="white"
      />
      <path
        d="M156.849 49.0964V44.0215H157.439V48.5063H160.153V49.0964H156.849Z"
        fill="white"
      />
      <path
        d="M166.409 49.0964H166.055L162.75 45.0837V49.0964H162.16V44.0215H162.632L165.819 48.0342V44.0215H166.409V49.0964Z"
        fill="white"
      />
      <path
        d="M172.192 46.854H169.595V48.5063H172.546V49.0964H169.005V44.0215H172.546V44.6116H169.595V46.2639H172.192V46.854Z"
        fill="white"
      />
      <path
        d="M174.788 48.1525L174.906 48.2706C175.024 48.3886 175.142 48.3886 175.378 48.5066C175.614 48.6246 175.85 48.6246 176.204 48.6246C176.676 48.6246 176.913 48.5066 177.149 48.3886C177.385 48.2706 177.503 48.0345 177.503 47.7985C177.503 47.5624 177.385 47.3264 177.267 47.2084C177.031 47.0904 176.676 46.8543 176.204 46.7363C175.614 46.5003 175.142 46.3822 174.906 46.1462C174.67 45.9102 174.552 45.6741 174.552 45.202C174.552 44.848 174.67 44.4939 175.024 44.2579C175.378 44.0218 175.732 43.9038 176.322 43.9038C176.558 43.9038 176.794 43.9038 176.912 43.9038C177.031 43.9038 177.267 44.0218 177.385 44.0218C177.503 44.0218 177.621 44.1399 177.739 44.1399C177.857 44.1399 177.857 44.2579 177.975 44.2579L177.621 44.73L177.503 44.6119C177.385 44.6119 177.267 44.4939 177.031 44.3759C176.794 44.2579 176.558 44.2579 176.322 44.2579C175.968 44.2579 175.732 44.3759 175.496 44.4939C175.26 44.6119 175.142 44.848 175.142 45.084C175.142 45.3201 175.26 45.4381 175.378 45.5561C175.614 45.6741 175.968 45.7921 176.44 46.0282C177.031 46.2642 177.503 46.3822 177.739 46.6183C177.975 46.8543 178.093 47.2084 178.093 47.5624C178.093 48.0345 177.975 48.3886 177.621 48.6246C177.267 48.8607 176.794 48.9787 176.204 48.9787C175.968 48.9787 175.732 48.9787 175.614 48.8607C175.378 48.8607 175.26 48.7426 175.142 48.7426C175.024 48.6246 174.906 48.6246 174.788 48.5066C174.67 48.3886 174.67 48.3886 174.552 48.3886L174.434 48.2706L174.788 48.1525Z"
        fill="white"
      />
      <path
        d="M180.217 48.1525L180.335 48.2706C180.453 48.3886 180.571 48.3886 180.807 48.5066C181.043 48.6246 181.279 48.6246 181.633 48.6246C182.106 48.6246 182.342 48.5066 182.578 48.3886C182.814 48.2706 182.932 48.0345 182.932 47.7985C182.932 47.5624 182.814 47.3264 182.696 47.2084C182.46 47.0904 182.106 46.8543 181.633 46.7363C181.043 46.5003 180.571 46.3822 180.335 46.1462C180.099 45.9102 179.981 45.6741 179.981 45.202C179.981 44.848 180.099 44.4939 180.453 44.2579C180.807 44.0218 181.279 43.9038 181.751 43.9038C181.988 43.9038 182.224 43.9038 182.342 43.9038C182.46 43.9038 182.696 44.0218 182.814 44.0218C182.932 44.0218 183.05 44.1399 183.168 44.1399C183.286 44.1399 183.286 44.2579 183.404 44.2579L183.05 44.73L182.932 44.6119C182.814 44.6119 182.696 44.4939 182.46 44.3759C182.224 44.2579 181.988 44.2579 181.751 44.2579C181.397 44.2579 181.043 44.3759 180.925 44.4939C180.689 44.6119 180.571 44.848 180.571 45.084C180.571 45.3201 180.689 45.4381 180.807 45.5561C181.043 45.6741 181.397 45.7921 181.869 46.0282C182.46 46.2642 182.932 46.3822 183.168 46.6183C183.404 46.8543 183.522 47.2084 183.522 47.5624C183.522 48.0345 183.404 48.3886 183.05 48.6246C182.696 48.8607 182.224 48.9787 181.633 48.9787C181.397 48.9787 181.161 48.9787 180.925 48.8607C180.689 48.8607 180.571 48.7426 180.453 48.7426C180.335 48.6246 180.217 48.6246 180.099 48.5066C179.981 48.3886 179.981 48.3886 179.863 48.3886L179.745 48.2706L180.217 48.1525Z"
        fill="white"
      />
    </svg>
  );
};

SimHuman.propTypes = {
  className: PropTypes.string,
};

export default SimHuman;
