import { PropTypes } from "prop-types";

const Demografia = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="526.000000pt"
      height="526.000000pt"
      viewBox="0 0 526.000000 526.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,526.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2415 5249 c-897 -72 -1700 -608 -2115 -1411 -127 -247 -207 -485
-257 -768 -31 -178 -42 -508 -24 -696 61 -610 334 -1182 769 -1611 395 -391
898 -641 1462 -730 145 -23 595 -26 735 -5 764 115 1404 513 1824 1137 233
347 369 705 428 1126 23 170 23 519 -1 684 -46 322 -132 595 -272 865 -340
652 -932 1131 -1639 1325 -291 80 -605 109 -910 84z m578 -1007 c41 -14 124
-86 507 -440 412 -380 460 -428 474 -470 14 -40 16 -168 16 -1091 0 -707 -4
-1058 -11 -1084 -13 -47 -72 -115 -116 -134 -27 -11 -248 -13 -1228 -13 -1057
0 -1200 2 -1233 15 -47 20 -79 50 -103 97 -18 36 -19 82 -19 1508 0 1616 -4
1504 63 1568 65 63 39 61 857 61 677 0 749 -1 793 -17z"
        />
        <path
          d="M1530 2635 l0 -1375 1100 0 1100 0 0 905 0 905 -397 0 c-379 0 -400
1 -443 21 -55 25 -105 71 -133 124 -21 38 -22 55 -27 415 l-5 375 -597 3 -598
2 0 -1375z m449 192 c35 -21 71 -84 71 -127 0 -44 -36 -106 -75 -130 -101 -62
-235 10 -235 127 0 73 36 123 107 149 44 16 85 10 132 -19z m1529 -124 l3
-123 -666 0 -665 0 0 125 0 125 663 -2 662 -3 3 -122z m-1513 -393 c87 -84 61
-213 -50 -256 -39 -14 -52 -15 -88 -5 -132 39 -160 203 -47 279 29 20 45 23
93 20 49 -4 62 -9 92 -38z m1513 -112 l-3 -123 -662 -3 -663 -2 0 125 0 125
665 0 666 0 -3 -122z m-1538 -370 c108 -74 103 -208 -10 -266 -75 -39 -146
-18 -197 58 -64 94 11 230 127 230 32 0 57 -7 80 -22z m1538 -130 l-3 -123
-662 -3 -663 -2 0 125 0 125 665 0 666 0 -3 -122z"
        />
        <path
          d="M2980 3592 l0 -272 23 -5 c12 -3 143 -4 291 -3 l269 3 -224 210
c-123 115 -254 239 -291 274 l-68 64 0 -271z"
        />
      </g>
    </svg>
  );
};

Demografia.propTypes = {
  className: PropTypes.string,
};

export default Demografia;
