import { PropTypes } from "prop-types";

const Simetrcis = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="525.000000pt"
      height="526.000000pt"
      viewBox="0 0 525.000000 526.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,526.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2347 5239 c-591 -62 -1153 -332 -1577 -758 -414 -415 -666 -932
-745 -1526 -19 -144 -22 -455 -5 -605 66 -601 328 -1148 755 -1575 421 -422
960 -681 1565 -751 177 -20 496 -15 670 11 145 22 386 82 516 130 241 88 549
263 750 425 514 415 853 1015 950 1685 22 149 25 521 6 671 -39 304 -122 580
-258 854 -235 474 -615 870 -1086 1131 -283 157 -622 265 -957 304 -135 16
-451 18 -584 4z m480 -1132 c68 -39 123 -74 123 -77 0 -8 -312 -193 -324 -193
-6 0 -83 43 -170 96 l-159 96 34 20 c19 11 93 56 164 99 l130 79 40 -24 c22
-13 95 -56 162 -96z m-376 -213 l159 -95 0 -859 0 -860 -37 -23 c-21 -13 -95
-61 -165 -105 l-128 -82 0 1060 c0 583 3 1060 6 1060 3 0 78 -43 165 -96z
m519 -959 c0 -580 -2 -1055 -5 -1055 -3 0 -77 46 -165 101 l-160 102 0 858 0
858 158 95 c86 52 160 95 165 96 4 0 7 -475 7 -1055z m551 679 c88 -52 157
-99 154 -103 -2 -5 -75 -50 -161 -101 -122 -74 -160 -91 -172 -84 -10 6 -83
50 -165 99 -81 48 -146 89 -145 90 6 6 323 195 326 195 2 0 76 -43 163 -96z
m-338 -233 l157 -93 0 -403 0 -402 -159 -92 c-87 -50 -161 -91 -165 -91 -3 0
-6 266 -6 591 0 325 4 589 8 587 5 -1 79 -45 165 -97z m527 -506 c0 -333 -3
-605 -7 -605 -5 0 -80 47 -168 103 l-160 103 0 404 0 404 160 97 c88 54 163
98 168 98 4 1 7 -271 7 -604z m-1648 359 c141 -85 157 -97 141 -108 -10 -7
-84 -52 -164 -100 l-146 -87 -162 97 c-102 61 -158 101 -152 107 14 14 305
187 314 187 5 0 81 -43 169 -96z m-309 -253 l127 -76 -2 -199 -3 -199 -155
-98 c-85 -54 -158 -98 -162 -98 -5 -1 -8 177 -8 395 l0 396 38 -22 c20 -12 94
-57 165 -99z m487 -267 l0 -386 -32 20 c-18 10 -93 52 -165 91 l-133 72 0 198
0 198 158 96 c86 52 160 96 165 96 4 1 7 -173 7 -385z m-911 100 l161 -97
-165 -99 -165 -99 -160 96 c-88 53 -160 99 -160 103 1 9 301 190 317 191 6 1
84 -42 172 -95z m2920 -86 c75 -45 144 -86 153 -93 14 -9 -8 -26 -148 -109
l-164 -99 -165 99 c-90 54 -163 99 -162 100 25 23 326 193 336 189 8 -3 75
-42 150 -87z m-3262 -145 l163 -98 0 -213 c0 -117 -3 -211 -7 -209 -5 2 -81
48 -170 101 l-163 97 0 210 c0 115 3 209 7 209 4 0 81 -44 170 -97z m521 -115
l-3 -213 -133 -80 c-73 -45 -146 -89 -162 -99 l-30 -17 0 215 0 215 158 95
c86 52 160 95 165 96 4 0 6 -96 5 -212z m2404 24 l163 -98 3 -212 c1 -117 -1
-212 -5 -212 -5 0 -80 44 -168 98 l-160 97 -3 213 c-1 116 0 212 2 212 3 0 78
-44 168 -98z m528 -115 l0 -212 -127 -77 c-71 -42 -145 -86 -165 -98 l-38 -22
0 214 0 213 163 98 c89 53 163 97 165 97 1 0 2 -96 2 -213z m-2381 -93 l162
-96 -158 -96 c-87 -52 -162 -95 -168 -95 -14 0 -315 183 -315 191 1 7 298 189
311 191 4 1 80 -42 168 -95z m1463 0 c87 -53 158 -97 156 -99 -2 -2 -74 -46
-161 -98 l-159 -95 -159 96 c-101 60 -156 99 -150 105 9 9 303 187 309 187 2
0 76 -43 164 -96z m-1791 -235 l149 -91 0 -210 0 -210 -37 22 c-21 12 -95 56
-165 98 l-128 77 0 209 c0 188 2 208 16 202 9 -4 84 -47 165 -97z m509 -108
l0 -210 -159 -95 c-87 -53 -162 -96 -165 -96 -3 0 -6 94 -6 209 l0 208 158 96
c86 52 160 96 165 96 4 1 7 -93 7 -208z m932 122 c79 -47 149 -91 156 -98 9
-8 12 -60 10 -217 l-3 -206 -157 94 -158 95 0 210 c0 115 2 209 5 209 2 0 69
-39 147 -87z m519 -336 c-5 -5 -76 -49 -158 -98 l-148 -89 -3 207 c-1 118 2
211 7 216 5 5 76 49 158 98 l148 89 3 -207 c1 -118 -2 -211 -7 -216z m-888
145 l148 -90 -161 -97 c-88 -53 -164 -95 -169 -93 -31 12 -310 184 -308 190 1
4 72 50 157 102 106 64 160 91 170 86 8 -5 82 -49 163 -98z m-193 -523 c0
-115 -3 -209 -6 -209 -3 0 -75 42 -160 92 l-154 93 0 212 0 212 160 -96 160
-96 0 -208z m360 189 l0 -213 -152 -90 c-83 -49 -158 -91 -165 -93 -11 -3 -13
34 -11 204 l3 208 160 97 c88 54 161 98 163 98 1 1 2 -95 2 -211z"
        />
      </g>
    </svg>
  );
};

Simetrcis.propTypes = {
  className: PropTypes.string,
};

export default Simetrcis;
