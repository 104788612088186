import { PropTypes } from "prop-types";

const AutomotrizSuite = ({ className }) => {
  return (
    <svg
      width="212"
      height="61"
      viewBox="0 0 212 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M49.2978 58.441C49.2978 58.1998 49.1371 58.0391 48.8959 58.0391H28.317C28.0758 58.0391 27.915 58.1998 27.915 58.441C27.915 58.6822 28.0758 58.8429 28.317 58.8429H48.8959C49.0567 58.8429 49.2978 58.6822 49.2978 58.441Z"
        fill="white"
      />
      <path
        d="M0.342457 25.5629C0.422843 25.5629 0.422843 25.5629 0.422843 25.5629C0.583616 25.5629 0.744389 25.4825 0.824775 25.2413L6.53221 5.70742C6.6126 5.46626 6.45183 5.30548 6.29105 5.2251C6.21067 5.2251 6.21067 5.2251 6.21067 5.2251C6.04989 5.2251 5.88912 5.30548 5.80873 5.54664L0.0209109 25.0002C-0.0594755 25.2413 0.101297 25.4825 0.342457 25.5629Z"
        fill="white"
      />
      <path
        d="M8.05953 5.06435L1.62861 26.7687C1.30706 27.9745 1.30706 29.1803 1.62861 30.3861L8.05953 52.0904C8.94378 55.0647 11.9181 57.1548 15.2943 57.1548H51.9505C53.9602 57.1548 55.6483 55.6274 55.6483 53.7785V36.817L62.4811 30.6272C63.0439 30.0645 63.3654 29.4214 63.3654 28.6176C63.3654 27.8941 63.0439 27.1706 62.4811 26.6079L55.6483 20.4182V3.37623C55.6483 1.52734 53.9602 0 51.9505 0H15.3747C11.9181 0 8.94378 2.09005 8.05953 5.06435ZM15.3747 0.723478H52.0309C53.6386 0.723478 55.0052 1.92927 55.0052 3.37623V20.6593L62.0792 27.0902C62.4811 27.4922 62.7223 27.9745 62.7223 28.5372C62.7223 29.0999 62.4811 29.5822 62.0792 29.9841L55.0052 36.4151V53.6981C55.0052 55.1451 53.6386 56.3509 52.0309 56.3509H15.3747C12.32 56.3509 9.58687 54.502 8.783 51.7689L2.35209 30.0645C2.03054 29.0195 2.03054 27.8941 2.35209 26.8491L8.783 5.14473C9.58687 2.65275 12.32 0.723478 15.3747 0.723478Z"
        fill="white"
      />
      <path
        d="M46.3236 60.2899C46.3236 60.0487 46.1628 59.8879 45.9217 59.8879H38.6065C38.3654 59.8879 38.2046 60.0487 38.2046 60.2899C38.2046 60.531 38.3654 60.6918 38.6065 60.6918H45.9217C46.1628 60.6918 46.3236 60.531 46.3236 60.2899Z"
        fill="white"
      />
      <path
        d="M28.6387 34.4055L29.2014 41.0776C29.4425 41.0776 29.6837 41.0776 29.9249 41.0776C30.166 41.0776 30.4072 41.0776 30.6483 41.0776L31.211 34.4055C30.8091 34.4859 30.4072 34.5663 30.0052 34.5663C29.4425 34.5663 29.0406 34.4859 28.6387 34.4055Z"
        fill="white"
      />
      <path
        d="M29.9248 30.5468C30.5679 30.5468 31.1306 29.9841 31.1306 29.3411C31.1306 28.698 30.5679 28.1353 29.9248 28.1353C29.2817 28.1353 28.719 28.698 28.719 29.3411C28.719 29.9841 29.2013 30.5468 29.9248 30.5468Z"
        fill="white"
      />
      <path
        d="M29.9248 32.9584C31.9344 32.9584 33.5422 31.3507 33.5422 29.341C33.5422 27.3314 31.9344 25.7236 29.9248 25.7236C27.9151 25.7236 26.3074 27.3314 26.3074 29.341C26.3074 31.3507 27.9151 32.9584 29.9248 32.9584ZM29.9248 26.6079C31.4521 26.6079 32.6579 27.8137 32.6579 29.341C32.6579 30.8684 31.4521 32.0742 29.9248 32.0742C28.3974 32.0742 27.1916 30.8684 27.1916 29.341C27.1112 27.8137 28.3974 26.6079 29.9248 26.6079Z"
        fill="white"
      />
      <path
        d="M32.7382 33.6819L32.1755 40.8363C36.9183 39.8717 40.6965 36.0131 41.5004 31.1899L34.2656 32.1546C33.8637 32.7977 33.3813 33.28 32.7382 33.6819Z"
        fill="white"
      />
      <path
        d="M25.5839 32.1546L18.3491 31.1899C19.153 36.0131 22.8508 39.8717 27.674 40.8363L27.1112 33.6819C26.4682 33.28 25.9858 32.7977 25.5839 32.1546Z"
        fill="white"
      />
      <path
        d="M24.6998 29.3412C24.6998 28.8589 24.7802 28.4569 24.8606 27.9746H18.2689C18.1885 28.3765 18.1885 28.8589 18.1885 29.3412C18.1885 29.4216 18.1885 29.502 18.1885 29.5823L24.8606 30.4666C24.7802 30.145 24.6998 29.7431 24.6998 29.3412Z"
        fill="white"
      />
      <path
        d="M29.9248 13.7461C21.3235 13.7461 14.3298 20.7397 14.3298 29.3411C14.3298 37.9424 21.3235 44.936 29.9248 44.936C38.5262 44.936 45.5198 37.9424 45.5198 29.3411C45.4394 20.7397 38.4458 13.7461 29.9248 13.7461ZM29.9248 42.6048C22.6096 42.6048 16.661 36.6562 16.661 29.3411C16.661 22.0259 22.6096 16.0773 29.9248 16.0773C37.24 16.0773 43.1886 22.0259 43.1886 29.3411C43.1886 36.6562 37.24 42.6048 29.9248 42.6048Z"
        fill="white"
      />
      <path
        d="M57.4975 27.3314L51.6292 21.7044V6.35057C51.6292 4.82323 50.3431 3.61743 48.8157 3.61743H17.5454C14.8122 3.61743 12.4007 5.38593 11.6772 7.9583L6.2109 27.4118C5.96974 28.3765 5.96974 29.5019 6.2109 30.4665L11.6772 49.8396C12.4007 52.412 14.8122 54.1805 17.5454 54.1805H48.8157C50.3431 54.1805 51.6292 52.9747 51.6292 51.4474V36.0936L57.5778 30.3861C58.3817 29.5823 58.3817 28.2157 57.4975 27.3314ZM41.9829 41.4794C38.7674 44.6949 34.4265 46.4634 29.8445 46.4634C25.2625 46.4634 21.002 44.6949 17.7062 41.4794C14.4907 38.264 12.7222 33.9231 12.7222 29.3411C12.7222 24.7591 14.4907 20.4986 17.7062 17.2027C20.9216 13.9873 25.2625 12.2188 29.8445 12.2188C34.4265 12.2188 38.687 13.9873 41.9829 17.2027C45.1983 20.4182 46.9668 24.7591 46.9668 29.3411C47.0472 33.9231 45.2787 38.264 41.9829 41.4794Z"
        fill="white"
      />
      <path
        d="M35.0695 29.3409C35.0695 29.7428 34.9891 30.1447 34.9087 30.5467L41.5808 29.6624C41.5808 29.582 41.5808 29.5016 41.5808 29.4213C41.5808 28.9389 41.5808 28.537 41.5004 28.0547H34.9087C34.9891 28.4566 35.0695 28.8586 35.0695 29.3409Z"
        fill="white"
      />
      <path
        d="M29.9248 17.6045C24.4585 17.6045 19.8765 21.3827 18.5903 26.447H25.6643C26.629 25.0804 28.1563 24.1962 29.9248 24.1962C31.6933 24.1962 33.3011 25.0804 34.1853 26.447H41.2593C39.9731 21.3827 35.3911 17.6045 29.9248 17.6045Z"
        fill="white"
      />
      <path
        d="M82.0152 36.0131H79.684L78.5585 33.28H72.208L71.163 36.0131H68.8318L74.2981 22.6689H76.5489L82.0152 36.0131ZM72.9315 31.4311H77.8351L75.4235 25.2413L72.9315 31.4311Z"
        fill="white"
      />
      <path
        d="M88.9284 26.6882H91.0988V36.0131H89.6518L89.2499 34.7269C88.6068 35.7719 87.5618 36.2542 86.1952 36.2542C84.9894 36.2542 84.0248 35.8523 83.3817 35.1288C82.7386 34.4053 82.3367 33.3603 82.3367 32.0741V26.6882H84.5071V31.833C84.5071 32.6368 84.6679 33.1995 85.0698 33.6819C85.4717 34.1642 85.9541 34.4053 86.6775 34.4053C87.401 34.4053 87.9637 34.1642 88.3657 33.6819C88.7676 33.1995 88.9284 32.7172 88.9284 32.1545V26.6882Z"
        fill="white"
      />
      <path
        d="M93.7518 26.6883V23.8748H95.9222V26.6883H98.4142V28.3764H95.9222V33.1192C95.9222 33.9231 96.3241 34.325 97.128 34.325C97.2888 34.325 97.5299 34.325 97.6907 34.2446C97.8515 34.1642 98.0123 34.1642 98.173 34.0838L98.3338 34.0034L98.9769 35.5308C98.3338 36.0131 97.6103 36.2543 96.7261 36.2543C95.7614 36.2543 95.038 36.0131 94.5556 35.4504C94.0733 34.8877 93.8322 34.1642 93.8322 33.1996V28.4568H92.144V26.7687H93.7518V26.6883Z"
        fill="white"
      />
      <path
        d="M107.337 34.8876C106.453 35.7718 105.247 36.2542 103.88 36.2542C102.514 36.2542 101.388 35.7718 100.504 34.8876C99.6198 34.0033 99.1375 32.7975 99.1375 31.3506C99.1375 29.9036 99.6198 28.6978 100.504 27.8136C101.388 26.9293 102.594 26.447 103.88 26.447C105.247 26.447 106.372 26.9293 107.256 27.8136C108.221 28.6978 108.623 29.9036 108.623 31.3506C108.703 32.7975 108.302 34.0033 107.337 34.8876ZM105.89 33.6014C106.372 33.0387 106.613 32.2348 106.613 31.3506C106.613 30.386 106.372 29.6625 105.81 29.0194C105.247 28.3763 104.684 28.1351 103.88 28.1351C103.076 28.1351 102.514 28.4567 101.951 29.0194C101.469 29.5821 101.147 30.386 101.147 31.3506C101.147 32.3152 101.388 33.0387 101.871 33.6014C102.353 34.1641 102.996 34.4857 103.8 34.4857C104.684 34.4857 105.327 34.1641 105.89 33.6014Z"
        fill="white"
      />
      <path
        d="M110.15 26.6884H111.597L111.839 28.0549H111.999C112.723 27.0099 113.687 26.5276 115.054 26.5276C116.581 26.5276 117.707 27.0903 118.35 28.2157H118.43C119.073 27.0903 120.199 26.5276 121.646 26.5276C122.851 26.5276 123.816 26.9295 124.459 27.653C125.102 28.3765 125.504 29.4215 125.504 30.7077V36.0936H123.334V30.9488C123.334 30.2254 123.173 29.5823 122.771 29.1C122.369 28.6176 121.887 28.3765 121.163 28.3765C120.52 28.3765 119.958 28.6176 119.556 29.0196C119.154 29.4215 118.993 29.9038 118.913 30.5469V36.0936H116.742V30.7077C116.742 30.0646 116.581 29.5019 116.179 29.0196C115.777 28.6176 115.215 28.3765 114.572 28.3765C113.929 28.3765 113.366 28.6176 112.964 29.0196C112.562 29.4215 112.401 29.9038 112.321 30.4665V36.0132H110.15V26.6884Z"
        fill="white"
      />
      <path
        d="M134.99 34.8876C134.105 35.7718 132.9 36.2542 131.533 36.2542C130.167 36.2542 129.041 35.7718 128.157 34.8876C127.273 34.0033 126.79 32.7975 126.79 31.3506C126.79 29.9036 127.273 28.6978 128.157 27.8136C129.041 26.9293 130.247 26.447 131.533 26.447C132.9 26.447 134.025 26.9293 134.909 27.8136C135.874 28.6978 136.276 29.9036 136.276 31.3506C136.356 32.7975 135.874 34.0033 134.99 34.8876ZM133.462 33.6014C133.945 33.0387 134.186 32.2348 134.186 31.3506C134.186 30.386 133.945 29.6625 133.382 29.0194C132.819 28.3763 132.257 28.1351 131.453 28.1351C130.649 28.1351 130.086 28.4567 129.523 29.0194C129.041 29.5821 128.72 30.386 128.72 31.3506C128.72 32.3152 128.961 33.0387 129.443 33.6014C129.925 34.1641 130.568 34.4857 131.372 34.4857C132.337 34.4857 132.98 34.1641 133.462 33.6014Z"
        fill="white"
      />
      <path
        d="M138.286 26.6883V23.8748H140.456V26.6883H142.948V28.3764H140.456V33.1192C140.456 33.9231 140.858 34.325 141.662 34.325C141.823 34.325 142.064 34.325 142.225 34.2446C142.385 34.1642 142.546 34.1642 142.707 34.0838L142.868 34.0034L143.511 35.5308C142.868 36.0131 142.144 36.2543 141.26 36.2543C140.295 36.2543 139.572 36.0131 139.09 35.4504C138.607 34.8877 138.366 34.1642 138.366 33.1996V28.4568H136.678V26.7687H138.286V26.6883Z"
        fill="white"
      />
      <path
        d="M144.475 24.5983C144.234 24.3571 144.073 24.0356 144.073 23.6337C144.073 23.2317 144.234 22.9906 144.475 22.669C144.717 22.4279 145.038 22.2671 145.44 22.2671C145.842 22.2671 146.164 22.4279 146.405 22.669C146.646 22.9102 146.807 23.2317 146.807 23.6337C146.807 24.0356 146.646 24.2768 146.405 24.5983C146.164 24.8395 145.842 25.0002 145.44 25.0002C145.038 24.9198 144.797 24.8395 144.475 24.5983ZM144.315 26.6883H146.485V36.0132H144.315V26.6883Z"
        fill="white"
      />
      <path
        d="M153.077 36.0131H151.549L147.289 26.6882H149.7L152.032 32.7172C152.112 32.9584 152.192 33.1995 152.192 33.4407H152.273C152.273 33.2799 152.353 33.0388 152.434 32.7172L154.765 26.6882H157.176L153.077 36.0131Z"
        fill="white"
      />
      <path
        d="M165.537 35.1288C165.456 35.2091 165.376 35.2895 165.215 35.4503C165.054 35.5307 164.652 35.7718 164.09 35.9326C163.527 36.1738 162.884 36.2542 162.16 36.2542C160.633 36.2542 159.508 35.7718 158.623 34.8876C157.739 34.0033 157.337 32.7975 157.337 31.3506C157.337 29.9036 157.739 28.7782 158.623 27.8136C159.427 26.849 160.553 26.447 161.919 26.447C163.286 26.447 164.331 26.849 165.054 27.7332C165.778 28.6175 166.18 29.7429 166.18 31.1094V31.6721H159.588C159.668 32.5564 159.91 33.2799 160.472 33.7622C160.955 34.2445 161.678 34.4857 162.482 34.4857C162.964 34.4857 163.366 34.4053 163.848 34.3249C164.25 34.1641 164.572 34.0837 164.733 33.923L165.054 33.7622L165.537 35.1288ZM159.427 30.2252H163.768C163.607 28.8586 162.884 28.1351 161.678 28.1351C160.392 28.1351 159.668 28.8586 159.427 30.2252Z"
        fill="white"
      />
      <path
        d="M172.048 32.9584C172.128 33.0388 172.369 33.1995 172.53 33.2799C172.771 33.4407 173.173 33.6015 173.816 33.8426C174.459 34.0838 175.103 34.1642 175.746 34.1642C176.63 34.1642 177.273 34.0034 177.675 33.6818C178.077 33.3603 178.318 32.878 178.318 32.3153C178.318 31.833 178.077 31.3506 177.675 31.0291C177.273 30.7075 176.469 30.386 175.263 29.9841C173.736 29.5018 172.611 29.0194 171.887 28.3763C171.164 27.8136 170.842 27.0098 170.842 25.9647C170.842 24.759 171.244 23.8747 172.128 23.1512C173.013 22.5081 174.218 22.1062 175.746 22.1062C176.71 22.1062 177.595 22.1866 178.398 22.4277C179.202 22.6689 179.685 22.9101 180.006 23.0708L180.408 23.3924L179.202 25.1609C179.122 25.0805 178.961 25.0001 178.8 24.8393C178.64 24.759 178.238 24.5178 177.595 24.357C177.032 24.1159 176.389 24.0355 175.746 24.0355C174.942 24.0355 174.379 24.1962 173.897 24.4374C173.495 24.759 173.254 25.1609 173.254 25.6432C173.254 26.1255 173.495 26.4471 173.897 26.7686C174.299 27.0902 175.103 27.3313 176.228 27.7333C177.836 28.2156 178.961 28.7783 179.685 29.341C180.408 29.9841 180.73 30.7879 180.73 31.833C180.73 33.0388 180.328 34.0034 179.443 34.7269C178.559 35.4503 177.353 35.8523 175.746 35.8523C175.103 35.8523 174.46 35.7719 173.897 35.6915C173.334 35.6111 172.852 35.4504 172.45 35.2896C172.048 35.1288 171.726 34.968 171.485 34.8073C171.244 34.6465 171.003 34.4857 170.923 34.4053L170.762 34.2446L172.048 32.9584Z"
        fill="white"
      />
      <path
        d="M188.608 26.6882H190.778V36.0131H189.331L188.929 34.7269H188.849C188.206 35.7719 187.161 36.2542 185.794 36.2542C184.588 36.2542 183.624 35.8523 182.981 35.1288C182.337 34.4053 181.936 33.3603 181.936 32.0741V26.6882H184.106V31.833C184.106 32.6368 184.267 33.1995 184.669 33.6819C185.071 34.1642 185.553 34.4053 186.276 34.4053C187 34.4053 187.563 34.1642 187.965 33.6819C188.366 33.1995 188.527 32.7172 188.527 32.1545V26.6882H188.608Z"
        fill="white"
      />
      <path
        d="M192.868 24.5983C192.627 24.3571 192.466 24.0356 192.466 23.6337C192.466 23.2317 192.627 22.9906 192.868 22.669C193.109 22.4279 193.431 22.2671 193.833 22.2671C194.235 22.2671 194.556 22.4279 194.797 22.669C195.038 22.9102 195.199 23.2317 195.199 23.6337C195.199 24.0356 195.038 24.2768 194.797 24.5983C194.556 24.8395 194.235 25.0002 193.833 25.0002C193.431 24.9198 193.19 24.8395 192.868 24.5983ZM192.788 26.6883H194.958V36.0132H192.788V26.6883Z"
        fill="white"
      />
      <path
        d="M197.611 26.6883V23.8748H199.781V26.6883H202.273V28.3764H199.781V33.1192C199.781 33.9231 200.183 34.325 200.987 34.325C201.148 34.325 201.389 34.325 201.55 34.2446C201.711 34.1642 201.871 34.1642 202.032 34.0838L202.193 34.0034L202.836 35.5308C202.193 36.0131 201.469 36.2543 200.585 36.2543C199.621 36.2543 198.897 36.0131 198.415 35.4504C197.932 34.8877 197.691 34.1642 197.691 33.1996V28.4568H196.003V26.7687H197.611V26.6883Z"
        fill="white"
      />
      <path
        d="M211.357 35.1288C211.276 35.2091 211.196 35.2895 211.035 35.4503C210.875 35.5307 210.473 35.7718 209.91 35.9326C209.347 36.1738 208.704 36.2542 207.981 36.2542C206.453 36.2542 205.328 35.7718 204.444 34.8876C203.559 34.0033 203.157 32.7975 203.157 31.3506C203.157 29.9036 203.559 28.7782 204.444 27.8136C205.248 26.849 206.373 26.447 207.739 26.447C209.106 26.447 210.151 26.849 210.875 27.7332C211.598 28.6175 212 29.7429 212 31.1094V31.6721H205.408C205.489 32.5564 205.73 33.2799 206.293 33.7622C206.775 34.2445 207.498 34.4857 208.302 34.4857C208.785 34.4857 209.186 34.4053 209.669 34.3249C210.071 34.1641 210.392 34.0837 210.553 33.923L210.875 33.7622L211.357 35.1288ZM205.248 30.2252H209.588C209.428 28.8586 208.704 28.1351 207.498 28.1351C206.212 28.1351 205.489 28.8586 205.248 30.2252Z"
        fill="white"
      />
      <path
        d="M130.81 41.7207C130.97 41.5599 130.97 41.3188 130.97 41.0776C130.97 40.6757 130.81 40.3541 130.569 40.1934C130.327 39.9522 129.925 39.8718 129.443 39.8718H127.594V44.3735H129.604C130.086 44.3735 130.488 44.2931 130.729 44.0519C130.97 43.8108 131.131 43.5696 131.131 43.0873C131.131 42.8461 131.051 42.605 130.89 42.4442C130.729 42.2834 130.569 42.1226 130.327 42.0423C130.488 41.9619 130.649 41.8815 130.81 41.7207ZM130.327 42.5246C130.488 42.6854 130.569 42.8461 130.569 43.0873C130.569 43.3284 130.488 43.5696 130.247 43.65C130.086 43.8108 129.845 43.8108 129.524 43.8108H128.077V42.2834H129.524C129.845 42.3638 130.086 42.4442 130.327 42.5246ZM130.167 41.6403C130.006 41.8011 129.765 41.8011 129.443 41.8011H128.077V40.3541H129.443C130.086 40.3541 130.408 40.5953 130.408 41.0776C130.488 41.3188 130.327 41.5599 130.167 41.6403Z"
        fill="white"
      />
      <path
        d="M155.167 43.9713C154.926 43.9713 154.685 43.891 154.444 43.891C154.202 43.8106 154.042 43.7302 154.042 43.7302C153.961 43.6498 153.961 43.6498 153.881 43.6498L153.64 44.0517L153.72 44.1321C153.72 44.2125 153.8 44.2125 153.881 44.2929C153.961 44.3733 154.042 44.3733 154.202 44.4537C154.283 44.534 154.444 44.534 154.685 44.6144C154.845 44.6948 155.087 44.6948 155.247 44.6948C155.73 44.6948 156.132 44.6144 156.453 44.3733C156.694 44.1321 156.855 43.8106 156.855 43.489C156.855 43.1675 156.775 42.8459 156.534 42.6852C156.292 42.444 155.971 42.2832 155.408 42.1225C154.926 41.9617 154.604 41.8813 154.444 41.7205C154.283 41.6401 154.202 41.4794 154.202 41.2382C154.202 40.997 154.283 40.8363 154.524 40.7559C154.765 40.6755 155.006 40.5951 155.247 40.5951C155.489 40.5951 155.73 40.5951 155.89 40.6755C156.132 40.7559 156.212 40.8363 156.292 40.8363C156.373 40.9167 156.373 40.9167 156.453 40.9167L156.694 40.5147L156.614 40.4343C156.614 40.4343 156.534 40.354 156.453 40.354C156.373 40.2736 156.292 40.2736 156.212 40.1932C156.132 40.1128 155.971 40.1128 155.81 40.1128C155.649 40.1128 155.489 40.1128 155.247 40.1128C154.765 40.1128 154.363 40.1932 154.122 40.4343C153.881 40.6755 153.72 40.9167 153.72 41.3186C153.72 41.6401 153.8 41.8813 154.042 42.1225C154.283 42.2832 154.685 42.5244 155.167 42.6852C155.649 42.8459 155.971 42.9263 156.132 43.0871C156.292 43.2479 156.373 43.4086 156.373 43.5694C156.373 43.8106 156.292 43.9713 156.132 44.1321C155.89 43.891 155.569 43.9713 155.167 43.9713Z"
        fill="white"
      />
      <path
        d="M132.417 39.8718V42.4442C132.417 43.0069 132.578 43.4892 132.9 43.8911C133.221 44.2931 133.623 44.4539 134.186 44.4539C134.749 44.4539 135.231 44.2931 135.472 43.8911C135.794 43.5696 135.954 43.0873 135.954 42.4442V39.8718H135.472V42.4442C135.472 42.9265 135.392 43.2481 135.151 43.4892C134.909 43.7304 134.588 43.8911 134.186 43.8911C133.784 43.8911 133.463 43.7304 133.221 43.4892C132.98 43.2481 132.9 42.8461 132.9 42.4442V39.8718H132.417Z"
        fill="white"
      />
      <path
        d="M144.395 39.8718H144.073V44.3735H144.556V40.8365L147.369 44.3735H147.771V39.8718H147.289V43.4088L144.395 39.8718Z"
        fill="white"
      />
      <path
        d="M152.594 40.3541V39.8718H149.459V44.3735H152.594V43.8911H149.942V42.3638H152.192V41.8815H149.942V40.3541H152.594Z"
        fill="white"
      />
      <path
        d="M121.083 44.3735V42.9265H122.449L123.575 44.3735H124.138V44.2127L123.012 42.7657C123.334 42.6854 123.575 42.5246 123.736 42.2834C123.896 42.0423 123.977 41.7207 123.977 41.3992C123.977 40.9169 123.816 40.5149 123.495 40.2738C123.173 40.0326 122.771 39.8718 122.208 39.8718H120.601V44.2931H121.083V44.3735ZM121.083 40.3541H122.289C122.691 40.3541 122.932 40.4345 123.173 40.5953C123.414 40.7561 123.495 40.9972 123.495 41.3992C123.495 41.7207 123.414 41.9619 123.173 42.1226C122.932 42.2834 122.691 42.3638 122.369 42.3638H121.083V40.3541Z"
        fill="white"
      />
      <path
        d="M138.929 43.9713C138.688 43.9713 138.446 43.891 138.205 43.891C137.964 43.8106 137.803 43.7302 137.803 43.7302C137.723 43.6498 137.723 43.6498 137.643 43.6498L137.401 44.0517L137.482 44.1321C137.562 44.2125 137.562 44.2125 137.643 44.2929C137.723 44.3733 137.803 44.3733 137.964 44.4537C138.044 44.534 138.205 44.534 138.446 44.6144C138.607 44.6144 138.848 44.6948 139.009 44.6948C139.491 44.6948 139.893 44.6144 140.215 44.3733C140.456 44.1321 140.617 43.8106 140.617 43.489C140.617 43.1675 140.536 42.8459 140.295 42.6852C140.054 42.444 139.733 42.2832 139.17 42.1225C138.688 41.9617 138.446 41.8813 138.286 41.7205C138.125 41.6401 138.044 41.4794 138.044 41.2382C138.044 40.997 138.125 40.8363 138.366 40.7559C138.527 40.5951 138.848 40.5951 139.089 40.5951C139.331 40.5951 139.572 40.5951 139.733 40.6755C139.974 40.7559 140.054 40.8363 140.135 40.8363C140.215 40.9167 140.215 40.9167 140.295 40.9167L140.536 40.5147L140.456 40.4343C140.456 40.4343 140.376 40.354 140.295 40.354C140.215 40.2736 140.135 40.2736 140.054 40.1932C139.974 40.1128 139.813 40.1128 139.652 40.1128C139.491 40.1128 139.25 40.1128 139.089 40.1128C138.607 40.1128 138.205 40.1932 137.884 40.4343C137.643 40.6755 137.482 40.9167 137.482 41.3186C137.482 41.6401 137.562 41.8813 137.803 42.1225C138.044 42.2832 138.446 42.5244 138.929 42.6852C139.411 42.8459 139.733 42.9263 139.893 43.0871C140.054 43.2479 140.135 43.4086 140.135 43.5694C140.135 43.8106 140.054 43.9713 139.893 44.1321C139.572 43.891 139.25 43.9713 138.929 43.9713Z"
        fill="white"
      />
      <path
        d="M142.385 39.8718H141.903V44.3735H142.385V39.8718Z"
        fill="white"
      />
      <path
        d="M107.417 44.3735V42.6854L109.105 39.8718H108.462L107.337 41.8815L107.176 42.203L107.096 41.8815L105.89 39.8718H105.247L106.935 42.6854V44.3735H107.417Z"
        fill="white"
      />
      <path
        d="M161.035 43.1676C161.035 42.846 160.955 42.6048 160.713 42.3637C160.472 42.1225 160.151 41.9618 159.588 41.801C159.106 41.6402 158.865 41.5598 158.704 41.3991C158.543 41.3187 158.463 41.1579 158.463 40.9167C158.463 40.6756 158.543 40.5148 158.784 40.4344C158.945 40.2736 159.266 40.2736 159.508 40.2736C159.749 40.2736 159.99 40.2736 160.151 40.354C160.392 40.4344 160.472 40.5148 160.553 40.5148C160.633 40.5952 160.633 40.5952 160.713 40.5952L160.955 40.1933L160.874 40.1129C160.874 40.1129 160.794 40.0325 160.713 40.0325C160.633 39.9521 160.553 39.9521 160.472 39.8717C160.392 39.7913 160.231 39.7913 160.07 39.7913C159.91 39.7913 159.749 39.7109 159.508 39.7109C159.025 39.7109 158.623 39.7913 158.302 40.0325C158.061 40.2736 157.9 40.5148 157.9 40.9167C157.9 41.2383 157.98 41.4794 158.221 41.7206C158.463 41.8814 158.865 42.1225 159.347 42.2833C159.829 42.4441 160.151 42.5245 160.311 42.6852C160.472 42.846 160.553 43.0068 160.553 43.1676C160.553 43.4087 160.472 43.5695 160.311 43.7303C160.151 43.891 159.829 43.9714 159.508 43.9714C159.266 43.9714 159.025 43.891 158.784 43.891C158.543 43.8106 158.382 43.7303 158.382 43.7303C158.302 43.6499 158.302 43.6499 158.221 43.6499L157.98 44.0518L158.061 44.1322C158.061 44.2126 158.141 44.2126 158.221 44.293C158.302 44.3733 158.382 44.3733 158.543 44.4537C158.623 44.5341 158.784 44.5341 159.025 44.6145C159.186 44.6949 159.427 44.6949 159.588 44.6949C160.07 44.6949 160.472 44.6145 160.794 44.3734C161.035 44.1322 161.196 43.891 161.196 43.5695V43.1676H161.035Z"
        fill="white"
      />
      <path
        d="M71.806 44.3735L72.7706 40.6757L73.8156 44.3735H74.2176L75.6645 39.8718H75.1822L73.9764 43.5696L73.0118 39.8718H72.5295L71.5648 43.5696L70.4394 39.8718H69.8767L71.4041 44.3735H71.806Z"
        fill="white"
      />
      <path
        d="M102.594 40.3541V39.8718H99.459V44.3735H102.594V43.8911H99.9413V42.3638H102.192V41.8815H99.9413V40.3541H102.594Z"
        fill="white"
      />
      <path
        d="M115.375 39.8718V42.4442C115.375 43.0069 115.536 43.4892 115.858 43.8911C116.179 44.2931 116.581 44.4539 117.144 44.4539C117.707 44.4539 118.189 44.2931 118.43 43.8911C118.752 43.5696 118.912 43.0873 118.912 42.4442V39.8718H118.43V42.4442C118.43 42.9265 118.35 43.2481 118.109 43.4892C117.867 43.7304 117.546 43.8911 117.144 43.8911C116.742 43.8911 116.421 43.7304 116.179 43.4892C115.938 43.2481 115.858 42.8461 115.858 42.4442V39.8718H115.375Z"
        fill="white"
      />
      <path
        d="M94.5556 39.8718H94.0732L96.0025 44.3735H96.4045L98.3337 39.8718H97.8514L96.1633 43.65L94.5556 39.8718Z"
        fill="white"
      />
      <path
        d="M110.311 40.4344C109.909 40.9167 109.749 41.4794 109.749 42.1225C109.749 42.7656 109.99 43.3283 110.392 43.8106C110.794 44.2929 111.356 44.4537 111.999 44.4537C112.642 44.4537 113.205 44.2125 113.607 43.8106C114.009 43.3283 114.17 42.8459 114.17 42.1225C114.17 41.4794 114.009 40.9167 113.607 40.4344C113.205 39.952 112.642 39.7913 111.999 39.7913C111.195 39.7913 110.713 40.0324 110.311 40.4344ZM113.607 42.1225C113.607 42.6048 113.446 43.0871 113.125 43.4086C112.803 43.7302 112.401 43.9714 111.919 43.9714C111.437 43.9714 111.035 43.8106 110.713 43.4086C110.392 43.0871 110.231 42.6048 110.231 42.1225C110.231 41.6401 110.392 41.1578 110.713 40.8363C111.035 40.5147 111.437 40.2736 111.919 40.2736C112.401 40.2736 112.803 40.4344 113.125 40.8363C113.446 41.1578 113.607 41.5598 113.607 42.1225Z"
        fill="white"
      />
      <path
        d="M80.0057 40.3541V39.8718H76.8706V44.3735H80.0057V43.8911H77.3529V42.3638H79.6037V41.8815H77.3529V40.3541H80.0057Z"
        fill="white"
      />
      <path
        d="M87.5619 44.3735V39.8718H87.2403L85.5522 42.0423L83.7837 39.8718H83.4622V44.3735H83.9445V40.9169L85.4718 42.7657H85.5522L87.0795 40.8365V44.3735H87.5619Z"
        fill="white"
      />
      <path
        d="M89.5716 40.4344C89.1696 40.9167 88.9285 41.4794 88.9285 42.1225C88.9285 42.7656 89.1696 43.3283 89.5716 43.8106C89.9735 44.2929 90.5362 44.4537 91.1793 44.4537C91.8224 44.4537 92.3851 44.2125 92.787 43.8106C93.189 43.3283 93.4301 42.8459 93.4301 42.1225C93.4301 41.4794 93.189 40.9167 92.787 40.4344C92.3851 39.952 91.8224 39.7913 91.1793 39.7913C90.5362 39.7913 89.9735 40.0324 89.5716 40.4344ZM92.3851 40.8363C92.7066 41.1578 92.8674 41.6401 92.8674 42.1225C92.8674 42.6048 92.7066 43.0871 92.3851 43.4086C92.0635 43.7302 91.6616 43.9714 91.1793 43.9714C90.697 43.9714 90.295 43.8106 89.9735 43.4086C89.6519 43.0871 89.4912 42.6048 89.4912 42.1225C89.4912 41.6401 89.6519 41.1578 89.9735 40.8363C90.295 40.5147 90.697 40.2736 91.1793 40.2736C91.6616 40.2736 92.0635 40.4344 92.3851 40.8363Z"
        fill="white"
      />
    </svg>
  );
};

AutomotrizSuite.propTypes = {
  className: PropTypes.string,
};

export default AutomotrizSuite;
