import { createContext, useState } from "react";
import { PropTypes } from "prop-types";

const categoriesContext = createContext(null);
const { Provider } = categoriesContext;

const CategoriesProviders = ({ children }) => {
  const [categories, setCategories] = useState({ apps: [] });

  return (
    <Provider
      value={{
        categories,
        setCategories: (categories) => setCategories(categories),
      }}
    >
      {children}
    </Provider>
  );
};

CategoriesProviders.propTypes = {
  children: PropTypes.object,
};

export { categoriesContext, CategoriesProviders };
