import { PropTypes } from "prop-types";

const SimStatements = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="525.000000pt"
      height="526.000000pt"
      viewBox="0 0 525.000000 526.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,526.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2420 5243 c-895 -69 -1698 -602 -2119 -1403 -217 -414 -315 -873
-290 -1357 29 -568 243 -1108 612 -1548 350 -416 850 -725 1380 -854 237 -58
316 -66 622 -66 314 0 402 10 648 71 657 165 1238 593 1590 1170 498 818 515
1843 45 2667 -422 739 -1161 1223 -2006 1312 -123 13 -363 17 -482 8z m1905
-1105 c-44 -100 -1384 -2950 -1388 -2954 -2 -2 -139 -3 -304 -2 l-300 3 89
190 c49 105 366 781 705 1503 l616 1312 303 0 303 0 -24 -52z m-1943 -1836
c103 -213 195 -404 204 -424 l17 -37 -154 -320 c-85 -176 -157 -317 -161 -313
-7 7 -698 1469 -698 1477 0 3 136 5 303 5 l302 0 187 -388z m-770 241 c31 -65
101 -213 157 -328 l102 -210 -189 -420 -190 -420 -293 0 -294 0 1 25 c0 26
634 1470 645 1470 3 0 31 -53 61 -117z"
        />
      </g>
    </svg>
  );
};

SimStatements.propTypes = {
  className: PropTypes.string,
};

export default SimStatements;
