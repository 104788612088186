import { useContext, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FetchContext } from "../context/FetchContext";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

const ChangePasswordForm = () => {
  const history = useHistory();
  const { authAxios } = useContext(FetchContext);

  const [error, setError] = useState(false);
  const [responseError, setResponseError] = useState("");

  const [success, setSuccess] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState("");

  const changeSchema = Yup.object().shape({
    password: Yup.string().required("El campo es obligatorio"),
    passwordConfirmation: Yup.string()
      .required("El campo es obligatorio")
      .oneOf([Yup.ref("password"), null], "Los passwords deben coincidir"),
  });

  const handleSubmitChangePassword = async ({ password }, { resetForm }) => {
    try {
      setError(false);
      setSuccess(false);
      setResponseSuccess("");
      setResponseError("");
      const { data } = await authAxios.patch("/profile/password/", {
        password,
      });
      setSuccess(true);
      setResponseSuccess(data.message);
      resetForm({});
    } catch (err) {
      const { data } = err.response;
      setError(true);
      if (data.statusCode === 401 && data.message === "Unauthorized") {
        toast.error("La sesión ha expirado");
        setResponseError("La sesión ha expirado");
        setTimeout(() => {
          history.push("/");
        }, 1500);
      } else {
        setResponseError(data.message);
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={{ password: "", passwordConfirmation: "" }}
        validationSchema={changeSchema}
        onSubmit={handleSubmitChangePassword}
      >
        {({ isSubmitting }) => (
          <Form className="space-y-6" action="#" method="POST">
            <div className="grid grid-cols-6 gap-6 pt-8 pb-8">
              <div className="col-span-6 mt-5 sm:col-span-6 md:mt-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Cambio de contraseña
                </h3>
              </div>

              <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Contraseña
                </label>
                <Field
                  id="password"
                  type="password"
                  name="password"
                  autoComplete="current-password"
                  placeholder="******"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <ErrorMessage
                  name="password"
                  render={(msg) => (
                    <div className="text-sm p-2 text-red-600 font-medium">
                      {msg}
                    </div>
                  )}
                />
              </div>

              <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                <label
                  htmlFor="passwordConfirmation"
                  className="block text-sm font-medium text-gray-700"
                >
                  Repetir contraseña
                </label>
                <Field
                  id="passwordConfirmation"
                  type="password"
                  name="passwordConfirmation"
                  autoComplete="current-password"
                  placeholder="******"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <ErrorMessage
                  name="passwordConfirmation"
                  render={(msg) => (
                    <div className="text-sm p-2 text-red-600 font-medium">
                      {msg}
                    </div>
                  )}
                />
              </div>

              {error && (
                <div className="col-span-6 text-sm p-2 text-red-600 font-medium">
                  {responseError}
                </div>
              )}

              {success && (
                <div className="col-span-6 text-sm p-2 text-green-600 font-medium">
                  {responseSuccess}
                </div>
              )}

              <div className="col-span-6 mt-5 sm:col-span-6 md:mt-0">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-simblue-900 py-4 px-8 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Guardar contraseña
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChangePasswordForm;
