import React, { useState } from "react";
import Chart from "react-apexcharts";
import { PropTypes } from "prop-types";

const LinealGrafica = ({ categorias, valor }) => {
  const [data] = useState([
    {
      options: {
        chart: {
          id: "basic-bar",
          type: "area",
          stacked: true,
          width: 800,
          height: 380,
          toolbar: {
            show: true,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "80%",
          },
        },
        yaxis: [
          {
            y: 30,
            borderColor: "#999",
            label: {
              show: true,
              text: "Support",
              style: {
                color: "#fff",
                background: "#00E396",
              },
            },
          },
        ],
        xaxis: {
          type: "date",
          categories: categorias,
        },
        responsive: [
          {
            breakpoint: 100,
            options: {
              plotOptions: {
                bar: {
                  horizontal: true,
                },
              },
            },
          },
        ],
      },
      series: [
        {
          name: "Accesos",
          data: valor,
        },
      ],
    },
  ]);

  return (
    <Chart options={data[0].options} series={data[0].series} width={700} />
  );
};

LinealGrafica.propTypes = {
  categorias: PropTypes.array,
  valor: PropTypes.array,
};

export default LinealGrafica;
