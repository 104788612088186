import { PropTypes } from "prop-types";

const DBU = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="526.000000pt"
      height="525.000000pt"
      viewBox="0 0 526.000000 525.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,525.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2431 5240 c-825 -69 -1552 -501 -1998 -1186 -296 -454 -438 -966
-420 -1513 14 -420 107 -776 298 -1140 340 -648 955 -1135 1665 -1317 438
-112 915 -108 1349 11 853 234 1535 888 1804 1729 136 426 159 910 65 1357
-78 374 -280 794 -525 1095 -378 464 -920 796 -1496 914 -226 47 -533 67 -742
50z m265 -835 c286 -30 508 -86 684 -172 200 -97 290 -215 263 -345 -9 -45 -8
-57 11 -97 20 -43 21 -62 21 -316 l0 -270 -27 -58 c-26 -55 -27 -60 -12 -88 8
-17 20 -32 27 -33 7 -2 60 -15 119 -29 185 -44 353 -136 495 -270 98 -93 163
-180 223 -300 87 -171 120 -312 120 -507 0 -195 -53 -386 -155 -560 -69 -116
-248 -298 -364 -368 -327 -199 -713 -221 -1049 -62 -99 47 -163 89 -257 167
l-70 59 -60 -10 c-94 -15 -585 -13 -695 2 -497 70 -844 240 -957 469 l-28 58
0 270 0 270 27 57 26 56 -26 54 -27 53 0 270 0 270 27 58 27 57 -27 58 c-27
56 -27 59 -30 308 -3 237 -2 254 18 310 12 32 21 70 21 84 -7 123 -5 135 24
189 103 188 472 327 990 374 119 11 529 6 661 -8z"
        />
        <path
          d="M2070 4224 c-19 -2 -80 -9 -135 -15 -385 -40 -725 -174 -725 -284 0
-87 220 -195 515 -254 235 -47 652 -66 920 -41 318 30 577 99 721 193 66 42
99 86 89 116 -3 11 -24 35 -45 55 -99 89 -310 161 -612 207 -104 16 -194 21
-413 24 -154 2 -296 1 -315 -1z"
        />
        <path
          d="M1170 3407 c0 -135 2 -146 26 -196 64 -129 309 -252 624 -315 167
-33 310 -46 510 -46 200 0 343 13 510 46 292 58 531 172 615 294 30 43 30 44
33 202 l3 158 -73 -40 c-239 -128 -636 -203 -1088 -203 -453 0 -854 75 -1087
204 l-73 40 0 -144z"
        />
        <path
          d="M1170 2660 c0 -209 11 -237 127 -327 131 -102 375 -187 654 -228 57
-9 188 -19 292 -22 l187 -6 0 22 c0 72 71 273 134 381 l36 61 -33 -5 c-140
-22 -463 -12 -672 20 -265 41 -522 128 -665 226 -19 13 -41 27 -47 32 -10 6
-13 -28 -13 -154z"
        />
        <path
          d="M3345 2746 c-353 -80 -614 -370 -654 -724 -46 -416 217 -796 624
-903 113 -30 298 -30 410 0 314 84 547 329 611 641 23 115 15 306 -16 405 -75
236 -232 416 -453 520 -40 19 -99 42 -132 51 -81 22 -309 28 -390 10z m343
-267 c136 -44 277 -162 337 -282 19 -38 35 -78 35 -89 0 -27 -45 -68 -75 -68
-43 0 -63 17 -96 82 -122 242 -445 300 -653 117 -40 -35 -106 -129 -106 -150
0 -4 20 -12 44 -18 49 -12 69 -43 45 -66 -31 -30 -195 -125 -215 -125 -17 0
-35 21 -82 98 -32 53 -62 102 -66 109 -4 7 2 21 13 32 17 17 25 18 61 10 24
-5 43 -8 44 -7 108 261 339 404 609 376 38 -4 85 -12 105 -19z m419 -644 c34
-55 62 -107 62 -116 2 -29 -25 -42 -73 -35 l-44 7 -33 -61 c-42 -79 -156 -188
-240 -229 -169 -82 -349 -81 -518 2 -91 44 -207 160 -250 249 -30 61 -32 69
-19 94 7 16 25 35 40 43 43 25 77 6 116 -64 46 -82 115 -150 191 -188 54 -27
78 -32 156 -35 114 -5 178 12 264 69 52 35 141 135 141 159 0 4 -19 11 -41 16
-45 10 -66 31 -56 57 8 20 207 138 227 135 8 -2 43 -48 77 -103z"
        />
        <path
          d="M1170 1899 c0 -194 8 -217 93 -295 206 -188 730 -310 1208 -279 l117
7 -44 81 c-46 87 -98 234 -109 309 l-7 45 -206 6 c-431 11 -765 100 -1024 271
l-28 18 0 -163z"
        />
      </g>
    </svg>
  );
};

DBU.propTypes = {
  className: PropTypes.string,
};

export default DBU;
