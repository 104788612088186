import { useEffect, useContext } from "react";
import { useHistory } from "react-router";
import { FetchContext } from "../context/FetchContext";
import { AuthContext } from "../context/AuthContext";

const Session = () => {
  const { authAxios } = useContext(FetchContext);
  const { setAuthState } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    const getSession = async () => {
      try {
        const { data } = await authAxios.get("auth/me");
        setAuthState(data);
        history.push("/home");
      } catch (err) {
        console.log(err);
      }
    };

    getSession();
  }, []);
  return <></>;
};

export default Session;
