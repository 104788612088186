import { createContext, useState } from "react";
import { PropTypes } from "prop-types";

const AvatarContext = createContext(null);
const { Provider } = AvatarContext;

const AvatarProvider = ({ children }) => {
  const base64Avatar = localStorage.getItem("avatar");
  const [avatar, setAvatar] = useState(base64Avatar);

  const setAvatarBase64 = (avatarBase64) => {
    localStorage.setItem("avatar", avatarBase64);
    setAvatar(avatarBase64);
  };

  return (
    <Provider
      value={{
        avatar,
        setAvatar: (avatarBase64) => setAvatarBase64(avatarBase64),
      }}
    >
      {children}
    </Provider>
  );
};

AvatarProvider.propTypes = {
  children: PropTypes.object,
};

export { AvatarContext, AvatarProvider };
