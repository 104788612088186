import { PropTypes } from "prop-types";

function TableHeader({ headers }) {
  return (
    <thead className="bg-gray-50">
      <tr>
        {headers.map((header, index) => (
          <th
            key={index}
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
          >
            {header}
          </th>
        ))}

        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
          <span className="sr-only">Editar</span>
        </th>
        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
          <span className="sr-only">Eliminar</span>
        </th>
      </tr>
    </thead>
  );
}

TableHeader.propTypes = {
  headers: PropTypes.array,
};

export default TableHeader;
