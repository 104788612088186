import { useEffect } from "react";
import PropTypes from "prop-types";

export default function useZendesk(key, options, onLoaded) {
  const onScriptLoaded = () => {
    if (typeof onLoaded === "function") {
      onLoaded();
    }
    ZendeskWidget("webWidget:on", "close", () =>
      ZendeskWidget("webWidget", "hide")
    );
  };

  useEffect(() => {
    const zendeskInitScript = document.createElement("script");
    zendeskInitScript.id = "ze-snippet";
    zendeskInitScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
    zendeskInitScript.async = true;
    zendeskInitScript.addEventListener("load", onScriptLoaded);
    document.body.appendChild(zendeskInitScript);
    window.zESettings = { ...options };
    return () => {
      document.body.removeChild(zendeskInitScript);
    };
  }, []);
}

const canUseDOM = () => {
  return !(
    typeof window === "undefined" ||
    !window.document ||
    !window.document.createElement
  );
};

export const ZendeskWidget = (...args) => {
  if (canUseDOM && window.zE) {
    window.zE.apply(null, args);
  } else {
    console.warn("Zendesk has not finished booting");
  }
};

useZendesk.propTypes = {
  key: PropTypes.string,
  options: PropTypes.object,
};
