import React, { useState } from "react";
import Chart from "react-apexcharts";
import { PropTypes } from "prop-types";
const RadialGrafica = ({ color, valor }) => {
  const [radial] = useState([
    {
      series: [valor],
      options: {
        colors: [color],
        chart: {
          type: "radialBar",
          offsetY: 0,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#e7e7e7",
              strokeWidth: "97%",
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: "#e7e7e7",
                opacity: 1,
                blur: 2,
              },
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: "18px",
              },
            },
          },
        },
        grid: {
          padding: {
            top: -10,
          },
        },
      },
    },
  ]);
  return (
    <Chart
      options={radial[0].options}
      series={radial[0].series}
      type="radialBar"
    />
  );
};

RadialGrafica.propTypes = {
  valor: PropTypes.string,
  color: PropTypes.string,
};

export default RadialGrafica;
