import { Link } from "react-router-dom";
import simtech from "../assets/svg/simtech.svg";
import simetrical from "../assets/svg/simetrical.svg";

function FooterLogos() {
  return (
    <div className="mt-12 md:mt-0 w-full pb-4 px-4 space-x-6 static  bottom-0 flex justify-center md:absolute md:justify-end">
      <Link
        to="/"
        className="font-medium text-indigo-600 hover:text-indigo-500"
      >
        <img className="h-5 w-auto" src={simtech} alt="simtech logo" />
      </Link>
      <Link
        to="/"
        className="font-medium text-indigo-600 hover:text-indigo-500"
      >
        <img className="h-6 w-auto" src={simetrical} alt="simetrical logo" />
      </Link>
    </div>
  );
}

export default FooterLogos;
