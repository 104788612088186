import React, { createContext, useState } from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { ZendeskWidget } from "../hooks/useZendesk";

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const history = useHistory();

  // local storage load
  const username = localStorage.getItem("username");
  const profile = localStorage.getItem("profile");
  const expiresAt = localStorage.getItem("expiresAt");

  // init states
  const [authState, setAuthState] = useState({
    profile: profile ? JSON.parse(profile) : {},
    username: username || null,
    expiresAt: expiresAt || null,
  });

  // set data
  const setAuthInfo = ({ profile, username, expiresAt }) => {
    ZendeskWidget("webWidget", "close");
    localStorage.setItem("username", username);
    localStorage.setItem("profile", JSON.stringify(profile));
    localStorage.setItem("expiresAt", expiresAt);
    setAuthState({ profile, username, expiresAt });
  };

  const isAuthenticated = () => {
    if (Object.keys(authState.profile).length === 0) {
      return false;
    }

    if (!authState.expiresAt) {
      return false;
    }

    return new Date().getTime() / 1000 < authState.expiresAt;
  };

  const logout = () => {
    ZendeskWidget("webWidget", "close");
    localStorage.removeItem("profile");
    localStorage.removeItem("username");
    localStorage.removeItem("expiresAt");
    localStorage.removeItem("avatar");
    localStorage.removeItem("usageResumen");
    localStorage.removeItem("aplicacionSelected");
    localStorage.removeItem("fechaSelected");
    setAuthState({
      username: null,
      profile: {},
      expiresAt: null,
    });
    history.push("/");
    // window.location.href = `${process.env.REACT_APP_SSO_URI}/session/end`;
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState: (authInfo) => setAuthInfo(authInfo),
        isAuthenticated,
        logout,
      }}
    >
      {children}
    </Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.object,
};

export { AuthContext, AuthProvider };
