import React from "react";
import { PropTypes } from "prop-types";
// Styles
import "./tableFilter.css";

// Ant-design
import { FilterIcon } from "@heroicons/react/outline";
import { Button, Checkbox, Dropdown, Menu } from "antd";

/**
 * Componente Table Filter
 */
export class TableFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownVisible: false,
      seleccionarTodos: false,
      filtrando: false,
      filtros: [],
    };
  }

  componentDidMount() {
    this.generarFiltros();
  }

  componentDidUpdate() {
    this.generarFiltros();
  }

  /**
   * Generar la lista de filtros disponibles
   */
  generarFiltros() {
    if (this.props.datos && this.props.datos.length > 0) {
      const filtros = [
        ...new Map(
          this.props.datos.map((dato) => [
            dato[this.props.propiedadObjeto],
            {
              propiedad: this.props.propiedadObjeto,
              valor: String(dato[this.props.propiedadObjeto]),
              seleccionado: false,
            },
          ])
        ).values(),
      ];

      if (this.state.filtros.length !== filtros.length) {
        this.setState({ filtros: [...filtros] });
      }
    }
  }

  obtenerContenidoDropdown = () => {
    return (
      <Menu className="dropdown-filter">
        <Menu.Item key="all">
          <Checkbox
            onChange={this.eventoSeleccionarTodos}
            checked={this.state.seleccionarTodos}
          >
            Marcar todos
          </Checkbox>
        </Menu.Item>
        <Menu.Divider></Menu.Divider>
        {this.state.filtros.map((filtro, index) => {
          return (
            <Menu.Item key={`${index}`}>
              <Checkbox
                onChange={(e) => this.eventoCheckItem(e, filtro)}
                checked={filtro.seleccionado}
              >
                {filtro.valor}
              </Checkbox>
            </Menu.Item>
          );
        })}
        <Menu.Divider></Menu.Divider>
        <Menu.Item key="actions">
          <Button type="link" onClick={() => this.eventoDropdownVisible(false)}>
            Cerrar
          </Button>
          <Button
            type="link"
            onClick={this.eventoFiltrarFilas}
            disabled={this.state.filtros.length === 0}
          >
            Filtrar datos
          </Button>
        </Menu.Item>
      </Menu>
    );
  };

  /**
   * Evento click sobre un filtro del dropdown
   *
   * @param evento any - el evento emitido por el checkbox
   * @param filtro FiltroFila - el objeto tipo FiltroFila
   */
  eventoCheckItem = (evento, filtro) => {
    const filtrosTabla = this.state.filtros;

    filtrosTabla
      .filter((data) => data.valor === filtro.valor)
      .map((data) => (data.seleccionado = evento.target.checked));

    this.setState({ filtros: filtrosTabla });
  };

  /**
   * Evento click mostrar/ocultar el dropdown
   *
   * @param visible boolean - true or false
   */
  eventoDropdownVisible = (visible) => {
    this.setState({ dropdownVisible: visible });
  };

  /**
   * Evento click marcar o desmarcar todos los filtros
   *
   * @param evento any - el evento emitido por el checkbox
   */
  eventoSeleccionarTodos = (evento) => {
    const seleccion = evento.target.checked;
    const filtrosTabla = this.state.filtros;
    filtrosTabla.map((filtro) => (filtro.seleccionado = seleccion));

    this.setState({
      seleccionarTodos: seleccion,
      filtros: [...filtrosTabla],
    });
  };

  /**
   * Evento click botón filtrar datos
   */
  eventoFiltrarFilas = () => {
    if (this.props.emiteEvento) {
      const filtros = this.state.filtros.filter(
        (filtro) => filtro.seleccionado
      );
      this.setState({ filtrando: filtros.length > 0 });
      this.eventoDropdownVisible(false);

      this.props.emiteEvento({
        accion: "filtrar",
        propiedadObjeto: this.props.propiedadObjeto,
        filtrosTabla: filtros,
      });
    }
  };

  render() {
    return (
      <Dropdown
        className="px-1 text-sm"
        overlay={this.obtenerContenidoDropdown}
        trigger={["contextMenu"]}
        visible={this.state.dropdownVisible}
      >
        <Button
          type={this.state.filtrando ? "link" : "text"}
          onClick={() => this.eventoDropdownVisible(true)}
        >
          <FilterIcon className="w-4" />
        </Button>
      </Dropdown>
    );
  }
}

TableFilter.propTypes = {
  propiedadObjeto: PropTypes.string,
  datos: PropTypes.array,
  emiteEvento: (evento) => {},
};

export default TableFilter;
