import { PropTypes } from "prop-types";

const SimInventory = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="525.000000pt"
      height="525.000000pt"
      viewBox="0 0 525.000000 525.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,525.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2465 5239 c-1009 -58 -1901 -703 -2274 -1644 -319 -802 -225 -1706
249 -2417 110 -166 198 -275 320 -398 350 -355 808 -609 1280 -714 232 -51
328 -61 590 -60 262 1 357 11 578 60 1015 226 1808 1051 1997 2078 104 570 21
1152 -239 1663 -478 940 -1445 1494 -2501 1432z m-156 -1084 c29 -16 65 -44
80 -63 16 -18 198 -369 406 -780 208 -411 382 -751 386 -756 11 -12 131 44
125 58 -2 6 -133 302 -290 658 -157 356 -286 650 -286 655 0 4 38 24 84 44
l83 36 448 -1016 c246 -559 449 -1017 450 -1019 1 -1 186 79 411 179 225 100
418 185 430 189 19 8 25 -1 67 -96 l46 -104 -32 -14 c-18 -7 -233 -102 -478
-210 l-446 -196 -74 0 -74 0 -12 61 c-46 241 -286 409 -527 370 -193 -31 -354
-185 -389 -372 l-11 -59 -445 0 -446 0 -12 61 c-46 241 -286 409 -527 370
-108 -18 -187 -60 -268 -142 l-69 -70 3 443 c3 432 4 444 25 483 12 22 36 54
55 72 54 52 93 63 228 63 l120 0 0 515 c0 443 2 521 15 553 21 49 51 81 100
103 37 18 66 19 405 17 l365 -3 54 -30z m1721 -1121 c40 -91 69 -169 64 -173
-21 -18 -525 -233 -532 -227 -9 9 -151 330 -147 334 6 7 521 231 531 232 6 0
44 -75 84 -166z m448 -387 c57 -131 103 -239 101 -241 -21 -17 -764 -335 -769
-329 -15 16 -212 474 -207 480 14 13 743 332 756 330 7 -1 60 -108 119 -240z
m-2972 -622 c75 -37 142 -104 180 -180 26 -54 29 -70 29 -155 0 -82 -4 -103
-27 -152 -35 -76 -95 -138 -170 -179 -60 -33 -68 -34 -168 -34 -94 0 -111 3
-161 28 -67 33 -152 119 -181 183 -62 141 -26 319 85 421 83 77 158 104 273
100 65 -3 93 -9 140 -32z m1774 19 c191 -57 309 -264 255 -450 -34 -119 -118
-211 -232 -254 -82 -31 -205 -26 -285 12 -76 35 -142 104 -181 186 -27 57 -31
77 -31 147 1 104 28 176 96 251 100 109 240 149 378 108z"
        />
        <path
          d="M1688 3889 c-17 -9 -18 -43 -18 -489 l0 -480 133 0 c149 0 192 -13
240 -69 33 -40 47 -89 47 -168 l0 -63 359 0 c314 0 362 2 375 16 9 8 16 21 16
28 0 18 -619 1219 -633 1228 -18 12 -499 9 -519 -3z"
        />
        <path
          d="M1325 1873 c-11 -2 -30 -6 -42 -8 -34 -8 -84 -55 -104 -100 -24 -51
-24 -99 0 -150 59 -131 231 -152 318 -39 39 52 44 151 11 209 -34 58 -121 100
-183 88z"
        />
        <path
          d="M3155 1875 c-5 -2 -22 -6 -37 -9 -37 -8 -98 -63 -114 -103 -34 -80
-2 -184 69 -231 58 -39 146 -38 205 1 112 74 108 252 -8 315 -35 19 -96 34
-115 27z"
        />
      </g>
    </svg>
  );
};

SimInventory.propTypes = {
  className: PropTypes.string,
};

export default SimInventory;
