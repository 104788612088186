import { PropTypes } from "prop-types";
const SimProcess = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="526.000000pt"
      height="526.000000pt"
      viewBox="0 0 526.000000 526.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,526.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2450 5250 c-506 -34 -988 -213 -1399 -519 -137 -103 -379 -336 -477
-461 -304 -386 -491 -833 -556 -1330 -16 -123 -16 -497 0 -620 55 -416 197
-803 415 -1132 432 -649 1105 -1068 1872 -1164 154 -19 454 -21 605 -4 786 87
1486 517 1915 1175 216 331 345 676 407 1090 18 121 18 569 0 690 -90 598
-339 1101 -747 1510 -355 357 -829 614 -1310 714 -226 46 -506 66 -725 51z
m988 -740 c82 -50 95 -145 29 -217 -11 -13 -19 -24 -16 -25 2 -2 38 -20 79
-42 148 -76 241 -146 381 -285 237 -237 364 -472 429 -789 40 -197 36 -491 -8
-566 -54 -91 -174 -98 -236 -13 -20 27 -21 42 -22 255 0 215 -2 232 -28 330
-67 249 -177 437 -360 610 -75 70 -225 182 -245 182 -4 0 -16 -22 -28 -49 -35
-84 -126 -117 -200 -72 -41 26 -270 326 -283 371 -13 47 2 99 37 132 16 14
102 64 193 112 181 94 218 103 278 66z m-1480 -116 c20 -14 22 -23 22 -119 0
-97 1 -104 21 -110 17 -6 35 7 93 64 40 40 80 71 91 71 12 0 79 -59 172 -152
116 -115 153 -159 153 -178 0 -17 -21 -46 -70 -95 -95 -96 -91 -105 43 -105
145 0 137 14 137 -245 0 -166 -3 -215 -14 -231 -12 -16 -29 -19 -119 -22 -138
-5 -141 -12 -47 -107 49 -50 70 -78 70 -96 0 -19 -37 -61 -154 -178 -134 -133
-157 -152 -178 -146 -13 4 -54 38 -93 76 -57 56 -73 66 -87 58 -15 -8 -18 -25
-18 -108 0 -145 16 -136 -245 -136 -261 0 -245 -9 -245 136 0 83 -3 100 -17
108 -15 8 -31 -3 -89 -61 -54 -52 -79 -71 -99 -70 -38 0 -325 284 -325 322 0
17 21 46 70 95 94 95 91 102 -47 107 -90 3 -107 6 -119 22 -11 16 -14 64 -14
230 0 260 -8 246 135 246 136 0 139 8 45 105 -46 48 -70 81 -70 96 0 31 295
329 325 329 11 0 51 -31 91 -71 58 -57 76 -70 93 -64 20 6 21 13 21 110 0 141
-12 135 245 135 165 0 204 -3 223 -16z m-846 -1791 c59 -29 63 -46 63 -288 1
-235 8 -285 60 -441 88 -261 304 -527 547 -673 l37 -22 11 40 c11 43 64 97
105 107 32 8 85 -2 111 -21 40 -27 266 -336 276 -376 9 -33 7 -46 -13 -86 -23
-47 -28 -50 -208 -142 -199 -101 -239 -111 -290 -73 -81 60 -93 148 -29 218
l20 22 -100 52 c-266 139 -514 390 -651 661 -135 265 -195 580 -162 848 16
130 34 160 108 186 40 14 70 11 115 -12z m2639 -2 c16 -19 19 -40 19 -122 0
-95 1 -99 22 -99 13 0 49 27 94 70 39 39 78 70 85 70 17 0 329 -312 329 -329
0 -7 -31 -46 -70 -85 -43 -45 -70 -81 -70 -94 0 -21 4 -22 99 -22 151 0 145
10 149 -242 3 -195 2 -205 -18 -231 -21 -26 -25 -27 -120 -27 -84 0 -101 -3
-109 -17 -8 -15 4 -32 65 -94 41 -42 74 -85 74 -95 0 -28 -300 -324 -329 -324
-15 0 -48 24 -97 71 -95 93 -104 90 -104 -34 0 -80 -3 -98 -21 -121 l-20 -26
-208 0 c-163 0 -211 3 -227 14 -16 12 -20 30 -24 123 -6 139 -16 143 -115 43
-39 -39 -78 -70 -89 -70 -25 0 -315 286 -323 318 -5 20 7 37 67 97 100 100 97
109 -43 115 -93 4 -111 8 -123 24 -11 16 -14 64 -14 223 0 203 0 204 25 228
22 23 32 25 120 25 127 0 129 8 36 104 -46 48 -71 82 -71 97 0 27 281 313 318
324 19 5 36 -6 100 -69 63 -61 80 -73 95 -65 14 8 17 25 17 109 0 95 1 99 27
120 26 20 36 21 231 18 200 -3 204 -3 223 -27z"
        />
        <path
          d="M1610 4211 c0 -113 -5 -122 -91 -157 -41 -16 -77 -31 -81 -32 -5 -2
-39 26 -76 62 -38 36 -73 66 -78 66 -5 0 -49 -39 -97 -88 l-87 -87 65 -65 c36
-35 65 -73 65 -84 0 -30 -50 -149 -68 -163 -10 -8 -49 -13 -104 -13 l-88 0 0
-130 0 -130 94 0 c63 0 97 -4 104 -12 14 -18 62 -138 62 -158 0 -8 -27 -42
-60 -75 -33 -33 -60 -64 -60 -69 0 -6 39 -50 87 -98 l87 -87 67 64 c37 36 73
65 80 65 27 0 145 -52 162 -72 13 -16 17 -39 17 -104 l0 -84 125 0 125 0 0 84
c0 65 4 88 18 104 16 20 134 72 161 72 7 0 43 -29 80 -65 l67 -64 87 87 c48
48 87 92 87 98 0 5 -27 36 -60 69 -33 33 -60 67 -60 75 0 20 48 140 62 158 7
8 41 12 104 12 l94 0 0 130 0 130 -88 0 c-55 0 -94 5 -104 13 -18 14 -68 133
-68 163 0 11 29 49 65 84 l65 65 -87 87 c-48 49 -92 88 -97 88 -5 0 -40 -30
-78 -66 -37 -36 -71 -64 -76 -62 -4 1 -40 16 -81 32 -86 35 -91 44 -91 157 l0
79 -125 0 -125 0 0 -79z m271 -221 c177 -54 312 -213 340 -400 10 -63 9 -88
-4 -154 -23 -111 -59 -180 -136 -257 -198 -198 -494 -198 -692 0 -76 77 -113
146 -135 255 -48 235 99 482 334 557 79 25 211 25 293 -1z"
        />
        <path
          d="M1596 3865 c-79 -32 -143 -89 -183 -162 -105 -192 -32 -418 166 -516
59 -29 74 -32 156 -32 82 0 97 3 157 33 116 57 185 149 209 276 30 166 -73
344 -235 405 -81 30 -191 29 -270 -4z"
        />
        <path
          d="M3390 2412 c0 -72 -3 -92 -17 -105 -18 -15 -138 -67 -157 -67 -6 0
-41 30 -78 67 l-68 68 -92 -92 -92 -92 67 -70 c37 -38 67 -73 67 -78 0 -15
-55 -140 -69 -157 -11 -13 -33 -16 -107 -16 l-94 0 0 -130 0 -130 88 0 c56 0
95 -5 106 -13 14 -10 76 -143 76 -164 0 -3 -30 -36 -67 -74 l-67 -70 92 -91
91 -92 70 67 c38 37 71 67 74 67 21 0 154 -62 164 -76 8 -11 13 -50 13 -106
l0 -88 130 0 130 0 0 94 c0 73 3 96 16 107 14 12 108 54 146 66 12 4 40 -16
88 -63 l71 -68 92 92 92 92 -68 68 c-37 37 -67 72 -67 76 0 19 53 142 68 159
12 14 32 17 104 17 l88 0 0 130 0 130 -87 0 c-49 0 -94 4 -102 9 -13 8 -58
107 -68 148 -4 18 10 38 63 93 l68 71 -92 91 -91 92 -71 -68 c-55 -53 -75 -67
-93 -63 -41 10 -140 55 -148 68 -5 8 -9 53 -9 102 l0 87 -130 0 -130 0 0 -88z
m245 -197 c256 -67 417 -322 364 -577 -78 -375 -542 -517 -820 -251 -169 161
-199 427 -68 622 116 173 328 257 524 206z"
        />
        <path
          d="M3419 2096 c-102 -27 -190 -103 -238 -205 -23 -49 -26 -69 -26 -156
0 -89 3 -106 27 -155 35 -71 107 -143 178 -178 49 -24 67 -27 155 -27 88 0
107 3 157 27 210 98 280 350 152 545 -83 127 -254 190 -405 149z"
        />
      </g>
    </svg>
  );
};

SimProcess.propTypes = {
  className: PropTypes.string,
};

export default SimProcess;
