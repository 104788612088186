import { PropTypes } from "prop-types";

const CrissCross = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="505.000000pt"
      height="505.000000pt"
      viewBox="0 0 505.000000 505.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,505.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2350 5044 c-19 -2 -78 -9 -130 -14 -531 -58 -1052 -308 -1449 -695
-425 -414 -678 -926 -752 -1520 -20 -160 -15 -474 10 -640 48 -321 140 -599
284 -860 391 -710 1081 -1182 1887 -1291 169 -23 482 -23 650 0 1150 157 2022
1030 2181 2186 18 124 18 506 0 630 -54 395 -196 775 -406 1085 -110 164 -187
258 -315 385 -388 388 -886 636 -1435 716 -97 14 -455 26 -525 18z m399 -909
c764 -107 1360 -752 1398 -1515 5 -97 3 -121 -11 -148 -21 -40 -70 -72 -111
-72 -39 0 -90 32 -108 67 -8 16 -17 75 -20 133 -21 348 -156 649 -402 895
-610 611 -1623 514 -2105 -199 -458 -680 -225 -1605 501 -1985 176 -92 364
-143 574 -157 l110 -7 38 -37 c30 -31 37 -45 37 -76 0 -84 -57 -134 -151 -134
-87 0 -275 27 -382 55 -446 116 -838 435 -1044 850 -291 587 -203 1279 227
1782 260 305 659 514 1060 556 92 10 290 5 389 -8z m54 -529 c110 -28 253 -93
348 -156 87 -59 229 -199 288 -284 100 -146 168 -313 191 -475 19 -128 8 -333
-25 -451 -192 -696 -967 -1033 -1601 -696 -329 175 -547 502 -585 879 -10 97
-10 117 4 149 40 96 177 104 221 14 8 -17 20 -80 26 -141 31 -305 203 -556
475 -693 419 -210 945 -32 1155 393 65 131 83 216 83 385 0 129 -3 155 -26
233 -110 360 -406 597 -780 624 -104 7 -143 28 -166 87 -20 52 0 111 49 144
33 23 41 24 140 19 62 -4 145 -16 203 -31z"
        />
      </g>
    </svg>
  );
};

CrissCross.propTypes = {
  className: PropTypes.string,
};

export default CrissCross;
