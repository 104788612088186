import axios from "axios";
import PropTypes from "prop-types";
import React, { createContext, useEffect } from "react";

const FetchContext = createContext();
const { Provider } = FetchContext;

const FetchProvider = ({ children }) => {
  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  useEffect(() => {
    const getCSRFToken = async () => {
      const { data } = await authAxios.get("auth/csrf");
      authAxios.defaults.headers["x-csrf-token"] = data.csrfToken;
    };
    getCSRFToken();
  }, []);

  return (
    <Provider
      value={{
        authAxios,
      }}
    >
      {children}
    </Provider>
  );
};

FetchProvider.propTypes = {
  children: PropTypes.object,
};

export { FetchContext, FetchProvider };
