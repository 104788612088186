import { createContext, useState } from "react";
import { PropTypes } from "prop-types";

const mySimsContext = createContext(null);
const { Provider } = mySimsContext;

const MySimsProviders = ({ children }) => {
  const [mySims, setMySims] = useState([]);

  return (
    <Provider
      value={{
        mySims,
        setMySims: (sims) => setMySims(sims),
      }}
    >
      {children}
    </Provider>
  );
};

MySimsProviders.propTypes = {
  children: PropTypes.object,
};

export { mySimsContext, MySimsProviders };
