import React from "react";
import { PropTypes } from "prop-types";

// Styles
import "./tableSearch.css";

// Ant-design
import { XCircleIcon, SearchCircleIcon } from "@heroicons/react/outline";
import { Button, Dropdown, Input, Space } from "antd";

/**
 * Componente Table Search
 */
export class TableSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filtroBusqueda: "",
      dropdownVisible: false,
      buscando: false,
    };
  }

  /**
   * Obtener html del dropdown
   */
  obtenerContenidoDropdown = () => {
    return (
      <div className="ant-table-filter-dropdown">
        <div className="dropdown-busqueda">
          <div className="div-action">
            Cerrar{" "}
            <XCircleIcon
              className="w-4 inline"
              onClick={() => this.eventoDropdownVisible(false)}
            />
          </div>
          <Input
            type="text"
            size="small"
            onChange={this.eventoFiltroChange}
            value={this.state.filtroBusqueda}
          />
          <Space size="small">
            <Button
              type="primary"
              onClick={() => this.eventoBusqueda()}
              disabled={this.state.filtroBusqueda.trim() === ""}
            >
              Buscar
            </Button>
            <Button
              type="default"
              onClick={() => this.eventoCancelarBusqueda()}
              disabled={this.state.filtroBusqueda.trim() === ""}
            >
              Limpiar
            </Button>
          </Space>
        </div>
      </div>
    );
  };

  /**
   * Cambiar estatus dropdown visible
   *
   * @param visible boolean - true or falsae
   */
  eventoDropdownVisible = (visible) => {
    this.setState({ dropdownVisible: visible });
  };

  /**
   * Evento change del input
   *
   * @param evento any - evento emitido por el input
   */
  eventoFiltroChange = (evento) => {
    this.setState({ filtroBusqueda: evento.target.value });
  };

  /**
   * Evento click buscar
   */
  eventoBusqueda = () => {
    console.log(this.props);
    if (this.props.emiteEvento) {
      this.setState({ buscando: this.state.filtroBusqueda.trim() !== "" });
      this.eventoDropdownVisible(false);
      this.props.emiteEvento({
        accion: "buscar",
        propiedadObjeto: this.props.propiedadObjeto,
        filtroBusqueda: this.state.filtroBusqueda,
      });
    }
  };

  /**
   * Evento click cancelar busqueda
   */
  eventoCancelarBusqueda = () => {
    this.setState({ filtroBusqueda: "" }, () => {
      this.eventoBusqueda();
    });
  };

  render() {
    return (
      <Dropdown
        className="px-1 text-sm"
        overlay={this.obtenerContenidoDropdown}
        trigger={["contextMenu"]}
        visible={this.state.dropdownVisible}
      >
        <Button
          type={this.state.buscando ? "link" : "text"}
          className="ant-dropdown-link"
          onClick={() => this.eventoDropdownVisible(true)}
        >
          <SearchCircleIcon className="w-4" />
        </Button>
      </Dropdown>
    );
  }
}

TableSearch.propTypes = {
  propiedadObjeto: PropTypes.string,
  emiteEvento: (evento) => {},
};

export default TableSearch;
