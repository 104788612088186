import { Toaster } from "react-hot-toast";
import SimIndexNewForm from "../components/SimIndexNewForm";

function SimIndexNew() {
  return (
    <main className="focus:outline-none relative flex-1 overflow-y-auto">
      <div className="py-4">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <div className="bg-white px-4 py-5 shadow sm:p-6">
            <SimIndexNewForm />
          </div>
        </div>
      </div>
      <Toaster />
    </main>
  );
}

export default SimIndexNew;
