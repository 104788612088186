import { PropTypes } from "prop-types";

const SimBusiness = ({ className }) => {
  return (
    <svg
      width="204"
      height="70"
      viewBox="0 0 204 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M59.3266 65.3H17.4266C13.5266 65.3 10.1266 62.9 9.12661 59.5L1.82661 34.7C1.42661 33.4 1.42661 31.9 1.82661 30.6L9.12661 5.8C10.1266 2.4 13.5266 0 17.4266 0H59.3266C61.6266 0 63.5266 1.7 63.5266 3.9V23.3L71.3266 30.4C72.0266 31 72.3266 31.8 72.3266 32.7C72.3266 33.6 71.9266 34.4 71.3266 35L63.5266 42V61.4C63.5266 63.5 61.6266 65.3 59.3266 65.3ZM17.4266 0.9C13.9266 0.9 10.8266 3 9.92661 6.1L2.62661 30.8C2.22661 32 2.22661 33.3 2.62661 34.5L9.92661 59.2C10.8266 62.3 13.9266 64.4 17.4266 64.4H59.3266C61.2266 64.4 62.7266 63 62.7266 61.3V41.7L70.8266 34.3C71.3266 33.9 71.6266 33.3 71.6266 32.6C71.6266 32 71.3266 31.4 70.8266 30.9L62.7266 23.5V3.9C62.7266 2.2 61.2266 0.8 59.3266 0.8H17.4266V0.9Z"
        fill="white"
      />
      <path
        d="M55.7266 67.2002H32.2266C32.0266 67.2002 31.7266 67.0002 31.7266 66.7002C31.7266 66.5002 31.9266 66.2002 32.2266 66.2002H55.7266C55.9266 66.2002 56.2266 66.4002 56.2266 66.7002C56.1266 67.0002 55.9266 67.2002 55.7266 67.2002Z"
        fill="white"
      />
      <path
        d="M52.3265 69.2998H43.9265C43.7265 69.2998 43.4265 69.0998 43.4265 68.7998C43.4265 68.5998 43.6265 68.2998 43.9265 68.2998H52.3265C52.5265 68.2998 52.8265 68.4998 52.8265 68.7998C52.8265 69.0998 52.6265 69.2998 52.3265 69.2998Z"
        fill="white"
      />
      <path
        d="M0.426599 29.1999H0.326599C0.126599 29.0999 -0.0734014 28.8999 0.0265986 28.5999L6.5266 6.2999C6.6266 5.9999 6.8266 5.8999 7.1266 5.9999C7.3266 5.9999 7.4266 6.2999 7.4266 6.4999L0.826599 28.8999C0.726599 29.0999 0.626599 29.1999 0.426599 29.1999Z"
        fill="white"
      />
      <path
        d="M32.1266 25.7998L27.7266 28.7998L27.9266 30.8998L32.2266 27.9998L32.1266 25.7998Z"
        fill="white"
      />
      <path
        d="M34.4264 22L34.2264 18.5L29.8264 22L30.1264 24.7L34.4264 22Z"
        fill="white"
      />
      <path
        d="M30.4266 34.6L35.0266 32.1V30L30.2266 32.5L30.4266 34.6Z"
        fill="white"
      />
      <path
        d="M65.6264 31.3002L58.8264 24.8002V7.3002C58.8264 5.6002 57.4264 4.2002 55.6264 4.2002H19.9264C16.7264 4.2002 14.0264 6.2002 13.2264 9.1002L6.92642 31.3002C6.62642 32.4002 6.62642 33.6002 6.92642 34.8002L13.2264 57.0002C14.0264 59.9002 16.8264 61.9002 19.9264 61.9002H55.6264C57.4264 61.9002 58.8264 60.5002 58.8264 58.8002V41.3002L65.6264 34.8002C66.6264 33.8002 66.6264 32.2002 65.6264 31.3002ZM20.1264 48.8002L18.1264 30.9002L20.7264 27.0002L22.4264 39.0002L21.6264 26.8002L25.6264 28.4002L26.9264 46.4002C24.4264 47.0002 22.1264 47.8002 20.1264 48.8002ZM36.3264 45.3002C33.1264 45.3002 30.1264 45.6002 27.4264 46.2002L25.9264 22.4002L36.4264 13.5002L37.4264 45.3002C37.0264 45.3002 36.7264 45.3002 36.3264 45.3002ZM42.5264 45.8002C40.8264 45.6002 39.1264 45.4002 37.3264 45.4002V13.9002L42.5264 20.2002V45.8002ZM48.7264 47.2002C46.9264 46.6002 44.9264 46.1002 42.9264 45.8002L43.3264 30.2002L47.7264 26.8002L43.9264 45.2002L48.9264 27.1002L53.2264 32.3002L48.7264 47.2002Z"
        fill="white"
      />
      <path
        d="M75.8265 37.7C75.9265 37.8 76.1265 37.9 76.4265 38.1C76.6265 38.3 77.1265 38.5 77.9265 38.8C78.7265 39.1 79.4265 39.2 80.2265 39.2C81.2265 39.2 82.0265 39 82.4265 38.6C82.9265 38.2 83.1265 37.7 83.1265 37.1C83.1265 36.5 82.9265 36 82.4265 35.7C81.9265 35.3 81.0265 34.9 79.6265 34.5C77.8265 34 76.5265 33.4 75.7265 32.7C74.9265 32 74.5265 31.1 74.5265 29.9C74.5265 28.5 75.0265 27.5 76.0265 26.7C77.0265 25.9 78.4265 25.5 80.1265 25.5C81.2265 25.5 82.2265 25.6 83.1265 25.9C84.0265 26.2 84.6265 26.4 84.9265 26.7L85.4265 27.1L84.0265 29.2C83.9265 29.1 83.7265 29 83.5265 28.8C83.3265 28.7 82.8265 28.5 82.2265 28.2C81.5265 28 80.8265 27.8 80.1265 27.8C79.2265 27.8 78.5265 28 78.0265 28.3C77.5265 28.6 77.2265 29.1 77.2265 29.7C77.2265 30.2 77.4265 30.6 77.9265 31C78.4265 31.4 79.3265 31.7 80.6265 32.1C82.5265 32.6 83.8265 33.3 84.6265 34C85.4265 34.7 85.8265 35.7 85.8265 36.9C85.8265 38.3 85.3265 39.4 84.3265 40.2C83.3265 41.1 81.9265 41.5 80.1265 41.5C79.4265 41.5 78.7265 41.4 78.0265 41.3C77.3265 41.2 76.8265 41 76.4265 40.8C76.0265 40.6 75.6265 40.4 75.3265 40.2C75.0265 40 74.7265 39.9 74.6265 39.7L74.4265 39.5L75.8265 37.7Z"
        fill="white"
      />
      <path
        d="M88.5265 27.9999C88.2265 27.6999 88.1265 27.2999 88.1265 26.8999C88.1265 26.4999 88.2265 26.0999 88.5265 25.7999C88.8265 25.4999 89.2265 25.3999 89.6265 25.3999C90.0265 25.3999 90.4265 25.4999 90.7265 25.7999C91.0265 26.0999 91.1265 26.4999 91.1265 26.8999C91.1265 27.2999 90.9265 27.6999 90.7265 27.9999C90.4265 28.2999 90.0265 28.3999 89.6265 28.3999C89.1265 28.3999 88.8265 28.2999 88.5265 27.9999ZM88.3265 30.3999H90.8265V41.0999H88.3265V30.3999Z"
        fill="white"
      />
      <path
        d="M93.9265 30.4001H95.5265L95.8265 31.9001H96.0265C96.8265 30.7001 98.0265 30.1001 99.6265 30.1001C101.427 30.1001 102.627 30.8001 103.427 32.0001H103.527C104.327 30.7001 105.527 30.1001 107.227 30.1001C108.627 30.1001 109.727 30.5001 110.427 31.4001C111.227 32.3001 111.627 33.4001 111.627 34.9001V41.1001H109.127V35.2001C109.127 34.3001 108.927 33.6001 108.427 33.1001C108.027 32.6001 107.327 32.3001 106.627 32.3001C105.827 32.3001 105.227 32.5001 104.827 33.0001C104.427 33.5001 104.127 34.0001 104.127 34.7001V41.1001H101.627V34.9001C101.627 34.1001 101.427 33.5001 100.927 33.0001C100.527 32.5001 99.8265 32.3001 99.0265 32.3001C98.2265 32.3001 97.6265 32.5001 97.2265 33.0001C96.8265 33.5001 96.5265 34.1001 96.5265 34.7001V41.1001H94.0265L93.9265 30.4001Z"
        fill="white"
      />
      <path
        d="M125.826 39.9998C124.826 40.6998 123.526 41.0998 121.726 41.0998H114.826V25.7998H121.526C123.226 25.7998 124.526 26.1998 125.426 26.8998C126.326 27.5998 126.826 28.5998 126.826 29.9998C126.826 30.6998 126.626 31.3998 126.226 31.9998C125.826 32.5998 125.326 32.9998 124.626 33.2998V33.3998C125.426 33.5998 126.026 33.9998 126.526 34.5998C127.026 35.1998 127.226 35.8998 127.226 36.7998C127.226 38.1998 126.826 39.2998 125.826 39.9998ZM123.426 28.5998C122.926 28.1998 122.326 28.0998 121.426 28.0998H117.226V32.3998H121.326C122.126 32.3998 122.826 32.1998 123.326 31.7998C123.826 31.3998 124.026 30.8998 124.026 30.2998C124.126 29.4998 123.926 28.9998 123.426 28.5998ZM117.326 34.3998V38.7998H121.826C122.626 38.7998 123.326 38.5998 123.826 38.1998C124.326 37.7998 124.626 37.2998 124.626 36.5998C124.626 35.9998 124.426 35.4998 123.926 35.0998C123.426 34.6998 122.826 34.4998 122.026 34.3998H117.326Z"
        fill="white"
      />
      <path
        d="M137.126 30.3998H139.626V41.0998H138.026L137.626 39.5998H137.526C136.726 40.7998 135.526 41.2998 134.026 41.2998C132.626 41.2998 131.526 40.8998 130.826 39.9998C130.026 39.0998 129.626 37.8998 129.626 36.4998V30.2998H132.126V36.1998C132.126 37.0998 132.326 37.7998 132.726 38.2998C133.126 38.7998 133.726 39.0998 134.526 39.0998C135.326 39.0998 136.026 38.7998 136.426 38.2998C136.926 37.6998 137.126 37.1998 137.126 36.4998V30.3998Z"
        fill="white"
      />
      <path
        d="M142.926 38.4001C143.926 39.1001 145.126 39.4001 146.526 39.4001C147.826 39.4001 148.526 39.0001 148.526 38.2001C148.526 37.9001 148.326 37.6001 148.026 37.4001C147.726 37.2001 147.326 37.0001 146.826 36.9001C146.326 36.8001 145.826 36.6001 145.326 36.5001C144.826 36.3001 144.226 36.1001 143.826 35.9001C143.326 35.7001 142.926 35.3001 142.626 34.9001C142.326 34.5001 142.126 33.9001 142.126 33.3001C142.126 32.4001 142.526 31.6001 143.226 31.0001C143.926 30.4001 144.926 30.1001 146.326 30.1001C147.226 30.1001 148.026 30.2001 148.726 30.3001C149.426 30.5001 150.026 30.6001 150.326 30.8001L150.826 31.0001L149.926 33.0001C149.026 32.4001 147.926 32.1001 146.526 32.1001C145.226 32.1001 144.626 32.5001 144.626 33.2001C144.626 33.5001 144.826 33.7001 145.126 33.9001C145.426 34.1001 145.826 34.3001 146.326 34.4001C146.826 34.5001 147.326 34.6001 147.926 34.8001C148.526 35.0001 149.026 35.2001 149.526 35.4001C150.026 35.6001 150.426 36.0001 150.726 36.4001C151.026 36.9001 151.226 37.4001 151.226 38.1001C151.226 39.1001 150.826 39.8001 150.126 40.4001C149.426 41.0001 148.326 41.3001 146.826 41.3001C145.926 41.3001 145.026 41.2001 144.326 41.0001C143.626 40.8001 143.026 40.6001 142.726 40.4001L142.326 40.1001L142.926 38.4001Z"
        fill="white"
      />
      <path
        d="M153.626 27.9999C153.326 27.6999 153.126 27.2999 153.126 26.8999C153.126 26.4999 153.326 26.0999 153.626 25.7999C153.926 25.4999 154.326 25.3999 154.726 25.3999C155.126 25.3999 155.526 25.4999 155.826 25.7999C156.126 26.0999 156.326 26.4999 156.326 26.8999C156.326 27.2999 156.126 27.6999 155.826 27.9999C155.526 28.2999 155.126 28.3999 154.726 28.3999C154.226 28.3999 153.926 28.2999 153.626 27.9999ZM153.426 30.3999H155.926V41.0999H153.426V30.3999Z"
        fill="white"
      />
      <path
        d="M159.027 30.4001H160.627L160.927 31.9001H161.127C161.927 30.7001 163.127 30.1001 164.727 30.1001C166.127 30.1001 167.227 30.5001 168.027 31.4001C168.827 32.3001 169.227 33.4001 169.227 34.9001V41.1001H166.727V35.2001C166.727 34.3001 166.527 33.6001 166.027 33.1001C165.527 32.6001 164.927 32.3001 164.127 32.3001C163.327 32.3001 162.727 32.5001 162.327 33.0001C161.827 33.5001 161.627 34.1001 161.527 34.7001V41.1001H159.027V30.4001Z"
        fill="white"
      />
      <path
        d="M181.027 40.2002C180.927 40.3002 180.827 40.4002 180.627 40.6002C180.427 40.7002 180.027 40.9002 179.327 41.2002C178.627 41.4002 177.927 41.6002 177.127 41.6002C175.427 41.6002 174.027 41.1002 173.027 40.0002C172.027 39.0002 171.527 37.6002 171.527 35.9002C171.527 34.3002 172.027 32.9002 172.927 31.8002C173.927 30.7002 175.127 30.2002 176.727 30.2002C178.227 30.2002 179.427 30.7002 180.327 31.7002C181.227 32.7002 181.627 34.0002 181.627 35.6002V36.3002H173.927C174.027 37.4002 174.327 38.2002 174.927 38.7002C175.527 39.3002 176.327 39.5002 177.227 39.5002C177.727 39.5002 178.327 39.4002 178.727 39.3002C179.227 39.1002 179.527 39.0002 179.727 38.8002L180.027 38.6002L181.027 40.2002ZM174.027 34.5002H179.027C178.827 32.9002 178.027 32.1002 176.627 32.1002C175.127 32.1002 174.327 32.9002 174.027 34.5002Z"
        fill="white"
      />
      <path
        d="M184.027 38.4001C185.027 39.1001 186.227 39.4001 187.627 39.4001C189.027 39.4001 189.627 39.0001 189.627 38.2001C189.627 37.9001 189.427 37.6001 189.127 37.4001C188.827 37.2001 188.427 37.0001 187.927 36.9001C187.427 36.8001 186.927 36.6001 186.427 36.5001C185.927 36.3001 185.327 36.1001 184.927 35.9001C184.427 35.7001 184.027 35.3001 183.727 34.9001C183.427 34.5001 183.227 33.9001 183.227 33.3001C183.227 32.4001 183.627 31.6001 184.327 31.0001C185.027 30.4001 186.027 30.1001 187.427 30.1001C188.327 30.1001 189.127 30.2001 189.827 30.3001C190.527 30.5001 191.127 30.6001 191.427 30.8001L191.927 31.0001L191.127 32.9001C190.227 32.3001 189.127 32.0001 187.727 32.0001C186.427 32.0001 185.827 32.4001 185.827 33.1001C185.827 33.4001 186.027 33.6001 186.327 33.8001C186.627 34.0001 187.027 34.2001 187.527 34.3001C188.027 34.4001 188.527 34.5001 189.127 34.7001C189.727 34.9001 190.227 35.1001 190.727 35.3001C191.227 35.5001 191.627 35.9001 191.927 36.3001C192.227 36.8001 192.427 37.3001 192.427 38.0001C192.427 39.0001 192.027 39.7001 191.327 40.3001C190.627 40.9001 189.527 41.2001 188.027 41.2001C187.127 41.2001 186.227 41.1001 185.527 40.9001C184.827 40.7001 184.227 40.5001 183.927 40.3001L183.527 40.0001L184.027 38.4001Z"
        fill="white"
      />
      <path
        d="M194.727 38.4001C195.727 39.1001 196.927 39.4001 198.327 39.4001C199.627 39.4001 200.327 39.0001 200.327 38.2001C200.327 37.9001 200.127 37.6001 199.827 37.4001C199.527 37.2001 199.127 37.0001 198.627 36.9001C198.127 36.8001 197.627 36.6001 197.127 36.5001C196.627 36.3001 196.027 36.1001 195.627 35.9001C195.127 35.7001 194.727 35.3001 194.427 34.9001C194.127 34.5001 193.927 33.9001 193.927 33.3001C193.927 32.4001 194.327 31.6001 195.027 31.0001C195.727 30.4001 196.727 30.1001 198.127 30.1001C199.027 30.1001 199.827 30.2001 200.527 30.3001C201.227 30.5001 201.827 30.6001 202.127 30.8001L202.627 31.0001L201.827 32.9001C200.927 32.3001 199.827 32.0001 198.427 32.0001C197.127 32.0001 196.527 32.4001 196.527 33.1001C196.527 33.4001 196.727 33.6001 197.027 33.8001C197.327 34.0001 197.727 34.2001 198.227 34.3001C198.727 34.4001 199.227 34.5001 199.827 34.7001C200.427 34.9001 200.927 35.1001 201.427 35.3001C201.927 35.5001 202.327 35.9001 202.627 36.3001C202.927 36.8001 203.127 37.3001 203.127 38.0001C203.127 39.0001 202.727 39.7001 202.027 40.3001C201.327 40.9001 200.227 41.2001 198.727 41.2001C197.827 41.2001 196.927 41.1001 196.227 40.9001C195.527 40.7001 194.927 40.5001 194.627 40.3001L194.227 40.0001L194.727 38.4001Z"
        fill="white"
      />
      <path
        d="M76.2265 44.2002H75.6265V49.3002H76.2265V44.2002Z"
        fill="white"
      />
      <path
        d="M83.0264 49.3002H82.6264L79.3264 45.2002V49.3002H78.8264V44.2002H79.2264L82.4264 48.2002V44.2002H83.0264V49.3002Z"
        fill="white"
      />
      <path
        d="M85.6266 48.4C85.6266 48.4 85.7266 48.5 85.8266 48.5C85.9266 48.6 86.0266 48.6 86.3266 48.7C86.5266 48.8 86.8266 48.8 87.1266 48.8C87.5266 48.8 87.8266 48.7 88.0266 48.6C88.2266 48.4 88.3266 48.2 88.3266 48C88.3266 47.8 88.2266 47.6 88.0266 47.4C87.8266 47.3 87.5266 47.1 86.9266 46.9C86.3266 46.7 85.9266 46.5 85.6266 46.3C85.3266 46.1 85.2266 45.8 85.2266 45.4C85.2266 45 85.4266 44.6 85.7266 44.4C86.0266 44.2 86.5266 44 87.0266 44C87.2266 44 87.4266 44 87.6266 44C87.8266 44 88.0266 44.1 88.1266 44.1C88.2266 44.1 88.3266 44.2 88.4266 44.2C88.5266 44.2 88.6266 44.3 88.6266 44.3H88.7266L88.4266 44.8C88.4266 44.8 88.3266 44.7 88.2266 44.7C88.1266 44.7 88.0266 44.6 87.8266 44.5C87.6266 44.4 87.3266 44.4 87.1266 44.4C86.7266 44.4 86.4266 44.5 86.2266 44.6C86.0266 45 85.9266 45.2 85.9266 45.5C85.9266 45.7 86.0266 45.9 86.2266 46C86.4266 46.1 86.7266 46.3 87.3266 46.4C87.9266 46.6 88.4266 46.8 88.6266 47C88.8266 47.2 89.0266 47.6 89.0266 47.9C89.0266 48.3 88.8266 48.7 88.5266 48.9C88.2266 49.2 87.7266 49.3 87.1266 49.3C86.9266 49.3 86.7266 49.3 86.4266 49.2C86.2266 49.2 86.0266 49.1 85.9266 49.1C85.8266 49 85.7266 49 85.6266 48.9C85.5266 48.8 85.4266 48.8 85.4266 48.7L85.3266 48.6L85.6266 48.4Z"
        fill="white"
      />
      <path
        d="M91.7265 44.2002H91.1265V49.3002H91.7265V44.2002Z"
        fill="white"
      />
      <path
        d="M97.8266 48.6002C97.3266 49.1002 96.7266 49.3002 95.9266 49.3002H94.2266V44.2002H95.9266C96.7266 44.2002 97.3266 44.4002 97.8266 44.9002C98.3266 45.4002 98.5266 46.0002 98.5266 46.8002C98.6266 47.5002 98.3266 48.1002 97.8266 48.6002ZM94.9266 44.7002V48.7002H95.9266C96.5266 48.7002 97.0266 48.5002 97.4266 48.2002C97.8266 47.9002 98.0266 47.3002 98.0266 46.7002C98.0266 46.1002 97.8266 45.6002 97.4266 45.2002C97.0266 44.8002 96.5266 44.7002 95.9266 44.7002H94.9266Z"
        fill="white"
      />
      <path
        d="M103.926 47.0002H101.426V48.7002H104.426V49.3002H100.826V44.2002H104.426V44.7002H101.426V46.4002H103.926V47.0002Z"
        fill="white"
      />
      <path
        d="M110.026 48.6C109.526 48.1 109.326 47.5 109.326 46.7C109.326 45.9 109.526 45.3 110.026 44.8C110.526 44.3 111.126 44 111.826 44C112.526 44 113.226 44.2 113.626 44.8C114.126 45.3 114.326 45.9 114.326 46.7C114.326 47.5 114.126 48.1 113.626 48.6C113.126 49.1 112.526 49.4 111.826 49.4C111.126 49.4 110.526 49.1 110.026 48.6ZM113.326 48.2C113.726 47.8 113.826 47.3 113.826 46.7C113.826 46.1 113.626 45.6 113.326 45.2C112.926 44.8 112.526 44.6 111.926 44.6C111.326 44.6 110.926 44.8 110.526 45.2C110.126 45.6 110.026 46.1 110.026 46.7C110.026 47.3 110.226 47.8 110.526 48.2C110.826 48.6 111.326 48.8 111.926 48.8C112.426 48.8 112.926 48.6 113.326 48.2Z"
        fill="white"
      />
      <path
        d="M120.127 44.6002C120.427 44.9002 120.627 45.3002 120.627 45.9002C120.627 46.5002 120.427 46.9002 120.127 47.2002C119.727 47.5002 119.327 47.7002 118.727 47.7002H117.327V49.3002H116.727V44.2002H118.727C119.227 44.2002 119.727 44.3002 120.127 44.6002ZM119.627 45.0002C119.427 44.8002 119.027 44.7002 118.627 44.7002H117.227V47.1002H118.627C119.527 47.1002 120.027 46.7002 120.027 45.9002C120.027 45.5002 119.827 45.2002 119.627 45.0002Z"
        fill="white"
      />
      <path
        d="M122.227 44.2002H126.227V44.7002H124.527V49.3002H123.927V44.7002H122.227V44.2002Z"
        fill="white"
      />
      <path
        d="M128.926 44.2002H128.326V49.3002H128.926V44.2002Z"
        fill="white"
      />
      <path
        d="M135.427 48.8001C135.327 48.8001 135.327 48.8001 135.427 48.8001C135.327 48.9001 135.227 49.0001 135.127 49.0001C135.027 49.1001 134.927 49.1001 134.827 49.2001C134.727 49.3001 134.527 49.3001 134.327 49.4001C134.127 49.4001 133.927 49.5001 133.727 49.5001C133.027 49.5001 132.327 49.2001 131.927 48.7001C131.427 48.2001 131.227 47.6001 131.227 46.8001C131.227 46.0001 131.427 45.4001 131.927 44.9001C132.427 44.4001 133.027 44.1001 133.727 44.1001C134.027 44.1001 134.427 44.2001 134.627 44.3001C134.927 44.4001 135.127 44.5001 135.227 44.6001L135.427 44.8001L135.127 45.3001C135.127 45.3001 135.027 45.2001 134.927 45.1001C134.827 45.0001 134.727 45.0001 134.427 44.9001C134.227 44.8001 133.927 44.7001 133.727 44.7001C133.127 44.7001 132.727 44.9001 132.327 45.3001C131.927 45.7001 131.827 46.2001 131.827 46.8001C131.827 47.4001 132.027 47.9001 132.327 48.3001C132.727 48.7001 133.127 48.9001 133.727 48.9001C133.927 48.9001 134.027 48.9001 134.127 48.8001C134.327 48.8001 134.427 48.7001 134.527 48.7001C134.627 48.6001 134.727 48.6001 134.827 48.5001C134.927 48.4001 134.927 48.4001 135.027 48.4001L135.127 48.3001L135.427 48.8001Z"
        fill="white"
      />
      <path
        d="M137.427 48.4C137.427 48.4 137.527 48.5 137.627 48.5C137.727 48.6 137.827 48.6 138.127 48.7C138.327 48.8 138.627 48.8 138.927 48.8C139.327 48.8 139.627 48.7 139.827 48.6C140.027 48.4 140.127 48.2 140.127 48C140.127 47.8 140.027 47.6 139.827 47.4C139.627 47.3 139.327 47.1 138.727 46.9C138.127 46.7 137.727 46.5 137.427 46.3C137.127 46.1 137.027 45.8 137.027 45.4C137.027 45 137.227 44.6 137.527 44.4C137.827 44.2 138.327 44 138.827 44C139.027 44 139.227 44 139.427 44C139.627 44 139.827 44.1 139.927 44.1C140.027 44.1 140.127 44.2 140.227 44.2C140.327 44.2 140.427 44.3 140.427 44.3H140.527L140.227 44.8L140.127 44.7C140.027 44.7 139.927 44.6 139.627 44.5C139.427 44.4 139.127 44.4 138.927 44.4C138.527 44.4 138.327 44.5 138.027 44.6C137.827 44.7 137.727 44.9 137.727 45.2C137.727 45.4 137.827 45.6 138.027 45.7C138.227 45.8 138.527 46 139.127 46.1C139.727 46.3 140.227 46.5 140.427 46.7C140.627 46.9 140.827 47.3 140.827 47.6C140.827 48 140.627 48.4 140.327 48.6C140.027 48.9 139.527 49 138.927 49C138.727 49 138.527 49 138.227 48.9C138.027 48.9 137.827 48.8 137.727 48.8C137.627 48.7 137.527 48.7 137.427 48.6C137.327 48.5 137.227 48.5 137.227 48.4L137.127 48.3L137.427 48.4Z"
        fill="white"
      />
    </svg>
  );
};

SimBusiness.propTypes = {
  className: PropTypes.string,
};

export default SimBusiness;
