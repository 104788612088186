import { Link } from "react-router-dom";
import logo from "../assets/svg/logo.svg";

function LogoMedium() {
  return (
    <div className="mt-12 md:mt-0 w-full max-w-sm lg:w-login mx-4 sm:mx-6 lg:mx-20 xl:mx-24 flex justify-center">
      <Link to="/">
        <img className="h-12 w-auto" src={logo} alt="Workflow" />
      </Link>
    </div>
  );
}

export default LogoMedium;
