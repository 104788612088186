import AppFooter from "../components/AppFooter";
import ChangePasswordForm from "../components/ChangePasswordForm";
import ChangeProfileForm from "../components/ChangeProfileForm";
import UploadAvatar from "../components/UploadAvatar";
import { Toaster } from "react-hot-toast";

function Profile() {
  return (
    <main className="focus:outline-none relative flex-1 overflow-y-auto">
      <div className="py-4">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <div className="bg-white px-4 py-5 shadow sm:p-6">
            <div className="md:grid md:grid-cols-4 md:gap-6">
              <UploadAvatar />
              <div className="space-y-8 md:col-span-3">
                <ChangeProfileForm />

                <ChangePasswordForm />
              </div>
            </div>
          </div>
          <AppFooter />
        </div>
      </div>
      <Toaster position="top-center" />
    </main>
  );
}

export default Profile;
