import { PropTypes } from "prop-types";

const SimReports = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="525.000000pt"
      height="526.000000pt"
      viewBox="0 0 525.000000 526.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,526.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2300 5235 c-336 -45 -669 -153 -943 -307 -1000 -559 -1522 -1677
-1306 -2798 150 -781 656 -1457 1370 -1829 540 -281 1182 -362 1789 -226 945
211 1710 954 1954 1898 200 773 36 1598 -445 2237 -406 539 -993 894 -1673
1011 -158 28 -585 35 -746 14z m1128 -1388 l402 -402 0 -633 0 -632 -59 30
c-33 16 -87 37 -120 46 l-61 17 0 504 0 503 -330 0 -330 0 -30 28 c-19 16 -35
43 -40 67 -5 22 -10 174 -10 338 l0 297 -625 0 -625 0 0 -1315 0 -1315 613 0
613 0 27 -59 c15 -32 47 -86 72 -120 l45 -61 -810 0 -810 0 0 1555 0 1555 838
0 837 0 403 -403z m-708 -217 l0 -80 -410 0 -410 0 0 80 0 80 410 0 410 0 0
-80z m0 -350 l0 -80 -410 0 -410 0 0 80 0 80 410 0 410 0 0 -80z m650 -460 l0
-180 -85 0 -85 0 0 42 0 43 -228 -228 -227 -227 -135 135 -135 135 -242 -242
-243 -243 -57 58 -58 57 298 297 297 298 135 -135 135 -135 170 170 170 170
-40 3 -40 3 0 80 0 79 185 0 185 0 0 -180z m233 -720 c31 -70 41 -83 73 -96
35 -15 39 -14 113 15 42 17 81 31 86 31 6 0 30 -19 53 -43 l42 -42 -31 -80
c-30 -75 -31 -82 -18 -119 13 -35 20 -41 94 -70 l80 -32 3 -65 3 -66 -81 -36
c-70 -32 -83 -42 -96 -73 -15 -36 -14 -40 15 -107 17 -38 31 -75 31 -81 0 -7
-19 -31 -43 -54 l-43 -43 -85 31 c-80 29 -86 30 -120 16 -32 -13 -40 -25 -69
-96 l-34 -80 -66 0 -67 0 -35 79 c-31 69 -41 82 -73 95 -35 15 -40 14 -113
-14 -43 -16 -82 -30 -87 -30 -6 0 -29 19 -52 43 l-42 42 31 79 c27 70 29 83
19 116 -10 35 -17 41 -94 73 l-82 34 -3 64 -3 64 83 35 c74 32 84 39 97 73 14
36 13 42 -19 119 l-34 81 46 43 46 44 69 -26 c96 -37 96 -37 137 -20 27 11 41
26 52 53 9 21 24 57 34 81 l19 42 64 0 64 0 36 -80z m-778 -277 c-4 -10 -12
-45 -19 -78 l-13 -60 -446 -3 -447 -2 0 80 0 80 466 0 c440 0 466 -1 459 -17z"
        />
        <path
          d="M3100 3675 l0 -155 157 0 158 0 -155 155 c-85 85 -156 155 -157 155
-2 0 -3 -70 -3 -155z"
        />
        <path
          d="M3425 1766 c-63 -29 -114 -106 -115 -172 0 -45 44 -125 85 -153 89
-60 231 -25 280 70 22 41 19 137 -4 175 -35 58 -86 88 -155 91 -37 2 -73 -2
-91 -11z"
        />
      </g>
    </svg>
  );
};

SimReports.propTypes = {
  className: PropTypes.string,
};

export default SimReports;
