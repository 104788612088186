import { PropTypes } from "prop-types";

const CustomerPulse = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="532.000000pt"
      height="532.000000pt"
      viewBox="0 0 532.000000 532.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,532.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2530 5314 c-1038 -56 -1942 -698 -2334 -1659 -128 -314 -189 -635
-189 -995 0 -444 90 -819 288 -1210 124 -244 267 -441 465 -644 424 -435 951
-699 1560 -782 170 -24 510 -24 680 0 855 117 1574 607 1991 1358 152 274 260
605 305 938 23 170 23 510 0 680 -66 489 -267 956 -573 1335 -93 115 -292 313
-408 405 -315 251 -718 439 -1109 520 -200 41 -488 64 -676 54z m77 -1404 c18
-10 39 -30 48 -44 11 -17 66 -314 165 -886 81 -474 150 -863 152 -866 3 -2 26
51 52 118 52 135 87 182 146 198 49 14 1353 13 1402 0 77 -22 128 -107 108
-180 -5 -20 -26 -51 -45 -70 l-36 -35 -646 -5 -647 -5 -132 -333 c-73 -185
-142 -345 -155 -358 -65 -69 -190 -50 -234 36 -8 16 -67 332 -130 702 -63 370
-118 686 -122 702 -6 21 -42 -157 -137 -680 -71 -390 -135 -724 -142 -741 -19
-47 -67 -73 -137 -73 -68 0 -111 19 -131 58 -8 15 -58 238 -112 497 -53 259
-100 473 -104 478 -3 4 -35 -43 -70 -105 -75 -131 -102 -162 -156 -177 -51
-14 -745 -15 -796 -1 -21 6 -51 27 -73 51 -36 40 -37 44 -33 101 4 52 9 63 41
96 l37 37 353 3 352 3 151 257 c130 221 157 260 190 279 70 40 145 22 195 -47
12 -16 40 -128 79 -314 33 -159 60 -294 61 -300 2 -30 21 71 149 774 91 506
143 767 154 785 26 40 75 65 126 65 27 0 58 -8 77 -20z"
        />
      </g>
    </svg>
  );
};

CustomerPulse.propTypes = {
  className: PropTypes.string,
};

export default CustomerPulse;
