import React from "react";
import { PropTypes } from "prop-types";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { Button } from "antd";

class TablaSort extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orden: "ascendente",
      ordenando: false,
    };
  }

  eventoOrdenarFilas = () => {
    if (this.props.emiteEvento) {
      this.setState(
        {
          orden:
            this.state.orden === "ascendente" ? "descendente" : "ascendente",
          ordenando: true,
        },
        () => {
          this.props.emiteEvento({
            propiedadObjeto: this.props.propiedadObjeto,
            accion: "ordenar",
            ordenamiento: this.state.orden,
          });
        }
      );
    }
  };

  render() {
    return (
      <>
        <Button
          className="px-1 text-sm"
          type={this.state.ordenando ? "link" : "text"}
          onClick={() => this.eventoOrdenarFilas()}
        >
          {this.state.orden === "ascendente" ? (
            <ChevronDownIcon className="w-4" />
          ) : (
            <ChevronUpIcon className="w-4" />
          )}
        </Button>
      </>
    );
  }
}

TablaSort.propTypes = {
  propiedadObjeto: PropTypes.string,
  emiteEvento: (evento) => {},
};

export default TablaSort;
