import { PropTypes } from "prop-types";

const PotencialVentas = ({ className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="513.000000pt"
      height="514.000000pt"
      viewBox="0 0 513.000000 514.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,514.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M2370 5131 c-796 -68 -1494 -475 -1935 -1131 -225 -335 -357 -687
-417 -1115 -21 -150 -16 -523 10 -690 37 -238 96 -442 185 -644 364 -824 1092
-1380 1987 -1518 163 -25 568 -25 730 0 852 133 1554 642 1929 1398 251 504
328 1057 226 1614 -43 234 -107 428 -215 650 -128 263 -279 474 -495 691 -468
470 -1075 730 -1745 748 -91 3 -208 1 -260 -3z m627 -1311 c79 -239 142 -444
141 -455 -3 -16 -74 -43 -448 -167 -245 -81 -456 -147 -470 -145 -24 2 -32 24
-171 442 -93 279 -143 446 -138 455 4 9 31 24 61 33 29 9 115 37 190 61 l137
44 52 -157 c28 -86 53 -157 54 -159 1 -2 77 23 132 43 8 3 -3 49 -38 152 -27
81 -49 153 -49 159 0 15 345 134 380 131 23 -2 32 -24 167 -437z m-1595 319
c111 -24 220 -103 271 -197 20 -37 830 -2465 823 -2467 0 -1 -52 2 -115 5
l-113 5 -324 980 c-178 539 -351 1064 -384 1166 -33 103 -71 202 -83 220 -70
103 -226 105 -304 2 -27 -35 -28 -42 -28 -144 0 -120 -8 -139 -70 -159 -26 -9
-41 -8 -68 4 -47 19 -66 51 -80 130 -34 200 103 401 308 452 65 16 102 17 167
3z m2320 -756 c17 -20 458 -1350 458 -1381 0 -11 -6 -25 -13 -31 -19 -15
-1404 -471 -1432 -471 -12 0 -27 6 -33 13 -6 7 -113 321 -237 697 -200 606
-224 685 -212 703 10 15 190 79 718 253 387 128 711 233 720 234 9 0 23 -8 31
-17z m423 -1659 c33 -15 65 -64 65 -99 0 -26 -28 -73 -52 -86 -12 -7 -305
-105 -651 -219 l-629 -208 -19 46 c-11 24 -33 65 -49 89 -17 24 -30 47 -30 52
0 7 1292 439 1318 440 8 1 29 -6 47 -15z m-1709 -355 c253 -47 409 -299 334
-541 -94 -305 -478 -401 -703 -176 -163 163 -167 413 -11 587 67 74 191 131
305 140 8 0 42 -4 75 -10z"
        />
        <path
          d="M2971 2607 c-61 -132 -111 -243 -111 -246 0 -7 125 -71 139 -71 4 0
30 46 56 103 l48 102 86 -260 c46 -143 86 -262 88 -264 2 -3 41 7 87 22 l84
28 -84 252 c-86 258 -94 287 -71 270 6 -5 51 -31 98 -56 l85 -46 38 70 c21 39
34 74 30 79 -5 4 -111 64 -236 133 l-227 124 -110 -240z"
        />
        <path
          d="M2268 1140 c-84 -45 -131 -146 -108 -238 40 -167 253 -218 367 -89
91 104 58 263 -68 328 -51 25 -141 25 -191 -1z"
        />
      </g>
    </svg>
  );
};

PotencialVentas.propTypes = {
  className: PropTypes.string,
};

export default PotencialVentas;
