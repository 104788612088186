import { PropTypes } from "prop-types";

const SimhubLogoIcon = ({ className }) => {
  return (
    <svg
      width="213"
      height="52"
      viewBox="0 0 213 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M188.2 16.3C181.2 6.1 169.7 0 157.3 0C144.9 0 133.4 6.1 126.4 16.3L126.2 16.6H131.5L131.6 16.5C137.9 8.7 147.3 4.3 157.4 4.3C167.5 4.3 176.9 8.8 183.2 16.5L183.3 16.6H188.6L188.2 16.3Z"
          fill="black"
        />
        <path
          d="M124.3 35.5H105.8V24H101.3V51.4H105.8V39.9H124.3V51.4H128.7V24H124.3V35.5Z"
          fill="black"
        />
        <path d="M45.5001 24H41.1001V51.4H45.5001V24Z" fill="black" />
        <path
          d="M83.8 25.4C82.5 24.5 80.8999 24 79.1999 24C77.5999 24 75.9999 24.5 74.5999 25.4C74.1999 25.7 73.8 26 73.4 26.4C73 26 72.6999 25.7 72.1999 25.4C70.8999 24.5 69.2999 24 67.5999 24C65.9999 24 64.4 24.5 63 25.4C62.2 25.9 61.5999 26.6 61.0999 27.3C60.1999 28.6 59.7 30.2 59.7 31.9V51.3H64.0999V31.9C64.0999 30 65.6999 28.4 67.5999 28.4C69.4999 28.4 71.0999 30 71.0999 31.9V51.3H75.5V31.9C75.5 30 77.1 28.4 79 28.4C80.9 28.4 82.5 30 82.5 31.9V51.3H86.9V31.9C86.9 30.3 86.4 28.7 85.5 27.3C85.2 26.6 84.5 26 83.8 25.4Z"
          fill="black"
        />
        <path
          d="M3.4 25.4C2.6 26 2 26.6 1.4 27.4C0.5 28.7 0 30.3 0 31.9C0 33.5 0.5 35.1 1.4 36.5C1.9 37.3 2.6 37.9 3.3 38.4C4.6 39.3 6.2 39.8 7.9 39.8H19.4C21.3 39.8 22.9 41.4 22.9 43.3C22.9 45.2 21.3 46.8 19.4 46.8H1.6V51.2H19.4C21 51.2 22.6 50.7 24 49.8C24.8 49.3 25.4 48.6 25.9 47.9C26.8 46.6 27.3 45 27.3 43.3C27.3 41.7 26.8 40.1 25.9 38.7C25.4 37.9 24.7 37.3 24 36.8C22.7 35.9 21.1 35.4 19.4 35.4H8C6.1 35.4 4.5 33.8 4.5 31.9C4.4 30 6 28.4 8 28.4H25.8V24H8C6.3 24 4.7 24.5 3.4 25.4Z"
          fill="black"
        />
        <path
          d="M166.4 37.7C166.4 42.8 162.2 47 157.1 47C152 47 147.8 42.8 147.8 37.7V24H143.4V37.7C143.4 39.7 143.8 41.7 144.7 43.5C146.1 46.4 148.4 48.7 151.3 50.1C153.1 51 155.1 51.4 157.1 51.4C159.1 51.4 161.1 51 162.9 50.1C165.8 48.7 168.1 46.4 169.5 43.5C170.4 41.7 170.8 39.7 170.8 37.7V24H166.4V37.7Z"
          fill="black"
        />
        <path
          d="M210.6 37.7C211 37.3 211.3 37 211.6 36.5C212.5 35.2 213 33.6 213 31.9C213 30.3 212.5 28.7 211.6 27.3C211.1 26.5 210.4 25.9 209.7 25.4C208.4 24.5 206.8 24 205.1 24H190.2H185.6V28.4V35.4V39.8V46.8V51.2H190.2H205.1C206.7 51.2 208.3 50.7 209.7 49.8C210.5 49.3 211.1 48.6 211.6 47.9C212.5 46.6 213 45 213 43.3C213 41.7 212.5 40.1 211.6 38.7C211.3 38.4 210.9 38 210.6 37.7ZM205.1 46.9H190.2V39.9H205.1C207 39.9 208.6 41.5 208.6 43.4C208.6 45.4 207 46.9 205.1 46.9ZM205.1 35.5H190.2V28.5H205.1C207 28.5 208.6 30.1 208.6 32C208.6 33.9 207 35.5 205.1 35.5Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="213" height="51.4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

SimhubLogoIcon.propTypes = {
  className: PropTypes.string,
};

export default SimhubLogoIcon;
