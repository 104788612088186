import { PropTypes } from "prop-types";

const ShoppingCart = ({ className }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.37496 15.5832C6.76616 15.5832 7.08329 15.266 7.08329 14.8748C7.08329 14.4836 6.76616 14.1665 6.37496 14.1665C5.98376 14.1665 5.66663 14.4836 5.66663 14.8748C5.66663 15.266 5.98376 15.5832 6.37496 15.5832Z"
        stroke="black"
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1667 15.5832C14.5579 15.5832 14.875 15.266 14.875 14.8748C14.875 14.4836 14.5579 14.1665 14.1667 14.1665C13.7755 14.1665 13.4584 14.4836 13.4584 14.8748C13.4584 15.266 13.7755 15.5832 14.1667 15.5832Z"
        stroke="black"
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.708374 0.708496H3.54171L5.44004 10.1931C5.50481 10.5192 5.68223 10.8121 5.94122 11.0206C6.20021 11.2291 6.52428 11.3399 6.85671 11.3335H13.7417C14.0741 11.3399 14.3982 11.2291 14.6572 11.0206C14.9162 10.8121 15.0936 10.5192 15.1584 10.1931L16.2917 4.25016H4.25004"
        stroke="black"
        strokeWidth="1.41667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ShoppingCart.propTypes = {
  className: PropTypes.string,
};

export default ShoppingCart;
