export default class TablaDatoService {
  /**
   * Modificar la lista de datos y agregar la propiedad visible a cada fila
   *
   * @param datos Array<any> - la lista de datos
   *
   * @returns Array<any>
   */
  static convertirDatosTipoFilaTabla(datos) {
    if (datos.length > 0) {
      datos.map((dato) => {
        const nuevaPropiedad = "visible";
        dato[nuevaPropiedad] = true;

        return dato;
      });
    }

    return datos;
  }

  /**
   * Generar la lista de ordenamientos que se van a aplicar a la tabla por prioridad
   *
   * @param evento EventoTabla - el objeto tipo EventoTabla
   * @param ordenamientosTabla OrdenamientoTabla[] - la lista de configuraciones de ordenamiento
   * @returns
   */
  static generarOrdenamientoTabla(evento, ordenamientosTabla) {
    if (ordenamientosTabla.length > 0) {
      for (let i = 0; i < ordenamientosTabla.length; i++) {
        if (ordenamientosTabla[i].propiedadObjeto === evento.propiedadObjeto) {
          ordenamientosTabla.splice(i, 1);
          i--;
        }
      }
    }

    ordenamientosTabla.push({
      propiedadObjeto: evento.propiedadObjeto,
      direccion: evento.ordenamiento === "ascendente" ? 1 : -1,
    });

    return ordenamientosTabla;
  }

  /**
   * Ordenar los datos de forma ascendente/descendente
   *
   * @param datos Array<any> - la lista de datos
   * @param ordenamientosTabla OrdenamientoTabla[] - la lista de ordenamientos de la tabla
   *
   * @returns Array<any>
   */
  static ordenarDatos(datos, eventoTabla, ordenamientosTabla) {
    if (datos.length > 0 && ordenamientosTabla.length > 0) {
      const tipoDato = typeof datos[0][eventoTabla.propiedadObjeto];

      if (eventoTabla.ordenamiento === "ascendente") {
        switch (tipoDato) {
          case "string":
            datos = datos.sort((a, b) =>
              String(a[eventoTabla.propiedadObjeto]).localeCompare(
                String(b[eventoTabla.propiedadObjeto])
              )
            );
            break;
          case "number":
            datos = datos.sort(
              (a, b) =>
                a[eventoTabla.propiedadObjeto] - b[eventoTabla.propiedadObjeto]
            );
            break;
        }
      } else {
        switch (tipoDato) {
          case "string":
            datos = datos.sort((a, b) =>
              String(b[eventoTabla.propiedadObjeto]).localeCompare(
                String(a[eventoTabla.propiedadObjeto])
              )
            );
            break;
          case "number":
            datos = datos.sort(
              (a, b) =>
                b[eventoTabla.propiedadObjeto] - a[eventoTabla.propiedadObjeto]
            );
            break;
        }
      }
      return datos;
    } else {
      return datos;
    }
  }

  /**
   * Generar la lista de filtros de busquedas
   *
   * @param evento EventoTabla - objeto tipo EventoTabla
   * @param filtrosBusqueda FiltroBusqueda[] - la lista de filtros de busqueda
   *
   * @returns FiltroBusqueda[]
   */
  static generarFiltrosBusquedaTabla(evento, filtrosBusqueda) {
    if (filtrosBusqueda.length > 0) {
      for (let i = 0; i < filtrosBusqueda.length; i++) {
        if (filtrosBusqueda[i].propiedad === evento.propiedadObjeto) {
          filtrosBusqueda.splice(i, 1);
          i--;
        }
      }
    }

    if (evento.filtroBusqueda && evento.filtroBusqueda.trim() !== "") {
      filtrosBusqueda.push({
        propiedad: evento.propiedadObjeto,
        valor: evento.filtroBusqueda,
      });
    }

    return filtrosBusqueda;
  }

  /**
   * Generar la lista de filtros que se aplicarán a la tabla de datos
   *
   * @param evento EventoTabla - el objeto tipo EventoTabla
   * @param filtrosFila FiltroFila[] - la lista de filtros
   *
   * @returns FiltroFila[]
   */
  static generarFiltrosTabla(evento, filtrosFila) {
    if (filtrosFila.length > 0) {
      for (let i = 0; i < filtrosFila.length; i++) {
        if (filtrosFila[i].propiedad === evento.propiedadObjeto) {
          filtrosFila.splice(i, 1);
          i--;
        }
      }
    }

    if (evento.filtrosTabla && evento.filtrosTabla.length > 0) {
      evento.filtrosTabla?.forEach((filtro) => {
        filtrosFila.push(filtro);
      });
    }

    return filtrosFila;
  }

  /**
   * Aplicar los filtros del dropdown y busquedas a cada fila y ocultar los que no coincidan
   *
   * @param datos Array<any> - la lista de datos
   * @param filtrosBusqueda FiltroBusqueda[] - la lista de filtros de busquedas
   * @param filtrosFila FiltroFila[] - la lista de filtros dropdown
   *
   * @returns Array<any>
   */
  static filtrarDatos(datos, filtrosBusqueda, filtrosFila) {
    if (datos.length > 0) {
      datos.map((dato) => {
        const propiedadVisible = "visible";
        dato[propiedadVisible] = true;

        // Aplicar filtros
        if (filtrosFila.length > 0) {
          const propiedades = filtrosFila.map((filtro) => {
            return filtro.propiedad;
          });

          let totalMatch = 0;
          const totalFiltros = Array.from(new Set(propiedades));

          filtrosFila.forEach((filtro) => {
            const valor = String(dato[filtro.propiedad]);
            if (valor === filtro.valor) {
              totalMatch++;
            }
          });

          dato[propiedadVisible] = totalMatch === totalFiltros.length;
        }

        // Aplicar busquedas
        if (filtrosBusqueda.length > 0 && dato[propiedadVisible]) {
          let totalBusquedas = 0;

          filtrosBusqueda.forEach((busqueda) => {
            const valor = String(dato[busqueda.propiedad]).toUpperCase();

            if (valor && valor.indexOf(busqueda.valor.toUpperCase()) !== -1) {
              totalBusquedas++;
            }
          });

          dato[propiedadVisible] = totalBusquedas === filtrosBusqueda.length;
        }

        return dato;
      });
    }

    return datos;
  }
}
