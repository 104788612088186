import * as Yup from "yup";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { AuthContext } from "../context/AuthContext";
import { FetchContext } from "../context/FetchContext";
import { ErrorMessage, Field, Form, Formik } from "formik";
// import { Formik } from "formik";
import { EyeOffIcon, EyeIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

export const LoginForm = () => {
  const { authAxios } = useContext(FetchContext);
  const { setAuthState } = useContext(AuthContext);
  const [credentialError, setCredentialError] = useState(false);
  const [responseError, setResponseError] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const history = useHistory();

  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      .email("Correo electronico Invalido")
      .required("Campo obligatorio"),
    password: Yup.string().required("Contraseña Obligatoria"),
  });

  const handleSubmitCredentials = async (credentials) => {
    try {
      setResponseError("");
      setCredentialError(false);
      const { data } = await authAxios.post("auth", credentials);
      setAuthState(data);
      setTimeout(() => {
        history.push("/home");
      }, 2000);
    } catch (error) {
      const { data } = error.response;
      setResponseError(data.message);
      setCredentialError(true);
    }
  };

  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
      }}
      onSubmit={(values) => handleSubmitCredentials(values)}
      validationSchema={LoginSchema}
    >
      {({ isSubmitting }) => (
        <div className="px-4 md:px-0 w-full max-w-sm lg:w-login mx-4 sm:mx-6 lg:mx-20 xl:mx-24">
          <div>
            <h2 className="mt-6 text-2xl text-neutral-800 text-center">
              Iniciar sesión
            </h2>

            {credentialError && (
              <div className="bg-red-100 p-2 text-sm font-medium border rounded-lg ">
                {responseError}
              </div>
            )}
          </div>
          <div className="mt-8">
            <div className="mt-6">
              {process.env.REACT_APP_LOGIN_CLASSIC === "true" && (
                <Form className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm text-neutral-600"
                    >
                      Correo electrónico
                    </label>
                    <div className="mt-1">
                      <Field
                        id="inline-full-name"
                        name="username"
                        type="email"
                        autoComplete="email"
                        placeholder="micorreo@example.com"
                        required
                        className="appearance-none block w-full px-3 py-4 border border-simblue-300 rounded-md placeholder-neutral-500 focus:outline-none focus:ring-simblue-600 focus:border-simblue-600 sm:text-sm"
                      />
                      <ErrorMessage
                        name="username"
                        render={(msg) => (
                          <div className="text-sm p-2 text-red-600 font-medium">
                            {msg}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm text-neutral-600"
                    >
                      Contraseña
                    </label>
                    <div className="mt-1 relative">
                      <Field
                        id="inline-password"
                        name="password"
                        type={passwordShown ? "text" : "password"}
                        autoComplete="current-password"
                        placeholder="******"
                        required
                        className="appearance-none block w-full px-3 py-4 border border-simblue-300 rounded-md placeholder-neutral-500 focus:outline-none focus:ring-simblue-600 focus:border-simblue-600 sm:text-sm"
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                        {passwordShown ? (
                          <EyeIcon
                            className="h-5 w-5 text-neutral-600"
                            aria-hidden="true"
                            onClick={() => setPasswordShown(!passwordShown)}
                          />
                        ) : (
                          <EyeOffIcon
                            className="h-5 w-5 text-neutral-600"
                            aria-hidden="true"
                            onClick={() => setPasswordShown(!passwordShown)}
                          />
                        )}
                      </div>
                    </div>
                    <ErrorMessage
                      name="password"
                      render={(msg) => (
                        <div className="text-sm p-2 text-red-600 font-medium">
                          {msg}
                        </div>
                      )}
                    />
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-4 px-4 border border-transparent rounded-md shadow-sm text-sm text-neutral-400 bg-simblue-900 hover:bg-simblue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-simblue-500"
                      disabled={isSubmitting}
                    >
                      Iniciar sesión
                    </button>
                  </div>

                  <div className="flex items-center justify-center">
                    <div className="text-sm">
                      <Link
                        to="/password/step-1"
                        className="font-medium text-neutral-900 hover:text-neutral-700"
                      >
                        Olvide mi contraseña
                      </Link>
                    </div>
                  </div>
                </Form>
              )}

              <div className="space-y-6">
                <div>
                  <a
                    href={`${process.env.PUBLIC_URL}${process.env.REACT_APP_API_URL}/auth/ssologin`}
                    className="w-full flex justify-center py-4 px-4 border border-transparent rounded-md shadow-sm text-sm text-neutral-400 bg-simblue-900 hover:bg-simblue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-simblue-500"
                  >
                    Iniciar sesión SSO
                  </a>
                </div>
                <div className="flex items-center justify-center">
                  <div className="text-sm">
                    <Link
                      to="/password/step-1"
                      className="font-medium text-neutral-900 hover:text-neutral-700"
                    >
                      Olvide mi contraseña
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
