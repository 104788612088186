import HomeIcon from "../components/icons/HomeIcon";
import CopyIcon from "../components/icons/CopyIcon";
import ThumbsUpIcon from "../components/icons/ThumbsUpIcon";

const principalNavigation = [
  { name: "Home", href: "/home", icon: HomeIcon, current: true },
  { name: "My SimApps", href: "/my-sims", icon: CopyIcon, current: false },
  { name: "Alianzas", href: "/alianzas", icon: ThumbsUpIcon, current: false },
];

export default principalNavigation;
