import { useContext, useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FetchContext } from "../context/FetchContext";
import DatePickerField from "./DatePickerField";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

const ChangeProfileForm = () => {
  const history = useHistory();
  const { authAxios } = useContext(FetchContext);

  // loadings
  const [loading, setLoading] = useState(false);
  const [loadingPuestos, setLoadingPuestos] = useState(false);
  const [profile, setProfile] = useState({});
  const [puestos, setPuestos] = useState({});

  const [error, setError] = useState(false);
  const [responseError, setResponseError] = useState("");

  const [success, setSuccess] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState("");

  const profileSchema = Yup.object().shape({
    firstName: Yup.string().required("El campo es obligatorio"),
    lastName: Yup.string().required("El campo es obligatorio"),
    phone: Yup.string().required("El campo es obligatorio"),
    cellphone: Yup.string().required("El campo es obligatorio"),
    birthday: Yup.string().required("El campo es obligatorio").nullable(),
  });

  const idiomas = [
    { value: "Spanish", label: "Español" },
    { value: "English", label: "Ingles" },
  ];

  const initCalendar = "1986/04/10";

  const handleSubmitChangeProfile = async (dataForm, { resetForm }) => {
    try {
      setError(false);
      setSuccess(false);
      setResponseSuccess("");
      setResponseError("");
      const { data } = await authAxios.patch("/profile", dataForm);
      setSuccess(true);
      setResponseSuccess(data.message);
    } catch (err) {
      const { data } = err.response;
      setError(true);
      if (data.statusCode === 401 && data.message === "Unauthorized") {
        toast.error("La sesión ha expirado");
        setResponseError("La sesión ha expirado");
        setTimeout(() => {
          history.push("/");
        }, 1500);
      } else {
        setResponseError(data.message);
      }
    }
  };

  useEffect(() => {
    const getPuestos = async () => {
      try {
        const { data } = await authAxios.get("profile/puestos");
        setPuestos(data);
        setLoadingPuestos(true);
      } catch (err) {
        const { data } = err.response;
        if (data.statusCode === 401 && data.message === "Unauthorized") {
          toast.error("La sesión ha expirado");
          setTimeout(() => {
            history.push("/");
          }, 1500);
        } else {
          toast.error(data.message);
        }
      }
    };

    const getProfile = async () => {
      try {
        const { data } = await authAxios.get("profile");
        setProfile(data);
        setLoading(true);
      } catch (err) {
        const { data } = err.response;
        if (data.statusCode === 401 && data.message === "Unauthorized") {
          toast.error("La sesión ha expirado");
          setTimeout(() => {
            history.push("/");
          }, 1500);
        } else {
          toast.error(data.message);
        }
      }
    };
    getPuestos();
    getProfile();
  }, []);

  if (!loading) {
    return (
      <div className="container mx-auto py-16 px-4 flex justify-center items-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }
  if (!loadingPuestos) {
    return (
      <div className="container mx-auto py-16 px-4 flex justify-center items-center">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"></div>
      </div>
    );
  }
  return (
    <>
      <Formik
        initialValues={{
          firstName: profile.nombre ?? "",
          lastName: profile.apellidos ?? "",
          phone: profile.telefono ?? "",
          cellphone: profile.celular ?? "",
          email: profile.email ?? "",
          job: profile.puesto ?? 0,
          birthday: profile.cumpleanos
            ? profile.cumpleanos.replace(/-/g, "/")
            : "",
          language: profile.Language ?? "Spanish",
        }}
        validationSchema={profileSchema}
        onSubmit={handleSubmitChangeProfile}
      >
        {(isSubmitting) => (
          <Form className="space-y-6">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 mt-5 sm:col-span-6 md:mt-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Información personal
                </h3>
              </div>
              <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nombre (s)
                </label>
                <Field
                  id="firstName"
                  type="text"
                  name="firstName"
                  placeholder="Nombre completo"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <ErrorMessage
                  name="firstName"
                  render={(msg) => (
                    <div className="text-sm p-2 text-red-600 font-medium">
                      {msg}
                    </div>
                  )}
                />
              </div>

              <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Apellidos (s)
                </label>
                <Field
                  id="lastName"
                  type="text"
                  name="lastName"
                  placeholder="Apellidos completo"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <ErrorMessage
                  name="lastName"
                  render={(msg) => (
                    <div className="text-sm p-2 text-red-600 font-medium">
                      {msg}
                    </div>
                  )}
                />
              </div>

              <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Teléfono
                </label>
                <Field
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="000000000"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <ErrorMessage
                  name="phone"
                  render={(msg) => (
                    <div className="text-sm p-2 text-red-600 font-medium">
                      {msg}
                    </div>
                  )}
                />
              </div>

              <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                <label
                  htmlFor="cellphone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Celular
                </label>
                <Field
                  type="text"
                  name="cellphone"
                  id="cellphone"
                  placeholder="000000000"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <ErrorMessage
                  name="cellphone"
                  render={(msg) => (
                    <div className="text-sm p-2 text-red-600 font-medium">
                      {msg}
                    </div>
                  )}
                />
              </div>

              <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Correo electrónico
                </label>
                <Field
                  id="email"
                  type="text"
                  name="email"
                  disabled
                  placeholder="micorreo@example.com"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                <label
                  htmlFor="job"
                  className="block text-sm font-medium text-gray-700"
                >
                  Puesto
                </label>
                <Field
                  name="job"
                  as="select"
                  className="focus:outline-none mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  {puestos.map((puesto) => (
                    <option key={puesto.IDPuesto} value={puesto.IDPuesto}>
                      {puesto.Spanish}
                    </option>
                  ))}
                </Field>
              </div>

              <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                <label
                  htmlFor="birthday"
                  className="block text-sm font-medium text-gray-700"
                >
                  Fecha de nacimiento
                </label>
                {/* <Field
                type="text"
                name="birthday"
                id="birthday"
                placeholder="YY/DD/MM "
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              /> */}
                <DatePickerField
                  name="birthday"
                  dateFormat="yyyy/MM/dd"
                  openToDate={new Date(initCalendar)}
                />
                <ErrorMessage
                  name="birthday"
                  render={(msg) => (
                    <div className="text-sm p-2 text-red-600 font-medium">
                      {msg}
                    </div>
                  )}
                />
              </div>

              <div className="col-span-6 mt-5 sm:col-span-3 md:mt-0">
                <label
                  htmlFor="language"
                  className="block text-sm font-medium text-gray-700"
                >
                  Idioma
                </label>
                <Field
                  name="language"
                  as="select"
                  className="focus:outline-none mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  {idiomas.map((idioma) => (
                    <option key={idioma.value} value={idioma.value}>
                      {idioma.label}
                    </option>
                  ))}
                </Field>
              </div>

              {error && (
                <div className="col-span-6 text-sm p-2 text-red-600 font-medium">
                  {responseError}
                </div>
              )}

              {success && (
                <div className="col-span-6 text-sm p-2 text-green-600 font-medium">
                  {responseSuccess}
                </div>
              )}

              <div className="col-span-6 mt-5 sm:col-span-6 md:mt-0">
                <button
                  type="submit"
                  className="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-simblue-900 py-4 px-8 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Guardar cambios
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChangeProfileForm;
