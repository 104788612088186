import { useContext, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FetchContext } from "../context/FetchContext";
import Return from "../assets/svg/corner-up-left.svg";

function RecoveryPassword() {
  const { authAxios } = useContext(FetchContext);

  const [error, setError] = useState(false);
  const [responseError, setResponseError] = useState("");

  const [success, setSuccess] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState("");

  const RecoverySchema = Yup.object().shape({
    email: Yup.string()
      .email("Correo electronico Invalido")
      .required("Campo obligatorio"),
  });

  const handleSubmitEmail = async (email, { resetForm }) => {
    try {
      setError(false);
      setSuccess(false);
      setResponseSuccess("");
      setResponseError("");
      const { data } = await authAxios.post("/recover/password/", email);
      setSuccess(true);
      setResponseSuccess(data.message);
      resetForm({});
    } catch (err) {
      const { data } = err.response;
      setError(true);
      data.statusCode === 500
        ? setResponseError("Token CSRF caducado, refresque la pagina")
        : setResponseError(data.message);
    }
  };

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={RecoverySchema}
      onSubmit={handleSubmitEmail}
    >
      {({ isSubmitting }) => (
        <div className="mx-auto px-4 md:px-0 w-full max-w-sm lg:w-login sm:mx-6 lg:mx-20 xl:mx-24">
          <div>
            <h2 className="mt-6 text-xl text-neutral-800 text-center">
              Recuperación de contraseña
            </h2>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <Form action="#" method="POST" className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm text-neutral-600"
                  >
                    Correo electrónico
                  </label>
                  <div className="mt-1">
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      placeholder="micorreo@example.com"
                      required
                      className="appearance-none block w-full px-3 py-4 border border-simblue-300 rounded-md placeholder-neutral-500 focus:outline-none focus:ring-simblue-600 focus:border-simblue-600 sm:text-sm"
                    />
                    <ErrorMessage
                      name="email"
                      render={(msg) => (
                        <div className="text-sm p-2 text-red-600 font-medium">
                          {msg}
                        </div>
                      )}
                    />
                  </div>
                </div>

                {error && (
                  <div className="text-sm p-2 text-red-600 font-medium">
                    {responseError}
                  </div>
                )}

                {success && (
                  <div className="text-sm p-2 text-green-600 font-medium">
                    {responseSuccess}
                  </div>
                )}

                <div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full flex justify-center py-4 px-4 border border-transparent rounded-md shadow-sm text-sm text-neutral-400 bg-simblue-900 hover:bg-simblue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-simblue-500"
                  >
                    Enviar enlace
                  </button>
                </div>

                <div className="pt-3 text-sm text-gray-600 flex justify-center">
                  <Link to="/">
                    <img
                      src={Return}
                      className="h-6 w-auto"
                      alt="return home"
                    />
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default RecoveryPassword;
