import { PropTypes } from "prop-types";
import FooterLogos from "../components/FooterLogos";
import LogoMedium from "../components/LogoMedium";

LoginShell.propTypes = {
  children: PropTypes.object,
};

function LoginShell({ children }) {
  return (
    <div className="bg-cover bg-login">
      <div className="min-h-screen flex">
        <div className="flex-1 flex flex-col justify-center lg:flex-none bg-neutral-200 bg-opacity-95 relative">
          <LogoMedium />
          {children}
          <FooterLogos />
        </div>
      </div>
    </div>
  );
}

export default LoginShell;
